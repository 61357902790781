import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CiBoxList } from "react-icons/ci";
import axios from "axios";

const EditBrand = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [category, setCategory] = useState([]);

  //   setting form data
  const [formData, setFormData] = useState({
    name: "",
    category: null,
    category_name: "",
  });

  //handle change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //setting token for authorization
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  //fetching category data
  const fetchCategory = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/api/category/`,
        { headers }
      );
      setCategory(response?.data?.results);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  // submit function
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_ROOT_URL}/api/brand/${id}/`,
        formData,
        { headers }
      );
      navigate("/brands");
    } catch (error) {
      console.error(error);
    }
  };

  //   fetching default value
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ROOT_URL}/api/brand/${id}/`,
          { headers }
        );
        const apiData = response?.data;
        setFormData({
          name: apiData.name,
          category: apiData.category,
          category_name: apiData.category_name,
        });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData(); // Call the fetchData function when the component mounts
  }, []);

  return (
    <div className=" flex justify-center items-center h-screen my-10 md:my-0">
      <form onSubmit={handleSubmit} class="w-full ">
        <div className=" flex justify-between mb-10 max-w-2xl mx-auto">
          <h1 class=" text-3xl  text-gray-500 font-bold ">Edit Brand</h1>

          <Link to="/company">
            <CiBoxList size={30} />
          </Link>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label
              class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              for="inline-full-name"
            >
              Brand Name
            </label>
          </div>
          <div class="md:w-1/2">
            <input
              name="name"
              defaultValue={formData?.name}
              onChange={handleChange}
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-500"
              id="inline-full-name"
              type="text"
              placeholder=" Enter Company Name"
            />
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label
              class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              for="inline-full-name"
            >
              Category Name
            </label>
          </div>
          <div class="md:w-1/2">
            <select
              name="category"
              onChange={handleChange}
              id="Category"
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-500"
            >
              <option selected>{formData?.category_name} </option>
              {category?.map((item) => (
                <option key={item?.id} value={item?.id} name={item?.id}>
                  {item?.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div class="md:flex md:items-center">
          <div class="md:w-1/3"></div>
          <div class="md:w-2/3">
            <button
              onClick={() => navigate(-1)}
              class=" w-24 bg-red-100 text-red-600 shadowfocus:shadow-outline focus:outline-none  font-bold py-2 px-4 rounded"
              type="button"
            >
              Back
            </button>
            <button
              class="mx-4 w-24 bg-gray-950 text-white shadowfocus:shadow-outline focus:outline-none  font-bold py-2 px-4 rounded"
              type="submit"
            >
              Update
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditBrand;
