import React from "react";
import { handleCurrentItem } from "../../Redux/API_Slices/POSSlice";
import { useDispatch } from "react-redux";

const SidebarCart = ({
  handleNumberClick,
  currentItem,
  handleRemoveFromCart,
  cartItems,
  handleHeyPress,
  setCurrentItem,
  handleBackSpace,
  handleEnter,
}) => {
  const dispatch = useDispatch();
  const calculateTotalPrice = (item) => {
    const totalPrice =
      (item.sell_price ? parseInt(item.sell_price) : 0) *
      (item.new_quantity ? parseInt(item.new_quantity) : 0);
    return totalPrice;
  };
  const total = cartItems.reduce(
    (sum, item) => sum + calculateTotalPrice(item),
    0
  );

  return (
    <div>
      <div class="mx-2  border-r h-screen pr-1">
        {/* card Section  start from here  */}
        <div className=" h-[70%] overflow-y-scroll">
          <ul class=" divide-y divide-gray-200">
            {cartItems?.map((item) => (
              <li
                role="button"
                onClick={() => dispatch(handleCurrentItem(item))}
                class={` ${
                  currentItem?.id === item?.id && "bg-gray-200 "
                } flex items-stretch justify-between space-x-5 py-2 `}
              >
                <div class="px-2 flex flex-col justify-between">
                  <div class="flex-1">
                    <p class="text-sm font-bold text-gray-900">{item?.name}</p>

                    {/* {item?.attributes?.map((i) => (
                      <p class="mt-1.5 text-sm font-medium text-gray-600">
                        {i?.attribute}:{i?.value}
                      </p>
                    ))} */}
                  </div>
                  <div className=" flex justify-between">
                    <p class="mt-4 text-sm font-medium text-gray-500">
                      x {item?.new_quantity}
                    </p>
                    <p class="mt-4 text-sm font-medium text-gray-500 ml-3">
                      per unit Price: {item?.sell_price}
                    </p>
                  </div>
                </div>

                <div class="ml-auto flex flex-col items-end justify-between">
                  <p class="text-right text-sm font-bold text-gray-900">
                    {(item?.new_quantity ? item?.new_quantity : 1) *
                      (item?.sell_price ? item?.sell_price : 0)}
                  </p>

                  <button
                    onClick={() => handleRemoveFromCart(item)}
                    type="button"
                    class="-m-2 inline-flex rounded p-2 text-gray-400 transition-all duration-200 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2"
                  >
                    <span class="sr-only">Remove</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="h-5 w-5"
                    >
                      <line x1="18" y1="6" x2="6" y2="18"></line>
                      <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                  </button>
                </div>
              </li>
            ))}
          </ul>

          <>
            <hr class="mt-6 border-gray-200" />
            <ul class="mt-6 space-y-3">
              {/* <li class="flex items-center justify-between">
                <p class="text-sm font-medium">Sub total</p>
                <p class="text-sm font-medium">₹1,14,399</p>
              </li> */}
              <li class="flex items-center justify-between">
                <p class="text-sm font-medium ">Total</p>
                <p class="text-sm font-bold ">{total}</p>
              </li>
            </ul>
          </>
        </div>
        {/* card Section  End  here  */}

        {/* calculator  */}
        <div className=" h-[30%] overflow-y-scroll">
          <div className="grid grid-cols-3 gap-2 p-4">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((number) => (
              <button
                key={number}
                onClick={() => handleNumberClick(String(number))}
                className="bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring focus:ring-blue-400 rounded p-2 text-center"
              >
                {number}
              </button>
            ))}

            <button
              onClick={() => handleEnter()}
              className="bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring focus:ring-blue-400 rounded p-2 text-center"
            >
              Enter
            </button>
            <button
              onClick={() => handleBackSpace()}
              className="bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring focus:ring-blue-400 rounded p-2 text-center"
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarCart;
