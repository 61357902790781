import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  doEditId: "",
  invoiceEditId: "",
  quotationEditId: "",
};

const editState = createSlice({
  name: "editState",
  initialState,
  reducers: {
    addIdNumber: (state, action) => {
      state[action.payload.type] = action.payload.value;
    },
    resetIdNumber: (state) => {
      state = initialState;
    },
  },
});

export const { addIdNumber, resetIdNumber } = editState.actions;
export default editState.reducer;
