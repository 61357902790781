import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CiBoxList } from "react-icons/ci";
import axios from "axios";

const AddUser = () => {
  const [roles, setRoles] = useState([]);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    first_name: "",
    last_name: "",
    password: "",
    // role: "",
  });
  const [permissions, setPermissions] = useState([
    {
      name: "user",
      title: "User",
      is_active: false,
    },
    { name: "product", title: "Product", is_active: false },
    { name: "category", title: "category", is_active: false },
    { name: "company", title: "Company", is_active: false },
    { name: "quatation", title: "Quotation", is_active: false },
    { name: "deliver_order", title: "Delivery Order", is_active: false },
    { name: "invoice", title: "Invoice", is_active: false },
    { name: "pos", title: "POS", is_active: false },
    { name: "purchase", title: "Purchase", is_active: false },
    { name: "order", title: "Order", is_active: false },
    { name: "inventory", title: "Inventory", is_active: false },
  ]);

  const { id } = useParams();

  //setting token
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ROOT_URL}/api/user/${id}/`,
          { headers }
        );
        const apiData = response?.data;

        setFormData({
          username: apiData.username,
          first_name: apiData.first_name,
          last_name: apiData?.last_name,
        });
        setPermissions(apiData?.permissions);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData(); // Call the fetchData function when the component mounts
  }, []);

  // Function to fetch data from the API
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/api/role/`,
        { headers }
      );
      setRoles(response?.data?.results);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      permissions,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (id) {
        const response = await axios.patch(
          `${process.env.REACT_APP_ROOT_URL}/api/user/${id}/`,
          formData,
          { headers }
        );
        navigate("/user");
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_ROOT_URL}/api/user/`,
          formData,
          { headers }
        );
        navigate("/user");
      }
    } catch (error) {
      console.error(error);
    }
  };
  const data = [
    {
      name: "user",
      title: "User",
      is_active: false,
    },
    { name: "product", title: "Product", is_active: false },
    { name: "category", title: "category", is_active: false },
    { name: "company", title: "Company", is_active: false },
    { name: "quatation", title: "Quotation", is_active: false },
    { name: "deliver_order", title: "Deliver Order", is_active: false },
    { name: "invoice", title: "Invoice", is_active: false },
    { name: "pos", title: "POS", is_active: false },
    { name: "purchase", title: "Purchase", is_active: false },
    { name: "order", title: "Order", is_active: false },
    { name: "inventory", title: "Inventory", is_active: false },
  ];

  const handlePermission = (e, index) => {
    const { name, checked } = e.target;
    const newData = [...permissions];
    newData[index].is_active = checked;
    // const res = newData.find((item) => item?.name === name);
    // res.is_active = checked;
    setPermissions(newData);
  };
  return (
    <div className=" flex justify-center items-center h-screen my-10 md:my-0">
      <form onSubmit={handleSubmit} class="w-full ">
        <div className=" flex justify-between mb-10 max-w-2xl mx-auto">
          <h1 class=" text-3xl  text-gray-500 font-bold ">Add User</h1>

          <Link to="/user">
            <CiBoxList size={30} />
          </Link>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label
              class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              for="inline-full-name"
            >
              User Name
            </label>
          </div>
          <div class="md:w-1/2">
            <input
              disabled={id ? true : false}
              value={formData?.username}
              name="username"
              onChange={handleChange}
              class=" appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-500"
              id="inline-full-name"
              type="text"
              placeholder=" Enter User Name"
            />
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label
              class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              for="inline-full-name"
            >
              First Name
            </label>
          </div>
          <div class="md:w-1/2">
            <input
              value={formData?.first_name}
              name="first_name"
              onChange={handleChange}
              class=" appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-500"
              id="inline-full-name"
              type="text"
              placeholder=" Enter First Name"
            />
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label
              class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              for="inline-full-name"
            >
              Last Name
            </label>
          </div>
          <div class="md:w-1/2 ">
            <input
              value={formData?.last_name}
              name="last_name"
              onChange={handleChange}
              class=" appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-500"
              id="inline-full-name"
              type="text"
              placeholder=" Enter Last Name"
            />
          </div>
        </div>
        {/* <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label
              class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              for="inline-full-name"
            >
              Role
            </label>
          </div>
          <div class="md:w-1/2">
            <select
              name="role"
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-500"
              id="inline-full-name"
              onChange={handleChange}
            >
              <option selected>Choose a role</option>
              {roles?.map((role) => (
                <option value={role?.id} >{role?.name}</option>
              ))}
            </select>
            
          </div>
        </div> */}

        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label
              class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              for="inline-full-name"
            >
              Permission
            </label>
          </div>
          <div class="md:w-1/2">
            <div class="  flex space-x-4 flex-wrap md:items-center border p-2 rounded">
              {data?.map((item, index) => (
                <div class="flex items-center mb-4">
                  <input
                    checked={permissions[index].is_active}
                    // value={permissions[index].is_active}
                    onChange={(e) => handlePermission(e, index)}
                    id={index}
                    type="checkbox"
                    name={item?.name}
                    class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500  "
                  />
                  <label
                    for={index}
                    class="ml-2 text-sm font-medium text-gray-900 dark::text-gray-300"
                  >
                    {item?.title}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label
              class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              for="inline-full-name"
            >
              Password
            </label>
          </div>
          <div class="md:w-1/2">
            <input
              name="password"
              onChange={handleChange}
              class=" appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-500"
              id="inline-full-name"
              type="password"
              placeholder=" Enter Password"
            />
          </div>
        </div>

        <div class="md:flex md:items-center">
          <div class="md:w-1/3"></div>
          <div class="md:w-2/3">
            <button
              onClick={() => navigate(-1)}
              class=" w-24 bg-red-100 text-red-600 shadowfocus:shadow-outline focus:outline-none  font-bold py-2 px-4 rounded"
              type="button"
            >
              Back
            </button>
            <button
              class="mx-4 w-24 bg-gray-950 text-white shadowfocus:shadow-outline focus:outline-none  font-bold py-2 px-4 rounded"
              type="submit"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddUser;
