import React, { useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { Link } from "react-router-dom";

const SelectedCompaniesTable = ({
  handleRemoveCompany,
  companies,
  onUpdateCompany,
}) => {
  const [editingCompanyId, setEditingCompanyId] = useState(null);

  const handleQuantityChange = (companyId, quantity) => {
    onUpdateCompany(companyId, { quantity });
  };

  const handleUnitChange = (companyId, unit) => {
    onUpdateCompany(companyId, { unit });
  };

  const handleViewClick = (companyId) => {
    // Handle view click logic here
    console.log(`Viewing company with id: ${companyId}`);
  };
  console.log("companies", companies);
  return (
    <div className="">
      <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark::text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark::bg-gray-700 dark::text-gray-400">
          <tr>
            <th scope="col" class="px-6 py-3">
              Company Name
            </th>
            <th scope="col" class="px-6 py-3">
              Quantity
            </th>

            <th scope="col" class="px-6 py-3">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {companies?.map((company) => (
            <tr key={company.id} class="bg-white border-b  ">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
              >
                {company.company_name}
              </th>
              <td class="px-6 py-4">
                <p>{company.quantity}</p>
              </td>

              <td class="px-6 py-4">
                <div className=" flex items-center space-x-2">
                  <Link
                    to={`/muti-inventory-details/${company.company}?product_id=${company?.product_detail?.id}`}
                  >
                    <AiOutlineEye
                      size={30}
                      className="cursor-pointer"
                      onClick={() => handleViewClick(company.company)}
                    />
                  </Link>
                  {/* <button
                    onClick={() => handleRemoveCompany(company)}
                    className="bg-red-500 text-white px-2 py-1 rounded-md"
                  >
                    Remove
                  </button> */}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SelectedCompaniesTable;
