import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import { AiFillDelete } from "react-icons/ai";
import axios from "axios";
import Swal from "sweetalert2";

const CategoryTable = () => {
  const [categoryData, setcategoryData] = useState([]);
  const navigate = useNavigate();

  //setting token
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  // delete function
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(
            `${process.env.REACT_APP_ROOT_URL}/api/category/${id}/`,
            { headers }
          );
          // After successful deletion, refresh the data
          fetchData();
          // Swal.fire("Deleted!", "Your item has been deleted.", "success");
        } catch (error) {
          console.error(error);
          Swal.fire(
            "Error!",
            "An error occurred while deleting the item.",
            "error"
          );
        }
      }
    });
  };

  // Function to fetch data from the API
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/api/category/`,
        { headers }
      );
      setcategoryData(response?.data?.results);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className=" m-5  ">
      <div className=" flex justify-end">
        <Link
          to="/add-category"
          className=" bg-gray-900 text-white px-4 py-2 rounded-lg my-2"
        >
          Add category
        </Link>
      </div>
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg ">
        <table class="w-full text-sm text-left text-gray-500 ">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              <th scope="col" class="px-6 py-3">
                category Name
              </th>

              <th scope="col" class="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {categoryData?.map((item) => (
              <tr class="bg-white border-b " key={item?.id}>
                <th class="px-6 py-4">
                  <p>{item?.name}</p>
                </th>

                <td className="px-6 py-4 w-64">
                  <div className="  w-full">
                    {/* edit button  */}
                    <button
                      onClick={() => navigate(`/edit-category/${item?.id}`)}
                      className="mx-1 py-2 bg-yellow-600 text-white px-2  rounded-full"
                    >
                      <BiEdit size={16} />
                    </button>
                    {/* delete button  */}
                    <button
                      onClick={() => handleDelete(item?.id)}
                      className=" py-2 bg-red-600 text-white px-2  rounded-full"
                    >
                      <AiFillDelete size={16} />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CategoryTable;
