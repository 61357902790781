import React, { useEffect, useState } from "react";
import { BiMinus } from "react-icons/bi";
import { AiFillMinusCircle } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { calculateMeasurement } from "../../lib/measurement";
import { BiEdit } from "react-icons/bi";
import { useSelector } from "react-redux";
const ProductList = ({
  subtotal,
  gstDiscount,
  grandTotal,
  handelChange,
  selectedProduct,
  handleDisCount,
  isTotalDiscount,
  isItemDiscount,
  isFlatDiscount,
  discount,
  setTermsAndConditions,
  termsAndConditions,
  handleEditDucting,
  setSelectedRow,
  handleDelete,
  isShowGST,
  setBankInfo,
  bankInfo,
  activeItem,
  setActiveItem,
  activeItemList,
  setActiveItemList,
  usedFor,
}) => {
  const { nestedQuotationEditId, nestedInvoiceEditId } = useSelector(
    (state) => state.nestedEditState
  );

  const { id } = useParams();
  const pathname = window.location.pathname;

  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    console.log("selectedProduct:", selectedProduct);
  }, [selectedProduct]);
  function calculateTotalPriceAfterDiscount(isItemDiscount, item) {
    const initialTotal =
      Number(
        item?.category_name === "Ducting" ? item?.unit_price : item?.sell_price
      ) * Number(item?.new_quantity);

    if (isItemDiscount && item?.item_discount) {
      const discountPercentage = Number(item.item_discount);
      const discountAmount = (initialTotal * discountPercentage) / 100;

      const totalAfterPercentageDiscount = initialTotal - discountAmount;

      // Return the total after discount
      return totalAfterPercentageDiscount.toFixed(2);
    } else {
      // Return the initial total if no discount is applicable
      return initialTotal.toFixed(2);
    }
  }

  // function calculateTotalPriceAfterDiscount(isItemDiscount, item) {
  //   const initialTotal =
  //     Number(
  //       item?.category_name === "Ducting" ? item?.unit_price : item?.sell_price
  //     ) * Number(item?.new_quantity);

  //   if (isItemDiscount && item?.item_discount) {
  //     const discountAmount = (initialTotal * Number(item.item_discount)) / 100;

  //     return Math.round(initialTotal - discountAmount);
  //   } else {
  //     return initialTotal.toFixed(2);
  //   }
  // }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <div>
      <table class="w-full text-sm text-left text-gray-500 ">
        <thead class="text-xs text-blue-700 uppercase ">
          <tr>
            <th>NO:</th>
            <th scope="col" class="px-1 py-3">
              Product Name
            </th>
            <th scope="col" class="px-1 py-3">
              Description
            </th>
            <th scope="col" class="px-6 py-3">
              Unit
            </th>
            <th scope="col" class="px-6 py-3">
              Unit Price
            </th>
            <th scope="col" class="px-6 py-3">
              Available
            </th>
            <th scope="col" class="px-6 py-3">
              Quantity
            </th>
            <th scope="col" class="px-6 py-3">
              Total Price
            </th>
            {isItemDiscount && (
              <th scope="col" class="px-6 py-3">
                Discount
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {selectedProduct?.map((item, index) => (
            <tr
              key={item.uuid}
              className={`border-b rounded ${
                id
                  ? activeItemList?.includes(index) && "bg-yellow-300"
                  : activeItem === index && "bg-yellow-300"
              } ${
                usedFor == "invoice"
                  ? item.uuid == nestedInvoiceEditId
                    ? "bg-yellow-300"
                    : ""
                  : item.uuid == nestedQuotationEditId
                  ? "bg-yellow-300"
                  : ""
              }`}
            >
              <th className=" pl-1">{index + 1}.</th>
              <th
                scope="row"
                className=" max-w-32 py-1 font-medium text-dark whitespace-nowrap "
              >
                <div className="text-dark text-xs flex w-full">
                  {item?.category_name === "Ducting" && (
                    <span className=" space-x-1 uppercase break-words ">
                      <span className="ml-1">
                        {item?.ducting_type?.product_type?.toUpperCase()}
                      </span>
                      <span>{item?.ducting_type?.thickness}</span>
                      <span>
                        {item?.ducting_type?.brand_type?.toUpperCase()}
                      </span>

                      <span>
                        {item?.ducting_type?.joint_type?.toUpperCase()}
                      </span>
                    </span>
                  )}
                  <span
                    className={`break-words ${
                      item?.category_name === "Ducting" && "ml-1"
                    }`}
                  >
                    {item?.parent ? item?.parent_name : item?.name}
                  </span>
                </div>

                <div className="text-xs ">
                  {item?.category_name === "Ducting" && !item?.isManual ? (
                    <span className="ml-1 flex ">
                      <span>{item?.dimensions?.width}X</span>
                      <span>{item?.dimensions?.height}X</span>
                      <span>{item?.dimensions?.length}</span>
                      {/* <span className="ml-2 text-xs">
                        Costing:{" "}
                        {calculateMeasurement(item?.display_dimensions)}sq m
                      </span> */}
                    </span>
                  ) : (
                    <span>{item?.area}</span>
                  )}
                </div>
              </th>
              <td className="px-1 py-4">
                <input
                  onChange={(e) => handelChange(e, item, index)}
                  class=" border border-gray-900 rounded w-full py-2 px-1 text-gray-950 "
                  id="inline-full-name"
                  type="text"
                  placeholder="description"
                  name="description"
                  value={
                    item?.category_name === "Ducting"
                      ? item?.display_dimensions
                      : item?.parent
                      ? Object.values(item?.variant || {}).join("/")
                      : item?.description
                  }
                  // disabled={pathname.includes("/view-invoice") ? true : false}
                />
              </td>
              <td className="px-1 py-4">
                <input
                  onChange={(e) => handelChange(e, item, index)}
                  class=" border border-gray-900 rounded w-full py-2 px-1 text-gray-950 "
                  id="inline-full-name"
                  type="text"
                  placeholder="UNIT"
                  name="unit"
                  defaultValue={item?.unit}
                  // disabled={pathname.includes("/view-invoice") ? true : false}
                />
              </td>

              <td className="px-1 py-4">
                <input
                  onKeyDown={handleKeyDown}
                  onFocus={() => setIsFocus(true)}
                  onBlur={() => setIsFocus(false)}
                  onChange={(e) => handelChange(e, item, index)}
                  onWheel={(e) => e.target.blur()}
                  class=" border border-gray-900 rounded w-full py-2 px-1 text-gray-950 "
                  id="inline-full-name"
                  type="number"
                  placeholder="UNIT PRICE"
                  name={
                    item?.category_name === "Ducting"
                      ? "unit_price"
                      : "sell_price"
                  }
                  value={
                    item?.category_name === "Ducting"
                      ? Number(item?.unit_price)
                      : Number(item?.sell_price)
                  }
                />
              </td>
              <td className="px-1 py-4 text-center">
                <p>{item?.quantity}</p>
              </td>
              <td className="px-1 py-4">
                {" "}
                <input
                  onKeyDown={handleKeyDown}
                  onChange={(e) => handelChange(e, item, index)}
                  onWheel={(e) => e.target.blur()}
                  class=" border border-gray-900 rounded w-full py-2 px-1 text-gray-950 "
                  id="inline-full-name"
                  type="number"
                  placeholder="QUANTITY"
                  name="new_quantity"
                  defaultValue={item?.new_quantity}
                  max={item?.quantity}
                  // disabled={pathname.includes("/view-invoice") ? true : false}
                />
              </td>
              <td className="px-1 py-4">
                <h1>
                  {calculateTotalPriceAfterDiscount(isItemDiscount, item)}
                </h1>
              </td>
              {isItemDiscount && (
                <td className="px-1 py-4 flex items-center">
                  <input
                    defaultValue={item?.item_discount || 0}
                    type="number"
                    name="item_discount"
                    onChange={(e) => handelChange(e, item, index)}
                    onWheel={(e) => e.target.blur()}
                    class=" border border-gray-900 rounded  py-2 px-1 text-gray-950 w-20 "
                  />
                  <span className=" ml-2 font-extrabold text-xl"> %</span>
                </td>
              )}
              <td>
                <div className=" flex items-center space-x-2">
                  {item?.category_name === "Ducting" &&
                    pathname !== "/add-purchase" &&
                    pathname !== `/edit-purchase/${id}` && (
                      <button
                        onClick={(e) => handleEditDucting(e, item)}
                        className="mx-1 py-2 bg-yellow-600 text-white px-2  rounded-full"
                      >
                        <BiEdit size={16} />
                      </button>
                    )}

                  <button onClick={(e) => handleDelete(e, index, item)}>
                    <AiFillMinusCircle size={40} />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className=" flex justify-between mt-1">
        {pathname === "/addinvoice" || pathname?.includes("/edit-invoice") ? (
          <div className=" w-full">
            <label
              for="message"
              class="block mb-2 text-sm font-medium text-gray-900 "
            >
              Bank Information for Payment Transfer
            </label>
            <textarea
              onChange={(e) => setBankInfo(e?.target?.value)}
              value={bankInfo}
              id="message"
              rows="13"
              class=" leading-none block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
              placeholder="Write your Bank Info here..."
            ></textarea>
          </div>
        ) : (
          <div className=" w-full">
            <label
              for="message"
              class="block mb-2 text-sm font-medium text-gray-900 "
            >
              Terms & Conditions
            </label>
            <textarea
              onChange={(e) => setTermsAndConditions(e?.target?.value)}
              value={termsAndConditions}
              id="message"
              rows="4"
              class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
              placeholder="Write your thoughts here..."
            ></textarea>
          </div>
        )}

        <div>
          <div className="  mr-[2%] my-4 ">
            <div className=" flex items-center justify-center my-2">
              <h1 className="text-right text-blue-700 font-semibold  w-32">
                Subtotal
              </h1>
              <h1 className="text-right border border-gray-900 py-2 px-3 w-40 ml-3 rounded-md">
                {subtotal?.toFixed(2)}
              </h1>
            </div>
          </div>
          {isTotalDiscount && (
            <div className="  mr-[2%] my-4 ">
              <div className=" flex items-center justify-center my-2 ">
                <h1 className=" text-blue-700 font-semibold w-32 text-right">
                  Discount <span>{!isFlatDiscount && `(%)`}</span>
                </h1>
                <div className=" text-right border border-gray-900 py-2 px-3 w-40 ml-3 rounded-md">
                  <input
                    onKeyDown={handleKeyDown}
                    defaultValue={discount}
                    onChange={handleDisCount}
                    class=" text-left appearance-none  w-full  text-gray-900 leading-tight focus:outline-none focus:bg-white focus:border-green-500"
                    id="inline-full-name"
                    type="text"
                    placeholder="Discount "
                    name="discount"
                    disabled={pathname.includes("/view-invoice") ? true : false}
                  />
                </div>
              </div>
            </div>
          )}
          {isShowGST && (
            <div className="  mr-[2%] my-4 ">
              <div className=" flex items-center justify-center my-2 ">
                <h1 className="text-right text-blue-700 font-semibold w-32 ">
                  GST (9%)
                </h1>
                <h1 className="text-right border border-gray-900 py-2 px-3 w-40 ml-3 rounded-md">
                  {gstDiscount?.toFixed(2)}
                </h1>
              </div>
            </div>
          )}

          <div className="  mr-[2%] my-4 ">
            <div className="text-right flex items-center justify-center my-2">
              <h1 className="text-right text-blue-700 font-semibold w-32 ">
                Grand Total
              </h1>
              <h1 className=" border border-gray-900 py-2 px-3 w-40 ml-3 rounded-md">
                {grandTotal?.toFixed(2)}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductList;
