import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useKeypress from "react-use-keypress";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useHeader } from "../../lib/headers";
import {
  addToCartItem,
  handleCurrentItem,
} from "../../Redux/API_Slices/POSSlice";
import Alert from "../Shared/Alert";
const Order = () => {
  const navigate = useNavigate();
  const [paymentType, setPaymentType] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const headers = useHeader();
  const cartItems = useSelector((state) => state.posSlice.orderItems);
  const dispatch = useDispatch();

  const calculateTotalPrice = (item) => {
    const totalPrice =
      item.sell_price * (item.new_quantity ? item?.new_quantity : 1);
    return totalPrice;
  };
  const subtotal = cartItems?.reduce(
    (sum, item) => sum + calculateTotalPrice(item),
    0
  );
  const cashtotal = paymentType?.reduce(
    (sum, item) => sum + Number(item?.amount),
    0
  );

  const handleNumberClick = (number) => {
    if (selectedItem) {
      const newData = [...paymentType];
      const index = newData.findIndex((item) => item?.id === selectedItem.id);
      const data = { ...newData[index] };
      //  const data = newData[index];
      data.amount = data.amount ? String(data.amount) + number : number;
      newData[index] = data;
      setPaymentType(newData);
    }
  };
  const handleCash = () => {
    const data = {
      id: paymentType?.length,
      type: "cash",
      amount: 0,
    };
    setSelectedItem(data);
    setPaymentType([...paymentType, data]);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const handleDelete = (item, index) => {
    const newData = [...paymentType];
    const rest = newData.filter((i) => i.id !== item.id);
    setPaymentType(rest);
  };
  const handleBackSpace = (item) => {
    if (selectedItem) {
      const newData = [...paymentType];
      const index = newData.findIndex((item) => item?.id === selectedItem.id);
      const data = { ...newData[index] };
      if (data.amount > 0) {
        data.amount = 0;
        newData[index] = data;
        setPaymentType(newData);
      } else {
        const rest = newData.filter((i) => i.id !== data.id);
        if (rest.length === 0) {
          setSelectedItem(rest[0]);
        }

        if (rest.length > 0) {
          setSelectedItem(rest[rest.length - 1]);
        }

        setPaymentType(rest);
      }
      //  const data = newData[index];
    }
  };
  const handleEnter = async () => {
    const requestData = {
      product: cartItems,
      sub_total: subtotal,
      grand_total: subtotal,
      date: formatDate(new Date()),
      gst: 0,
      extra: {
        cash: cashtotal,
        changes: cashtotal - subtotal,
      },
    };
    try {
      await axios
        .post(
          `${process.env.REACT_APP_ROOT_URL}/api/order/`,
          requestData,
          headers
        )
        .then((res) => {
          dispatch(handleCurrentItem([]));
          dispatch(addToCartItem([]));
          // Alert("Order Created", "success");
          navigate(`/pos/order/${res?.data?.id}`);
        });
    } catch (error) {
      console.error(error);
    }
  };

  useKeypress("Backspace", handleBackSpace);

  useKeypress("Escape", () => {});

  useKeypress("Enter", () => handleEnter());

  // Move useKeypress to the top level of the component
  useKeypress("1", () => handleNumberClick(1));
  useKeypress("2", () => handleNumberClick(2));
  useKeypress("3", () => handleNumberClick(3));
  useKeypress("4", () => handleNumberClick(4));
  useKeypress("5", () => handleNumberClick(5));
  useKeypress("6", () => handleNumberClick(6));
  useKeypress("7", () => handleNumberClick(7));
  useKeypress("8", () => handleNumberClick(8));
  useKeypress("9", () => handleNumberClick(9));
  useKeypress("0", () => handleNumberClick(0));
  return (
    <div
      id="defaultModal"
      tabIndex="-1"
      aria-hidden="true"
      className="fixed h-screen top-0 left-0 right-0 z-50 flex items-center justify-center w-full p-4 overflow-x-hidden overflow-y-auto bg-black bg-opacity-75"
    >
      <div className="relative w-full max-w-7xl max-h-screen h-[95%] over">
        {/* Modal content */}
        <div className="relative bg-white rounded-lg shadow h-full">
          {/* Modal header */}
          <div className="flex items-start justify-between p-4 border-b rounded-t">
            <h3 className="text-xl font-semibold text-gray-900">
              Point of Sell
            </h3>
            <button
              onClick={() => navigate(-1)}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              data-modal-hide="defaultModal"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          {/* Modal body */}
          <div className="p-6 space-y-6  ">
            <div className=" grid grid-cols-12 gap-6 ">
              {/* sidebar  */}
              <div className=" col-span-3">
                <h1 className=" px-4 py-2 bg-gray-300 w-full text-center font-bold">
                  Payment Method
                </h1>
                <div>
                  <button
                    onClick={handleCash}
                    className=" hover:bg-gray-200 block border w-full py-2  "
                  >
                    Cash
                  </button>
                  <button
                    disabled
                    className=" bg-gray-50 block border-x  w-full py-2  "
                  >
                    Bank
                  </button>
                  <button
                    disabled
                    className=" bg-gray-50 block border w-full py-2  "
                  >
                    Customer Account
                  </button>
                </div>

                {/* summary  */}
                <h1 className="mt-4 px-4 py-2 bg-gray-300 w-full text-center font-bold">
                  Sumary
                </h1>
                <div>
                  {paymentType?.map((item, index) => (
                    <button
                      onClick={() => setSelectedItem(item)}
                      className=" hover:bg-gray-200 block border w-full py-2  "
                    >
                      <div className="px-2 flex justify-between">
                        <p>Cash </p>
                        <div className=" flex items-center">
                          <span className=" mr-2">{item?.amount} </span>
                          <button
                            onClick={() => handleDelete(item, index)}
                            className=" rounded-full p-2 hover:bg-red-400"
                          >
                            {" "}
                            <RxCross2 />
                          </button>
                        </div>
                      </div>
                    </button>
                  ))}
                </div>
              </div>
              {/* sidebar end */}
              <div className=" col-span-9 flex justify-center items-center h-full w-full mx-auto">
                <div className=" w-full">
                  <div className="px-4 py-10 border-2 rounded">
                    <div className=" flex justify-between items-center ">
                      <h1 className=" font-bold text-xl">Cash :{cashtotal}</h1>
                      <h1 className=" font-bold text-xl">
                        Changes :{cashtotal ? cashtotal - subtotal : 0}
                      </h1>
                    </div>
                    <p className=" font-semibold mt-1">
                      Total Amount: {subtotal}
                    </p>
                  </div>
                  <div className="grid grid-cols-3 gap-2 mt-4 max-w-2xl mx-auto  w-full ">
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((number) => (
                      <button
                        key={number}
                        onClick={() => handleNumberClick(String(number))}
                        className="h-16 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring focus:ring-blue-400 rounded p-2 text-center"
                      >
                        {number}
                      </button>
                    ))}

                    <button
                      //   onClick={() => handleEnter()}
                      className="bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring focus:ring-blue-400 rounded p-2 text-center"
                    >
                      Enter
                    </button>
                    <button
                      onClick={() => handleBackSpace()}
                      className="bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring focus:ring-blue-400 rounded p-2 text-center"
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Order;
