import React from "react";
import "./print.css";
import dateFormat from "dateformat";
const PrintPurchase = ({ items, componentRef, quotaionData }) => {
  function formatPageNumber(currentPage, totalPages) {
    const formattedCurrentPage = String(currentPage).padStart(2, "0");
    const formattedTotalPages = String(totalPages).padStart(2, "0");

    return ` ${formattedCurrentPage}/${formattedTotalPages}`;
  }

  const totalItems = quotaionData?.product?.length || 0;
  let itemsPerPage = totalItems > 30 ? 30 : 28;

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const currentPage = 1;
  const pageNumberDisplay = formatPageNumber(currentPage, totalPages);

  const now = new Date(quotaionData?.date ? quotaionData?.date : null);
  const formattedDate = dateFormat(now, "dd-mm-yyyy");
  function calculateTotalPriceAfterDiscount(isItemDiscount, item) {
    const initialTotal =
      Number(
        item?.category_name === "Ducting" ? item?.unit_price : item?.sell_price
      ) * Number(item?.new_quantity);
    console.log("initialTotal", initialTotal);
    if (isItemDiscount && item?.item_discount) {
      const discountPercentage = Number(item.item_discount);

      const discountAmount = (initialTotal * discountPercentage) / 100;
      console.log("discountAmount", discountAmount);
      const totalAfterPercentageDiscount = initialTotal - discountAmount;
      return Math.round(discountAmount);
      // if (isFlatDiscount) {
      //   const discountAmount = Number(item.item_discount);
      //   const totalAfterFlatDiscount = initialTotal - discountAmount;
      //   return Math.round(totalAfterFlatDiscount);
      // } else {

      //   const discountPercentage = Number(item.item_discount);
      //   const discountAmount = (initialTotal * discountPercentage) / 100;
      //   const totalAfterPercentageDiscount = initialTotal - discountAmount;
      //   return Math.round(totalAfterPercentageDiscount);
      // }
    } else {
      return initialTotal.toFixed(2);
    }
  }
  return (
    <div ref={componentRef} className="print-container h-full  w-full ">
      <div class="px-2  ">
        <div>
          <div className=" flex justify-center items-center">
            {/* <img className=" w-36 h-36" src="/company_logo.png" alt="" /> */}
            <div className=" text-center flex justify-center">
              <div className=" w-full  px-4 py-3  sm:p-4 ">
                <h2 class=" text-[22px] text-center  text-green-600 font-bold leading-5">
                  AMA M&E Contractor Pte Ltd
                </h2>
                <div className="text-center mt-2">
                  <dd className=" ml-2 text-xs leading-3  ">
                    Company Registration No. 201700945E
                  </dd>
                </div>
                <div className="text-center mt-2">
                  <p className=" ml-2 text-xs leading-5 ">
                    1 Tuas Bay Close #03-04, Liner, Singapore 636997
                  </p>
                </div>
                <div className="text-center mt-2">
                  <p className=" ml-2 text-xs leading-3  ">
                    Tel: +65 6291 1091 HP: +65 85465715 E-mail:
                    amacontractors.sg@gmail.com
                  </p>
                </div>
                <div>
                  <h1 className=" mt-5 text-base font-bold underline ">
                    Purchase
                  </h1>
                </div>
              </div>
            </div>

            {/* <div className=" ">
              <img className=" w-32 h-24" src="/last.jpg" alt="" />
            </div> */}
          </div>
        </div>
        <div class="py-4">
          {/* <h1 class=" text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {quotaionData?.ref}
          </h1> */}
          <div className=" flex justify-between w-full ">
            <div className=" flex items-center">
              {/* <img className=" w-32 h-24" src="/company_logo.jpg" alt="" /> */}
              <div className="  pr-4  w-96   ">
                <h2 id="summary-heading" class=" text-xl  text-gray-900">
                  {quotaionData?.company_name && (
                    <div className=" border-2  border-gray-700 ">
                      <h1 className=" bg-gray-500 px-2 text-white text-sm font-bold py-1">
                        Customer Add:
                      </h1>
                      {/* <span className=" text-sm font-medium"> Company:</span>{" "} */}
                      <span className=" text-base font-bold p-1">
                        {quotaionData?.company_name}{" "}
                      </span>
                      <p className="my-1 leading-3 p-1">
                        <span className=" text-sm font-medium">
                          {" "}
                          Company Address:
                        </span>{" "}
                        <span className=" text-sm font-medium">
                          {quotaionData?.company_address}{" "}
                        </span>
                      </p>
                    </div>
                  )}
                  <div className=" pr-4">
                    <h1 className=" mt-2 text-sm font-bold underline ">
                      Attention:
                    </h1>
                    <textarea
                      rows={4}
                      disabled
                      className=" ml-6 text-xs mt-1 bg-white w-full "
                      value={quotaionData?.attention}
                    />
                  </div>
                </h2>

                {/* <div className="flex items-center justify-between mt-2">
                  <dt className="text-sm font-medium text-gray-800">PO No: </dt>
                  <dd className=" ml-2 text-sm  ">{quotaionData?.po_no}</dd>
                </div>
                <div className="flex items-center justify-between mt-2">
                  <dt className="text-sm font-medium text-gray-800">DO No: </dt>
                  <dd className=" ml-2 text-sm  "> {quotaionData?.ref}</dd>
                </div> */}
                {/* <div className="flex items-center justify-between mt-2">
                  <dt className="text-sm font-medium text-gray-800">SO No: </dt>
                  <dd className=" ml-2 text-sm  ">{quotaionData?.so_no}</dd>
                </div> */}
              </div>
            </div>

            <div className=" flex justify-end min-w-32 float-right   ">
              <div className=" space-y-1 px-2 py-4">
                <div class="flex items-center justify-right mt-2">
                  <dt class="text-sm font-bold text-gray-800 text-right">
                    NO:{" "}
                  </dt>
                  <dd class=" ml-2 text-sm  font-bold text-gray-800">
                    {quotaionData?.ref}
                  </dd>
                </div>
                <div class="flex items-center justify-right">
                  <dt class="text-sm  font-bold text-gray-800">Date:</dt>
                  <dd class=" ml-2 text-sm  font-bold text-gray-800">
                    {formattedDate}
                  </dd>
                </div>

                <div className=" mt-2 w-64 ">
                  <p className="text-sm  font-bold text-gray-800 ">
                    Site Address:{" "}
                  </p>
                  <p className="break-all  text-sm    font-bold text-gray-800">
                    {quotaionData?.site_address?.address}
                  </p>
                </div>

                {/* <div className="flex items-center justify-between mt-2">
                  <dt className="text-sm font-medium text-gray-800">DO No: </dt>
                  <dd className=" ml-2 text-sm  "> {quotaionData?.ref}</dd>
                </div> */}
                <div className="flex items-center justify-right mt-2">
                  <dt className="text-sm font-bold text-gray-800">
                    Sales Person:{" "}
                  </dt>
                  <dd className=" ml-2 text-sm  font-bold text-gray-800">
                    {quotaionData?.sales_person
                      ? quotaionData?.sales_person
                      : ""}
                  </dd>
                </div>
                <div className="flex items-center justify-right mt-2">
                  <dt className="text-sm font-bold text-gray-800">Page: </dt>
                  <dd className=" ml-2 text-sm  font-bold text-gray-800">
                    {pageNumberDisplay}
                  </dd>
                </div>
              </div>
            </div>
          </div>
          <div class="  w-full">
            <section class="rounded-lg col-span-12 bg-white lg:col-span-8 w-full">
              <table class="w-full text-sm text-left text-gray-500  ">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50  ">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 w-2 border border-gray-950"
                    >
                      S/N
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 border border-gray-950 w-32"
                    >
                      Item
                    </th>
                    <th scope="col" class="px-6 py-3  border border-gray-950">
                      Size
                    </th>
                    <th scope="col" class="px-6 py-3 border border-gray-950">
                      Unit
                    </th>
                    <th scope="col" class="px-6 py-3 border border-gray-950">
                      Quantity
                    </th>
                    <th scope="col" class="px-6 py-3 border border-gray-950">
                      Unit Price(S$)
                    </th>
                    {quotaionData?.is_item_discount && (
                      <th scope="col" class="px-6 py-3 border border-gray-950">
                        Discount {quotaionData?.is_flat_discount ? "" : " (%)"}
                      </th>
                    )}
                    <th scope="col" class="px-6 py-3 border border-gray-950">
                      Amount(S$)
                    </th>
                  </tr>
                </thead>
                <tbody className=" border-gray-950 ">
                  {items?.map((item, index) => (
                    <React.Fragment>
                      <tr
                        className="bg-white   border-r border-gray-950"
                        key={item.id}
                      >
                        <th className=" text-center border-l border-r border-gray-950">
                          {index + 1}
                        </th>
                        <th
                          scope="row"
                          className=" px-2 py-1 font-medium text-gray-900 whitespace-nowrap border-r border-gray-950 "
                        >
                          <div className=" w-52  text-dark text-xs flex flex-wrap ">
                            {item?.category_name === "Ducting" && (
                              <span className=" space-x-1 uppercase">
                                <span className="ml-1">
                                  {item?.ducting_type?.product_type}
                                </span>
                                <span>{item?.ducting_type?.thickness}</span>
                                <span>{item?.ducting_type?.brand_type}</span>

                                <span>{item?.ducting_type?.joint_type}</span>
                              </span>
                            )}
                            <span
                              className={`${
                                item?.category_name === "Ducting" && "ml-1"
                              }`}
                            >
                              {" "}
                              {item?.name}
                            </span>
                          </div>
                        </th>

                        <td className="px-6 py-1 border-r border-gray-950  ">
                          <div className="text-xs flex item-center w-full  ">
                            {/* {item?.category_name === "Ducting" && (
                              <span className="ml-1 flex ">
                                <span>{item?.display_dimensions?.width}X</span>
                                <span>{item?.display_dimensions?.height}</span>
                                {item?.display_dimensions?.length && (
                                  <span>
                                    X{item?.display_dimensions?.length}L
                                  </span>
                                )}
                              </span>
                            )} */}

                            <h1 className=" w-48  break-keep">
                              {item?.description}
                            </h1>
                          </div>
                        </td>
                        <td className="px-6 py-1 border-r border-gray-950 text-center">
                          {item?.unit}
                        </td>
                        <td className="px-6 py-1 border-r border-gray-950 text-center">
                          {item?.new_quantity}
                        </td>
                        <td className="px-6 py-1 border-r border-gray-950 text-center">
                          $
                          {item?.category_name === "Ducting"
                            ? Number(item?.unit_price)?.toFixed(2)
                            : Number(item?.sell_price)?.toFixed(2)}
                        </td>

                        {quotaionData?.is_item_discount && (
                          <td className="px-6 py-1 border-r border-gray-950 text-center">
                            {Number(
                              item?.item_discount > 0 ? item?.item_discount : 0
                            )?.toFixed(2)}
                            {quotaionData?.is_flat_discount ? "" : " %"}
                          </td>
                        )}
                        <td className="px-6 py-1 border-r border-gray-950 text-center">
                          $
                          {calculateTotalPriceAfterDiscount(
                            quotaionData?.is_item_discount,
                            item
                          )}
                        </td>
                      </tr>
                      {(index + 1) % itemsPerPage === 0 &&
                        index + 1 < totalItems && (
                          <tr className="page-break-after">
                            <td colSpan="7"></td>{" "}
                            {/* Adjust the colSpan according to your table */}
                          </tr>
                        )}
                    </React.Fragment>
                  ))}
                  <tr className="bg-white   border border-gray-950">
                    <th
                      colSpan={quotaionData?.is_item_discount ? 7 : 6}
                      className=" text-gray-950  border-r border-gray-950 text-right text-base font-medium"
                    >
                      Total Value Before GST:
                    </th>
                    <th className="  grid grid-cols-2  text-gray-950 text-base font-medium">
                      <span className=" text-right mr-1 ">$</span>{" "}
                      <span className=" ">
                        {Number(quotaionData?.sub_total)?.toFixed(2)}
                      </span>
                    </th>
                  </tr>
                  {quotaionData?.is_show_GST && (
                    <tr className="bg-white   border-r border-gray-950 ">
                      <th
                        colSpan={quotaionData?.is_item_discount ? 7 : 6}
                        className=" text-gray-950  border-r border-gray-950 text-right text-base font-medium"
                      >
                        Add 9% GST:
                      </th>
                      <th className="  grid grid-cols-2   text-gray-950 text-base border-b border-gray-950  font-medium">
                        <span className=" text-right mr-1 ">$</span>{" "}
                        <span className=" ">
                          {Number(quotaionData?.gst)?.toFixed(2)}
                        </span>
                      </th>
                    </tr>
                  )}

                  <tr className="bg-white   border-r border-gray-950">
                    <th
                      colSpan={quotaionData?.is_item_discount ? 7 : 6}
                      className=" text-gray-950  border-r border-gray-950 text-right text-base font-medium"
                    >
                      Total Value{" "}
                      {quotaionData?.is_show_GST && <span>With GST</span>} :
                    </th>
                    <th className="  grid grid-cols-2  text-gray-950 text-base border-b border-gray-950 font-medium">
                      <span className=" text-right mr-1 ">$</span>{" "}
                      <span className=" ">
                        {Number(quotaionData?.grand_total)?.toFixed(2)}
                      </span>
                    </th>
                  </tr>
                </tbody>
              </table>
              {/* <div className=" border-b border-gray-950 py-2 mt-1 flex justify-end pr-5">
                <h1 className=" font-bold">
                  SUB TOTAL BEFORE GST: <span>$</span>{" "}
                  <span className=" px-2">
                    {Number(quotaionData?.sub_total)?.toFixed(2)}
                  </span>
                </h1>
              </div> */}
            </section>
            <div className=" ">
              <p className=" font-bold text-xs">
                <strong>Terms &amp; Conditions:</strong>
              </p>
              <textarea
                rows={6}
                disabled
                className=" ml-6 text-xs mt-1 bg-white w-full h-full"
                value={quotaionData?.terms_and_conditions}
              />
            </div>
            <div className=" flex justify-between items-center px-4">
              <div className=" flex flex-col  ">
                <div className=" flex justify-center">
                  <img className=" w-24" src="/signature.png" alt="" />
                </div>
                <h1 className=" text-xs font-bold border-t-2 border-gray-950">
                  Authorised Signature & Company Stamp
                </h1>
                {/* <h3>AMA M&E Contractor Pte Ltd</h3> */}
              </div>
              <div>
                <div className=" flex justify-between space-x-4 items-center  ">
                  <div className=" text-xs font-bold">
                    <div className=" py-2 mt-1 flex justify-between items-center pr-5 space-x-10">
                      <h1 className=" w-60 font-bold mb-5">Accepted by:</h1>
                      <div>
                        <img className=" w-24 mb-1" src="/uaf.jpg" alt="" />
                        <img className=" w-20" src="/biz.jpg" alt="" />
                      </div>
                    </div>

                    <div className=" border-t-2 border-gray-950 py-2 mt-1 flex justify-between pr-5 space-x-10 ">
                      <h1>Authorised Signature & Company Stamp</h1>
                      {/* <div>
                        <img className=" w-20" src="/biz.jpg" alt="" />
                      </div> */}
                    </div>
                  </div>

                  {/* <h3>AMA M&E Contractor Pte Ltd</h3> */}
                </div>
                {/* <hr />
                <h3>Name and Signature of recipient (company's Stamp) </h3>
                <div className="mt-5 flex items-center justify-between">
                  <h3>Vehicle No:______</h3>
                  <h3>Date & Time:_____</h3>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        {/* <div className=" absolute bottom-2 right-2 ">
          <img className="  h-24" src="/footerlogo2.jpg" alt="" />
        </div> */}
      </div>
    </div>
  );
};

export default PrintPurchase;
