import React from "react";
import PurchaseTable from "./PurchaseTable";

const Purchase = () => {
  return (
    <div>
      <PurchaseTable />
    </div>
  );
};

export default Purchase;
