import { useState } from "react";

const useOrderBy = () => {
  const [orderBy, setOrderBy] = useState([]);
  const [orderByData, setOrderByData] = useState([]);

  const handleOrderByChange = (value) => {
    if (value) {
      setOrderBy({
        ...value,
        name: value.label,
        value: value.label,
      });
    } else {
      setOrderBy(value);
    }
  };

  const handleNewOrderByCreate = (value) => {
    setOrderBy({ label: value, value: value, name: value });
  };

  return {
    orderBy,
    setOrderBy,
    orderByData,
    setOrderByData,
    handleOrderByChange,
    handleNewOrderByCreate,
  };
};

export default useOrderBy;
