import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import CompanyModal from "./CompanyModal";
import SelectedCompaniesTable from "./SelectedCompaniesTable";
import { AiOutlineEye } from "react-icons/ai";
import Alert from "../Shared/Alert";
import axios from "axios";
import { useHeader } from "../../lib/headers";
const MultiInvantory = () => {
  const { id } = useParams();
  const location = useLocation();
  const productName = new URLSearchParams(location.search).get("product_name");
  const [showModal, setShowModal] = useState(false);
  const [products, setProducts] = useState({});
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const token = localStorage.getItem("token");

  const headers = useHeader();

  const fetchData = () => {
    axios
      .get(`${process.env.REACT_APP_ROOT_URL}/api/product/${id}/`, headers)
      .then((response) => {
        console.log("Response data:", response.data);
        const data = response.data;
        setProducts(data);
      })
      .catch((error) => {
        // Handle any errors
        console.error(error);
      });
  };
  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  const handleAddCompanies = (companies) => {
    setSelectedCompanies(companies);
  };

  const handleUpdateCompany = (companyId, updates) => {
    setSelectedCompanies((prevCompanies) =>
      prevCompanies.map((company) =>
        company.id === companyId ? { ...company, ...updates } : company
      )
    );
  };
  const getAllData = async () => {
    try {
      if (token) {
        // Make API request to fetch all data
        const response = await axios.get(
          `${process.env.REACT_APP_ROOT_URL}/api/inventory_movement/?product=${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Handle successful response
        if (response.status === 200) {
          const allData = response.data;
          console.log("allData", allData);
          setSelectedCompanies(allData?.results);
        }
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (token) {
      getAllData();
    }
  }, []);
  const handleRemoveCompany = async (company) => {
    try {
      if (token) {
        // Make API request to remove the company
        await axios
          .delete(
            `${process.env.REACT_APP_ROOT_URL}/api/inventory_movement/${company.id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setSelectedCompanies((prevSelectedCompanies) =>
              prevSelectedCompanies.filter((c) => c.id !== company.id)
            );

            // Show success message
            Alert("Company removed from inventory successfully", "success");
          });
      }
    } catch (error) {
      console.error("Error removing company from inventory:", error);
      // Show error message
      Alert("Error removing company from inventory", "error");
    }
  };
  return (
    <div>
      <h1>Multi Invantory</h1>
      <div>
        <div className="flex justify-between items-center mt-10">
          <h1 className="text-lg font-bold mb-4">{productName}</h1>
          <div className=" flex justify-between items-center space-x-2">
            <Link to={`/inventory/${id}`}>
              <AiOutlineEye size={40} className="cursor-pointer" />
            </Link>
            <button
              className="bg-green-400 px-4 py-2 text-white rounded-md"
              onClick={() => setShowModal(true)}
            >
              Add
            </button>
          </div>
        </div>
        <div>
          {
            <div className="border p-4 rounded mt-4">
              <h2 className="text-lg font-bold mb-4">Company List</h2>
              <SelectedCompaniesTable
                handleRemoveCompany={handleRemoveCompany}
                companies={selectedCompanies}
                onUpdateCompany={handleUpdateCompany}
              />
            </div>
          }
        </div>
      </div>
      {showModal && (
        <CompanyModal
          products={products}
          getAllData={getAllData}
          onClose={() => setShowModal(false)}
          onAddCompanies={handleAddCompanies}
        />
      )}
    </div>
  );
};

export default MultiInvantory;
