import React from "react";
import capitalizeWordLetter from "../lib/capitalizeFirstLetter";

const Test = () => {
  return (
    <div>
      <h1>{capitalizeWordLetter("HELLO BANGLADESH")}</h1>
    </div>
  );
};

export default Test;
