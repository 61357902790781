import React from "react";
import CompanyTable from "./CompanyTable";

const Company = () => {
  return (
    <div>
      <CompanyTable />
    </div>
  );
};

export default Company;
