import React from "react";
import "./print.css";
import dateFormat from "dateformat";
import capitalizeWordLetter from "../lib/capitalizeFirstLetter";

const PrintQuotation = ({ items, componentRef, quotaionData }) => {
  function formatPageNumber(currentPage, totalPages) {
    const formattedCurrentPage = String(currentPage).padStart(2, "0");
    const formattedTotalPages = String(totalPages).padStart(2, "0");

    return ` ${formattedCurrentPage}/${formattedTotalPages}`;
  }

  const totalItems = quotaionData?.product?.length || 0;
  const itemsPerPage = 18;

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const now = new Date(quotaionData?.date ? quotaionData?.date : null);
  const formattedDate = dateFormat(now, "dd-mm-yyyy");

  function calculateTotalPriceAfterDiscount(isItemDiscount, item) {
    const initialTotal =
      Number(
        item?.category_name === "Ducting" ? item?.unit_price : item?.sell_price
      ) * Number(item?.new_quantity);

    if (isItemDiscount && item?.item_discount) {
      const discountPercentage = Number(item.item_discount);
      const discountAmount = (initialTotal * discountPercentage) / 100;
      const totalAfterPercentageDiscount = initialTotal - discountAmount;
      return totalAfterPercentageDiscount?.toFixed(2);
    } else {
      return initialTotal.toFixed(2);
    }
  }

  return (
    <div ref={componentRef} className="print-container h-full w-full">
      {Array(totalPages)
        .fill()
        .map((_, pageIndex) => {
          const startItemIndex = pageIndex * itemsPerPage;
          const endItemIndex = Math.min(
            startItemIndex + itemsPerPage,
            totalItems
          );
          const currentPageItems = items.slice(startItemIndex, endItemIndex);
          const pageNumberDisplay = formatPageNumber(pageIndex + 1, totalPages);

          return (
            <div key={pageIndex} className="page-content">
              <PageHeader
                quotaionData={quotaionData}
                formattedDate={formattedDate}
                pageNumberDisplay={pageNumberDisplay}
                capitalizeWordLetter={capitalizeWordLetter}
              />
              <div>
                <div className="w-full">
                  <section className="page-body rounded-lg col-span-12 bg-white lg:col-span-8 w-full">
                    <table className="text-sm text-left text-black">
                      <thead className="text-xs uppercase bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 w-2 border border-gray-950 text-center"
                          >
                            S/N
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 border border-gray-950 w-32 text-center"
                          >
                            Item
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 border border-gray-950 text-center"
                          >
                            Size
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 border border-gray-950 text-center"
                          >
                            Unit
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 border border-gray-950 text-center"
                          >
                            QTY
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 border border-gray-950 text-center"
                          >
                            Unit Price(S$)
                          </th>
                          {quotaionData?.is_item_discount && (
                            <th
                              scope="col"
                              className="px-6 py-3 border border-gray-950 text-center"
                            >
                              Discount{" "}
                              {quotaionData?.is_flat_discount ? "" : " (%)"}
                            </th>
                          )}
                          <th
                            scope="col"
                            className="px-6 border border-r-2 border-black text-center w-3"
                          >
                            Amount(S$)
                          </th>
                        </tr>
                      </thead>
                      <tbody className="border-gray-950">
                        {currentPageItems.map((item, index) => {
                          const isLastRowOnPage =
                            (index + 1) % itemsPerPage === 0;

                          return (
                            <tr
                              key={index}
                              className={`bg-white border-r border-gray-950 ${
                                isLastRowOnPage ? "last-row-on-page" : ""
                              }`}
                            >
                              <th className="font-normal text-center border-l border-r border-gray-950">
                                {item ? startItemIndex + index + 1 : "\u00A0"}
                              </th>
                              <th
                                scope="row"
                                className="font-normal px-2 text-gray-900 whitespace-nowrap border-r border-gray-950"
                              >
                                {/* <div className="w-52 flex flex-wrap min-h-[1.5rem]"> */}

                                <div
                                  className="w-42 flex flex-wrap min-h-[1.5rem]"
                                  style={{ width: "250px" }}
                                >
                                  {item?.category_name === "Ducting" && (
                                    <span className="space-x-1">
                                      <span className="ml-1">
                                        {item?.ducting_type?.product_type}
                                      </span>
                                      <span>
                                        {item?.ducting_type?.thickness}
                                      </span>
                                      <span>
                                        {item?.ducting_type?.brand_type}
                                      </span>
                                      <span className="uppercase">
                                        {item?.ducting_type?.joint_type}
                                      </span>
                                    </span>
                                  )}
                                  <span
                                    className={`${
                                      item?.category_name === "Ducting" &&
                                      "ml-1"
                                    }`}
                                  >
                                    {item?.parent
                                      ? item?.parent_name
                                      : item?.name || "\u00A0"}
                                  </span>
                                </div>
                              </th>
                              <td className="  px-1 border-r border-gray-950">
                                <div className="text-xs flex item-center w-32 min-h-[1.5rem]">
                                  <h1 className=" line-clamp-2 hyphens-auto  ">
                                    {item?.category_name === "Ducting"
                                      ? item?.display_dimensions
                                      : item?.parent
                                      ? Object.values(item?.variant || {}).join(
                                          "/ "
                                        )
                                      : item?.description || "\u00A0"}
                                  </h1>
                                </div>
                              </td>
                              <td className="px-6 border-r border-gray-950 text-center">
                                {item?.unit || "\u00A0"}
                              </td>
                              <td className="px-6 border-r border-gray-950 text-center">
                                {item?.new_quantity || "\u00A0"}
                              </td>
                              <td className=" text-right px-6 border-r border-gray-950 ">
                                {item
                                  ? `${
                                      item.category_name === "Ducting"
                                        ? Number(item.unit_price)?.toFixed(2)
                                        : Number(item.sell_price)?.toFixed(2)
                                    }`
                                  : "\u00A0"}
                              </td>
                              {quotaionData?.is_item_discount && (
                                <td className="px-6 border-r border-gray-950 text-right">
                                  {item && Number(item?.item_discount) > 0
                                    ? `${Number(item.item_discount).toFixed(
                                        2
                                      )}${
                                        quotaionData?.is_flat_discount
                                          ? ""
                                          : " %"
                                      }`
                                    : "\u00A0"}
                                </td>
                              )}
                              <td className="px-6 border-r border-gray-950 text-right pr-4 w-3">
                                {item
                                  ? `$${calculateTotalPriceAfterDiscount(
                                      quotaionData?.is_item_discount,
                                      item
                                    )}`
                                  : "\u00A0"}
                              </td>
                            </tr>
                          );
                        })}
                        {pageIndex === totalPages - 1 && (
                          <>
                            <tr className="bg-white border border-gray-950">
                              <th
                                colSpan={quotaionData?.is_item_discount ? 7 : 6}
                                className="text-gray-950 border-r border-gray-950 text-right text-base"
                              >
                                Total Value Before GST:
                              </th>
                              <th className="px-6 py-1 border-r border-gray-950 text-right pr-4 border-b">
                                <span>$</span>
                                <span>
                                  {Number(quotaionData?.sub_total)?.toFixed(2)}
                                </span>
                              </th>
                            </tr>
                            {quotaionData?.is_show_GST && (
                              <tr className="bg-white border-r border-gray-950">
                                <th
                                  colSpan={
                                    quotaionData?.is_item_discount ? 7 : 6
                                  }
                                  className="text-gray-950 border-r border-gray-950 text-right text-base font-medium"
                                >
                                  Add 9% GST:
                                </th>
                                <th className="px-6 py-1 border-r border-gray-950 text-right pr-4 border-b">
                                  <span>$</span>
                                  <span>
                                    {Number(quotaionData?.gst)?.toFixed(2)}
                                  </span>
                                </th>
                              </tr>
                            )}
                            <tr className="bg-white border-r border-gray-950">
                              <th
                                colSpan={quotaionData?.is_item_discount ? 7 : 6}
                                className="text-gray-950 border-r border-gray-950 text-right text-base font-medium"
                              >
                                Total Value{" "}
                                {quotaionData?.is_show_GST && (
                                  <span>With GST</span>
                                )}
                                :
                              </th>
                              <th className="px-6 py-1 border-r border-gray-950 text-right pr-4 border-b">
                                <span>$</span>
                                <span>
                                  {Number(quotaionData?.grand_total)?.toFixed(
                                    2
                                  )}
                                </span>
                              </th>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </section>
                </div>
              </div>
              {pageIndex < totalPages && (
                <RepeatingSection quotaionData={quotaionData} />
              )}
            </div>
          );
        })}
    </div>
  );
};

//   company address two lining
function addressFormating(add) {
  let splited = add.split(",");
  console.log("splitted vlaue ", splited);
  const firstPart = splited
    .slice(0, splited.length - 2)
    .join(",")
    .trim();
  const secondPart = splited
    .slice(splited.length - 2)
    .join(",")
    .trim();
  var addValue = (
    <>
      <p>{firstPart}</p>
      <p>{secondPart}</p>
    </>
  );
  return addValue;
}

const PageHeader = ({
  quotaionData,
  formattedDate,
  pageNumberDisplay,
  capitalizeWordLetter,
}) => (
  <section className="page-header">
    <div>
      <div>
        <div className="flex justify-center items-center">
          <div className="text-center flex justify-center">
            <div className="w-full px-4 py-3 sm:p-4">
              <h2 className="text-[22px] text-center text-green-600 font-bold leading-5">
                AMA M&E CONTRACTOR PTE LTD
              </h2>
              <div className="text-center mt-2">
                <dd className="ml-2 text-sm leading-3">
                  Company UEN/GST Registration No. 201700945E
                </dd>
              </div>
              <div className="text-center mt-2">
                <p className="ml-2 text-sm leading-5">
                  1 Tuas Bay Close #03-04, Liner, Singapore 636997
                </p>
              </div>
              <div className="text-center mt-2">
                <p className="ml-2 text-sm leading-3">
                  Tel: +65 6015 0254 HP: +65 8139 9772 E-mail:
                  amacontractors.sg@gmail.com
                </p>
              </div>
              <div>
                <h1 className="mt-5 text-base font-bold underline">
                  QUOTATION
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between w-full">
        <div className="flex items-center">
          <div className="pr-4">
            <h2 id="summary-heading" className="text-xl text-gray-900">
              {quotaionData?.company_name && (
                <div className="border-gray-700">
                  <h1 className="bg-gray-200 w-[320px] px-2 text-black text-sm font-bold">
                    Customer Add:
                  </h1>
                  <span className="text-base font-bold px-1 leading-snug">
                    {quotaionData?.company_name}
                  </span>
                  <p className="leading-snug pl-1 my-0 line-clamp-2 hyphens-auto ">
                    {/* <span className="text-sm font-medium">
                      {quotaionData?.company_address}
                    </span> */}
                    <span className="text-sm text-black">
                      {quotaionData?.company_address
                        ? addressFormating(quotaionData.company_address)
                        : ""}
                    </span>
                  </p>
                </div>
              )}
              {quotaionData?.attention && (
                <div className="pr-4">
                  <p className="mt-1 text-sm font-bold">Attention:</p>
                  <p
                    className="text-black text-xs bg-white w-full"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {quotaionData?.attention}
                  </p>
                </div>
              )}
              {quotaionData?.site_address && (
                <div className="pr-4">
                  <h1 className="mt-2 text-sm font-bold">
                    Project name and Address:
                  </h1>
                  <div
                    className="text-black text-xs bg-white w-full"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    <p className="break-all text-sm text-gray-800">
                      {quotaionData?.site_address?.address},
                      {quotaionData?.site_contact}
                    </p>
                  </div>
                </div>
              )}
            </h2>
          </div>
        </div>
        <div className="flex justify-end min-w-32 float-right">
          <div className="px-2 py-4 space-y-0">
            <div className="flex items-center justify-right mt-2">
              <dt className="text-sm font-bold text-gray-800 text-right">
                NO:
              </dt>
              <dd className="ml-2 text-sm font-bold text-gray-800">
                {quotaionData?.ref}
              </dd>
            </div>
            <div className="flex items-center justify-right">
              <dt className="text-sm font-bold text-gray-800">Date:</dt>
              <dd className="ml-2 text-sm font-bold text-gray-800">
                {formattedDate}
              </dd>
            </div>
            <div className="flex items-center justify-right">
              <dt className="text-sm font-bold text-gray-800">Terms:</dt>
              <dd className="ml-2 text-sm font-bold text-gray-800">
                {quotaionData?.terms}
              </dd>
            </div>
            <div className="flex items-center justify-right mt-2">
              <dt className="text-sm font-bold text-gray-800">Sales Person:</dt>
              <dd className="ml-2 text-sm font-bold text-gray-800 capitalize">
                {quotaionData?.sales_person
                  ? capitalizeWordLetter(quotaionData?.sales_person)
                  : ""}
              </dd>
            </div>
            <div className="flex items-center justify-right mt-2">
              <dt className="text-sm font-bold text-gray-800">Page:</dt>
              <dd className="ml-2 text-sm font-bold text-gray-800">
                {pageNumberDisplay}
              </dd>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const RepeatingSection = ({ quotaionData }) => (
  <section className="repeating-section">
    <div>
      <p className="font-bold text-xs">
        <strong>Terms & Conditions:</strong>
      </p>
      <p
        className="ml-6 text-xs bg-white w-full h-full"
        style={{ whiteSpace: "pre-wrap" }}
      >
        {quotaionData?.terms_and_conditions}
      </p>
    </div>
    <div className="flex justify-between items-center px-4 mt-[-30px]">
      <div className="flex flex-col">
        <div className="flex justify-center">
          <img className="w-24" src="/signature.png" alt="" />
        </div>
        <h1 className="text-xs font-bold border-t-2 border-gray-950">
          Authorised Signature & Company Stamp
        </h1>
      </div>
      <div>
        <div className="flex justify-between space-x-4 items-center">
          <div className="text-xs font-bold">
            <div className="py-2 mt-1 flex justify-between items-center pr-5 space-x-10">
              <h1 className="w-60 font-bold mb-5">Accepted by:</h1>
              <div>
                <img className="w-24 mb-1" src="/uaf.jpg" alt="" />
                <img className="w-20" src="/biz.jpg" alt="" />
              </div>
            </div>
            <div className="w-60 border-t-2 border-gray-950 py-2 mt-1 flex justify-between pr-5 space-x-10">
              <h1>Authorised Signature & Company Stamp</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default PrintQuotation;
