import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  signin: false,
  signup: false,
  signUpType: "",
  userProfile: JSON.parse(localStorage.getItem("userProfile")) || {},
};

const authSlice = createSlice({
  name: "userAuth",
  initialState,
  reducers: {
    handlesignin: (state) => {
      state.signup = false;
      state.signin = !state.signin;
    },
    handlesignup: (state, action) => {
      state.signUpType = action.payload;
      state.signin = false;
      state.signup = !state.signup;
    },
    handlesUserProfile: (state, action) => {
      const data = action.payload;
      data?.permissions?.forEach((permission) => {
        permission.is_selected = false;
      });

      state.userProfile = data;
    },
  },
});

export const { handlesUserProfile, handlesignin, handlesignup } =
  authSlice.actions;

export default authSlice.reducer;
