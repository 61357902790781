import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartItems: JSON.parse(localStorage.getItem("cartItems")) || [],
    orderItems: [],
  },
  reducers: {
    addToCart: (state, action) => {
      const { id } = action.payload;

      const existingItem = state.cartItems.find((item) => item.id === id);
      if (existingItem) {
        existingItem.quantity++;
      } else {
        state.cartItems.push({ ...action.payload, quantity: 1 });
      }
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    removeFromCart: (state, action) => {
      state.cartItems = state.cartItems.filter(
        (item) => item.id !== action.payload
      );
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    clearCart: (state) => {
      state.cartItems = [];
      localStorage.removeItem("cartItems");
    },
    handleOrderItems: (state, action) => {
      state.orderItems = action.payload;
    },
    handleOrders: (state, action) => {
      state.orders = action.payload;
    },
    handleAllFoods: (state, action) => {
      state.foods = action.payload;
    },
    increment: (state, action) => {
      const item = state.cartItems.find((item) => item.id === action.payload);
      if (item) {
        item.quantity++;
        localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      }
    },
    decrement: (state, action) => {
      const item = state.cartItems.find(
        (item) => item.id === action.payload.id
      );

      if (item && item.quantity > 1) {
        item.quantity--;
        localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      }
    },
    decrement2: (state, action) => {
      const item = state.cartItems.find(
        (item) => item.id === action.payload.id
      );

      if (item) {
        item.quantity--;
        if (item.quantity < 1) {
          state.cartItems = state.cartItems.filter(
            (cartItem) => cartItem.id !== item.id
          );
        }
        localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      }
    },
  },
});

export const {
  handleAllFoods,
  handleOrders,
  handleOrderItems,
  decrement2,
  addToCart,
  removeFromCart,
  clearCart,
  increment,
  decrement,
} = cartSlice.actions;

export default cartSlice.reducer;
