import React from "react";

const ProductCard = ({ handleAddToCart, item }) => {
  return (
    <button onClick={() => handleAddToCart(item)} className=" w-40 h-40">
      <div class="rounded-md border h-full ">
        <img
          src={
            item?.image ||
            "https://www.millcentre.ie/wp-content/plugins/store/assets/images/placeholder-img.jpg"
          }
          alt={item?.name}
          class="  w-40 h-20 rounded-t-md  "
        />

        <h1 class="px-1 text-left items-center text-xs mt-2 font-semibold ">
          {item?.name?.length > 50
            ? item?.name.slice(0, 50) + "..."
            : item?.name}
        </h1>
        <p className=" text-left mt-2 font-semibold px-1  text-xs pb-1 ">
          <span> Price: {item?.sell_price}</span>{" "}
        </p>
        <p className=" text-left  font-semibold px-1  text-xs pb-1 ">
          <span>Quantity: {item?.quantity}</span>
        </p>
        {/* <button
            onClick={() => handleAddToCart(item)}
            type="button"
            class="mt-2 w-full  rounded-sm bg-black px-2 py-1.5 text-sm font-semibold text-white "
          >
            Add to Cart
          </button> */}
      </div>
    </button>
  );
};

export default ProductCard;
