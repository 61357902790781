import React, { useEffect, useRef, useState } from "react";
import { useHeader } from "../../lib/headers";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import Alert from "../Shared/Alert";
import ReactToPrint from "react-to-print";
import PrintVDO from "./PrintVDO";
const ViewDO = () => {
  const [deliveryOrder, setDeliveryOrder] = useState([]);
  const [items, setItems] = useState([]);
  const headers = useHeader();
  const navigate = useNavigate();
  const { id } = useParams();
  const componentRef = useRef();
  const fetchDeliveryOrder = async () => {
    try {
      await axios
        .get(
          `${process.env.REACT_APP_ROOT_URL}/api/delivery_order/${id}`,
          headers
        )
        .then((response) => {
          setDeliveryOrder(response.data);
          setItems(response?.data?.product);
        });
    } catch (error) {
      console.error(error);
    }
  };
  console.log("deliver order check", deliveryOrder?.product);
  useEffect(() => {
    fetchDeliveryOrder();
  }, [id]);

  const calculateTotalPrice = (item) => {
    const totalPrice =
      (item.unit_price ? item.unit_price : 1) *
      (item.quantity ? item.quantity : 1);
    return totalPrice;
  };
  const subtotal = deliveryOrder?.product?.reduce(
    (sum, item) => sum + calculateTotalPrice(item),
    0
  );

  const handleStatus = async (status) => {
    const data = {
      status: status,
    };
    try {
      await axios
        .patch(
          `${process.env.REACT_APP_ROOT_URL}/api/delivery_order/${id}/`,
          data,
          headers
        )
        .then((res) => {
          setDeliveryOrder(res?.data);
          Alert("Status Updated", "success");
          navigate("/deliver_order");
        });
    } catch (error) {
      console.error(error);
      Alert("Something Went Wrong", "Failed");
    }
  };
  return (
    <div>
      <div className="hidden">
        <PrintVDO
          items={items}
          componentRef={componentRef}
          deliveryOrder={deliveryOrder}
        />
      </div>
      <div className=" flex items-center justify-between">
        <button
          onClick={() => navigate(-1)}
          className=" border rounded p-2  flex  items-center hover:bg-black hover:text-white "
        >
          <IoIosArrowBack />
          <span className=" ml-2"> Back</span>
        </button>
        <ReactToPrint
          trigger={() => (
            <button className=" px-10 text-center  border rounded p-2  flex  items-center hover:bg-black hover:text-white ">
              Print
            </button>
          )}
          content={() => componentRef.current}
          documentTitle={deliveryOrder?.ref}
        />
      </div>

      <div class=" mx-auto max-w-7xl px-2 lg:px-0">
        <div class="mx-auto max-w-2xl py-8 lg:max-w-7xl px-2">
          <div class="mx-auto max-w-2xl py-8 lg:max-w-7xl px-2">
            <h1 class=" text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {deliveryOrder?.ref}
            </h1>
            <div className=" flex justify-between items-center">
              <div>
                <h2 id="summary-heading" class="pb-0 text-xl  text-gray-900">
                  <>
                    <span className=" font-medium"> Company:</span>{" "}
                    <span>{deliveryOrder?.company_name} </span>
                  </>
                </h2>
                <div class="flex items-center">
                  <dt class="text-sm font-medium text-gray-800">
                    Company Address:
                  </dt>
                  <dd class=" ml-2 text-sm ">
                    {deliveryOrder?.company_address}
                  </dd>
                </div>

                <div class="flex items-center">
                  <dt class="text-sm font-medium text-gray-800">Attention:</dt>
                  <dd class=" ml-2 text-sm ">{deliveryOrder?.attention}</dd>
                </div>

                <div class="flex items-center">
                  <dt class="text-sm font-medium text-gray-800">
                    Project name & Address:
                  </dt>
                  <dd class=" ml-2 text-sm ">
                    {deliveryOrder?.site_address?.address}{" "}
                    {/* {deliveryOrder?.site_contact ? "," : null}{" "}
                    {deliveryOrder?.site_contact} */}
                  </dd>
                </div>
                <div class="flex items-center">
                  <dt class="text-sm font-medium text-gray-800">
                    Delivery Address:
                  </dt>
                  <dd class=" ml-2 text-sm ">
                    {/* {deliveryOrder?.site_address?.address}{" "} */}
                    {/* {deliveryOrder?.site_contact ? "," : null}{" "} */}
                    {deliveryOrder?.site_contact}
                  </dd>
                </div>
              </div>

              <div className=" ">
                <div class="flex items-center">
                  <dt class="text-sm font-medium text-gray-800">NO:</dt>
                  <dd class=" ml-2 text-sm ">{deliveryOrder?.ref}</dd>
                </div>

                <div class="flex items-center">
                  <dt class="text-sm font-medium text-gray-800">Date:</dt>
                  <dd class=" ml-2 text-sm ">{deliveryOrder?.date}</dd>
                </div>

                <div class="flex items-center">
                  <dt class="text-sm font-medium text-gray-800">Order No:</dt>
                  <dd class=" ml-2 text-sm ">{deliveryOrder?.order_number}</dd>
                </div>

                <div class="flex items-center">
                  <dt class="text-sm font-medium text-gray-800">Order By:</dt>
                  <dd class=" ml-2 text-sm ">{deliveryOrder?.order_by}</dd>
                </div>

                <div class="flex items-center">
                  <dt class="text-sm font-medium text-gray-800">Status:</dt>
                  <dd class=" ml-2 text-sm ">{deliveryOrder?.status}</dd>
                </div>

                <div class="flex items-center">
                  <dt class="text-sm font-medium text-gray-800">Terms:</dt>
                  <dd class=" ml-2 text-sm ">{deliveryOrder?.terms}</dd>
                </div>

                <div class="flex items-center">
                  <dt class="text-sm font-medium text-gray-800">
                    Sales Person:
                  </dt>
                  <dd class=" ml-2 text-sm ">{deliveryOrder?.sales_person}</dd>
                </div>
              </div>
            </div>
            <div class=" mt-2  w-full">
              <section class="rounded-lg col-span-12 bg-white lg:col-span-8 w-full">
                <table class="w-full text-sm text-left text-gray-500 ">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
                    <tr>
                      <th>S/N</th>
                      <th scope="col" class="px-6 py-3">
                        Product Description
                      </th>
                      <th scope="col" class="px-6 py-3  ">
                        Size
                      </th>
                      {/* <th scope="col" class="px-6 py-3">
                          Unit Price
                        </th> */}
                      <th scope="col" class="px-6 py-3">
                        Quantity
                      </th>

                      {/* {deliveryOrder?.is_item_discount && (
                          <th scope="col" class="px-6 py-3">
                            Discount
                          </th>
                        )} */}
                      {/* <th scope="col" class="px-6 py-3">
                          Total
                        </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {items?.map((item, index) => (
                      <tr className={` bg-white border-b `} key={item.id}>
                        <th className=" text-center">{index + 1}</th>
                        <th
                          scope="row"
                          className="px-6 py-1 font-medium text-gray-900 whitespace-nowrap "
                        >
                          <div className="text-dark text-xs flex">
                            {item?.category_name === "Ducting" && (
                              <span className=" space-x-1 uppercase">
                                <span className="ml-1">
                                  {item?.ducting_type?.product_type}
                                </span>
                                <span>{item?.ducting_type?.thickness}</span>
                                <span>{item?.ducting_type?.brand_type}</span>

                                <span>{item?.ducting_type?.joint_type}</span>
                              </span>
                            )}
                            <span
                              className={`${
                                item?.category_name === "Ducting" && "ml-1"
                              }`}
                            >
                              {" "}
                              {item?.name}
                            </span>
                          </div>
                        </th>
                        <td className="px-6 py-4">
                          {" "}
                          <div className="text-xs flex items-center ">
                            {/* <span>{item?.description}</span> */}
                            <span>
                              {item?.category_name === "Ducting"
                                ? item?.display_dimensions
                                : item?.parent
                                ? Object.values(item?.variant || {}).join("/ ")
                                : item?.description || "\u00A0"}
                            </span>
                          </div>
                        </td>
                        {/* <td className="px-6 py-4">
                            $
                            {item?.category_name === "Ducting"
                              ? Number(item?.unit_price)?.toFixed(2)
                              : Number(item?.sell_price)?.toFixed(2)}
                          </td> */}
                        <td className="px-6 py-4">
                          {item?.new_quantity} {item?.unit}
                        </td>

                        {/* {deliveryOrder?.is_item_discount && (
                            <td className="px-6 py-4">
                              $
                              {Number(
                                item?.item_discount > 0
                                  ? item?.item_discount
                                  : 0
                              )?.toFixed(2)}
                            </td>
                          )} */}
                        {/* <td className="px-6 py-4">
                            $
                            {Number(
                              item?.category_name === "Ducting"
                                ? item?.unit_price * item.new_quantity
                                : Number(item.sell_price * item.new_quantity)
                            )?.toFixed(2)}
                          </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </section>
              <section
                aria-labelledby="summary-heading"
                class="mt-5 rounded-md bg-white flex justify-end  mr-5"
              >
                <div>
                  <div>
                    <div>
                      <dl class=" space-y-1 px-2 py-4">
                        <div class="flex items-center justify-between">
                          <dt class="text-sm text-gray-800">Total Qty:</dt>
                          <dd class="text-sm font-medium text-gray-900 ml-2">
                            {/* {deliveryOrder?.product?.length} */}
                            {deliveryOrder?.product?.reduce(
                              (sum, item) => sum + parseInt(item.new_quantity),
                              0
                            )}
                          </dd>
                        </div>

                        {/* <div class="flex items-center justify-between">
                            <dt class="text-sm text-gray-800">
                              Price ({deliveryOrder?.product?.length} item)
                            </dt>
                            <dd class="text-sm font-medium text-gray-900">
                              $ {deliveryOrder?.sub_total?.toFixed(2)}
                            </dd>
                          </div> */}
                        {/* {deliveryOrder?.is_total_discount && (
                            <div class="flex items-center justify-between pt-4">
                              <dt class="flex items-center text-sm text-gray-800">
                                <span>Discount</span>
                              </dt>
                              <dd class="text-sm font-medium text-green-700">
                                $ {Number(deliveryOrder?.discount)?.toFixed(2)}
                              </dd>
                            </div>
                          )} */}

                        {/* {deliveryOrder?.is_show_GST && (
                            <div class="flex items-center justify-between py-4">
                              <dt class="flex text-sm text-gray-800">
                                <span>GST</span>
                              </dt>
                              <dd class="text-sm font-medium text-green-700">
                                $ {deliveryOrder?.gst?.toFixed(2)}
                              </dd>
                            </div>
                          )} */}

                        {/* <div class="flex items-center justify-between border-y border-dashed py-4 ">
                            <dt class="text-base font-medium text-gray-900">
                              Total Amount:
                            </dt>
                            <dd class="text-base font-medium text-gray-900">
                              $ {deliveryOrder?.grand_total?.toFixed(2)}
                            </dd>
                          </div> */}
                      </dl>
                    </div>
                  </div>
                </div>
              </section>

              {(deliveryOrder?.status === "Draft" ||
                deliveryOrder?.status === "Pending") && (
                <div>
                  <button
                    onClick={() => handleStatus(3)}
                    className=" border px-4 py-2 rounded mx-2 bg-green-400 hover:bg-green-600 text-white  "
                  >
                    Approve
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewDO;
