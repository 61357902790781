import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import { AiFillDelete } from "react-icons/ai";
import DeleteAlert from "../Shared/DeleteAlert";
import Swal from "sweetalert2";
import axios from "axios";
import { useSelector } from "react-redux";

const InvoiceTable = () => {
  const { invoiceEditId } = useSelector((state) => state.editState);
  const [invoiceData, setinvoiceData] = useState([]);
  const navigate = useNavigate();

  //setting token
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  // delete function
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(
            `${process.env.REACT_APP_ROOT_URL}/api/invoice/${id}/`,
            { headers }
          );
          // After successful deletion, refresh the data
          getInvoiceList();
          // Swal.fire("Deleted!", "Your item has been deleted.", "success");
        } catch (error) {
          console.error(error);
          Swal.fire(
            "Error!",
            "An error occurred while deleting the item.",
            "error"
          );
        }
      }
    });
  };

  // Function to fetch data from the API
  const getInvoiceList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/api/invoice/`,
        { headers }
      );
      setinvoiceData(response?.data?.results);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getInvoiceList();
  }, []);
  console.log("invoiceData", invoiceData);
  return (
    <div className=" m-5  ">
      <div className=" flex justify-between">
        <h1 className=" text-2xl font-bold  text-gray-950 ">Invoice List</h1>
        <Link
          to="/addinvoice"
          className=" bg-gray-900 text-white px-4 py-2 rounded-lg my-2"
        >
          Add Invoice
        </Link>
      </div>
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg ">
        <table class="w-full text-sm text-left text-gray-500 dark::text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark::bg-gray-700 dark::text-gray-400">
            <tr>
              <th scope="col" class="px-6 py-3">
                Code
              </th>
              <th scope="col" class="px-6 py-3">
                Date
              </th>
              {/* <th scope="col" class="px-6 py-3">
                Project Name
              </th> */}
              <th scope="col" class="px-6 py-3">
                Company Name
              </th>
              <th scope="col" class="px-6 py-3">
                Source
              </th>
              <th scope="col" class="px-6 py-3">
                Grand Total
              </th>

              <th scope="col" class="px-6 py-3">
                Status
              </th>
              <th scope="col" class="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {invoiceData.map((item) => (
              <tr
                class={`${
                  item.id == invoiceEditId ? "bg-yellow-400" : "bg-white"
                } bg-white border-b dark::bg-gray-800 dark::border-gray-700`}
              >
                <th class="px-6 py-4">
                  <p>{item?.ref}</p>
                </th>
                <td class="px-6 py-4">{item?.date}</td>
                {/* <td class="px-6 py-4">{item?.project_name}</td> */}
                <td class="px-6 py-4">{item?.company_name}</td>
                <td class="px-6 py-4">{item?.source}</td>
                <td class="px-6 py-4">{item?.grand_total?.toFixed(2)}</td>
                <td class="px-6 py-4">{item?.status}</td>
                <td className="px-6 py-4  ">
                  <div className="  w-full">
                    {/* <button className=" bg-green-600 text-white px-2 py-1 rounded-full">
                    Add Project
                  </button> */}

                    <button className="mx-1 py-2 bg-yellow-600 text-white px-2  rounded-full">
                      <Link to={`/edit-invoice/${item?.id}?ref=${item?.ref}`}>
                        <BiEdit size={16} />
                      </Link>
                    </button>
                    <button className="mx-1 py-2 bg-black text-white px-2  rounded-full">
                      <Link to={`/view-invoice/${item?.id}`}>
                        <AiOutlineEye size={16} />
                      </Link>
                    </button>

                    <button
                      className=" py-2 bg-red-600 text-white px-2  rounded-full"
                      onClick={() => handleDelete(item?.id)}
                    >
                      <AiFillDelete size={16} />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InvoiceTable;
