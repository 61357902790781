import React from "react";
import { Link } from "react-router-dom";

const POSInvoiceTable = React.forwardRef(({ invoice, singleData }, ref) => {
  return (
    <div ref={ref} className=" px-10 ">
      <h1 className=" text-xl my-10git pull text-gray-950 font-bold text-center ">
        Invoice #{singleData?.ref}
      </h1>
      <div className=" flex justify-between items-center mb-4">
        <div>
          <p>Date: {singleData?.date}</p>
        </div>
        <div className=" my-4">
          Status:{singleData?.status}
          {singleData?.status === "Unpaid" && (
            <button
              // onClick={handleStatus}
              className=" w-32 mx-1 py-2 bg-yellow-600 text-white px-2  rounded-full"
            >
              Paid
            </button>
          )}
        </div>
      </div>

      <div class=" overflow-x-auto shadow-md sm:rounded-lg ">
        <table class="  w-full text-sm text-left text-gray-500 dark::text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark::bg-gray-700 dark::text-gray-400">
            <tr>
              <th scope="col" class="px-6 py-3">
                Product Name
              </th>
              <th scope="col" class="px-6 py-3">
                Quantity
              </th>
              {/* <th scope="col" class="px-6 py-3">
            Project Name
          </th> */}
              <th scope="col" class="px-6 py-3">
                Sell Price
              </th>
              <th scope="col" class="px-6 py-3">
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            {invoice &&
              invoice?.map((item) => (
                <tr class="bg-white border-b dark::bg-gray-800 dark::border-gray-700">
                  <th class="px-6 py-4">{item?.name}</th>
                  <td class="px-6 py-4">
                    {item?.new_quantity} {item?.unit}
                  </td>
                  {/* <td class="px-6 py-4">{item?.project_name}</td> */}
                  <td class="px-6 py-4">{item?.sell_price}</td>

                  <td class="px-6 py-4">
                    {item?.sell_price * item?.new_quantity}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className=" flex justify-end mt-5 mr-5">
        <div>
          <dl class=" space-y-1 px-2 py-4">
            <div class="flex items-center justify-between">
              <dt class="text-sm text-gray-800">Sub total</dt>
              <dd class="text-sm font-medium text-gray-900">
                $ {singleData?.sub_total}
              </dd>
            </div>
            <div class="flex items-center justify-between pt-4">
              <dt class="flex items-center text-sm text-gray-800">
                <span>Discount</span>
              </dt>
              <dd class="text-sm font-medium text-green-700">
                $ {singleData?.discount}
              </dd>
            </div>
            <div class="flex items-center justify-between py-4">
              <dt class="flex text-sm text-gray-800">
                <span>GST</span>
              </dt>
              <dd class="text-sm font-medium text-green-700">
                $ {singleData?.gst?.toFixed(2)}
              </dd>
            </div>
            <div class="flex items-center justify-between border-y border-dashed py-4 ">
              <dt class="text-base font-medium text-gray-900">Total Amount</dt>
              <dd class="text-base font-medium text-gray-900">
                $ {singleData?.grand_total}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
});

export default POSInvoiceTable;
