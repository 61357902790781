import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { WMS_Query } from "./API_Query/APIQuery";
import authSlice from "./API_Slices/AuthSlice";
import cartSlice from "./API_Slices/cartSlice";
import POSSlice from "./API_Slices/POSSlice";
import editStateSlice from "./Edit_State_Slice/editStateSlice";
import nestedEditStateSlice from "./Edit_State_Slice/nestedEditStateSlice";

export const store = configureStore({
  reducer: {
    userSlice: authSlice,
    cartSlice: cartSlice,
    posSlice: POSSlice,
    editState: editStateSlice,
    nestedEditState: nestedEditStateSlice,

    [WMS_Query.reducerPath]: WMS_Query.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(WMS_Query.middleware),
  serializableCheck: false,
});
setupListeners(store.dispatch);
