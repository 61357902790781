import React, { useState, useEffect } from "react";
import axios from "axios";
import Alert from "../Shared/Alert";

const CompanyModal = ({ products, getAllData, onClose, onAddCompanies }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [companyQuantities, setCompanyQuantities] = useState({});

  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ROOT_URL}/api/company/?name=${searchTerm}`,
          { headers }
        );
        setSearchResults(response?.data?.results);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    if (searchTerm.trim()) {
      fetchCompanies();
    } else {
      setSearchResults([]);
    }
  }, [searchTerm]);

  const handleAddCompany = async (company) => {
    const dataToSend = {
      company: company?.id,
      mode: 1,
      product: products?.name,
      quantity: companyQuantities?.[company?.id]?.quantity,
      ref: "",
      product_detail: products,
    };
    console.log("token", token);
    try {
      if (token) {
        const response = await axios
          .post(
            `${process.env.REACT_APP_ROOT_URL}/api/inventory_movement/`,
            dataToSend,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            Alert("Company added to inventory successfully", "success");
            setSelectedCompanies((prevSelectedCompanies) => [
              ...prevSelectedCompanies,
              company,
            ]);
          });
      }
    } catch (error) {
      console.error("Error adding company to inventory:", error);
    }
  };
  console.log("companyQuantities", companyQuantities);

  const handleRemoveCompany = async (company) => {
    try {
      if (token) {
        // Make API request to remove the company
        const response = await axios
          .delete(
            `${process.env.REACT_APP_ROOT_URL}/api/inventory_movement/${company.id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setSelectedCompanies((prevSelectedCompanies) =>
              prevSelectedCompanies.filter((c) => c.id !== company.id)
            );
            setCompanyQuantities((prevQuantities) => {
              const updatedQuantities = { ...prevQuantities };
              delete updatedQuantities[company.id];
              return updatedQuantities;
            });
            // Show success message
            Alert("Company removed from inventory successfully", "success");
          });
      }
    } catch (error) {
      console.error("Error removing company from inventory:", error);
      // Show error message
      Alert("Error removing company from inventory", "error");
    }
  };

  const handleQuantityChange = (item, quantity) => {
    const updatedQuantities = { ...item };
    updatedQuantities.quantity = quantity;

    setCompanyQuantities((prevQuantities) => ({
      [item.id]: updatedQuantities,
    }));
  };

  const handleSubmit = () => {
    getAllData();
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-md max-w-7xl w-full h-[95%] overflow-y-auto">
        <div className="p-8">
          <h2 className="text-lg font-bold mb-4">Add Companies</h2>
          <input
            type="text"
            placeholder="Search companies..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full mb-4 border border-gray-300 p-2 rounded-md"
          />
          <div className="max-h-[30rem] overflow-y-auto border-b border-gray-300 mb-4">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                <tr>
                  <th scope="col" className="px-6 py-2">
                    Company Name
                  </th>
                  <th scope="col" className="px-6 py-2">
                    Quantity
                  </th>
                  <th scope="col" className="px-6 py-2">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {searchResults?.map((company) => (
                  <tr key={company.id} className="bg-white border-b ">
                    <th
                      scope="row"
                      className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap "
                    >
                      {company.name}
                    </th>
                    <td className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap ">
                      <input
                        type="number"
                        placeholder="Quantity"
                        value={companyQuantities[company.id]?.quantity || ""}
                        onChange={(e) =>
                          handleQuantityChange(company, e.target.value)
                        }
                        className="border border-gray-300 p-2 rounded-md"
                      />
                    </td>
                    <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark::text-white">
                      <button
                        onClick={() => handleAddCompany(company)}
                        disabled={selectedCompanies.some(
                          (c) => c.id === company.id
                        )}
                        className="bg-green-500 text-white px-2 py-1 rounded-md"
                      >
                        Add
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div>
            <h3 className="text-lg font-bold my-4">Selected Companies</h3>
            <ul className="max-h-48 overflow-y-auto">
              {selectedCompanies?.map((company) => (
                <li
                  key={company.id}
                  className="flex items-center justify-between mb-2"
                >
                  <span>{company.name}</span>
                  <button
                    onClick={() => handleRemoveCompany(company)}
                    className="bg-red-500 text-white px-2 py-1 rounded-md"
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
            <div className="flex justify-end mt-4">
              <button
                onClick={onClose}
                className="mr-2 bg-gray-300 px-4 py-2 rounded-md"
              >
                Cancel
              </button>
              {selectedCompanies?.length > 0 && (
                <button
                  onClick={handleSubmit}
                  disabled={selectedCompanies.length === 0}
                  className="bg-green-500 text-white px-4 py-2 rounded-md"
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyModal;
