import { useState } from "react";

const useDeliveryAddress = () => {
  const [siteContact, setSiteContact] = useState([]);
  const [siteContactData, setSiteContactData] = useState([]);

  const handleDeliveryAddressChange = (value) => {
    if (value) {
      setSiteContact({
        ...value,
        name: value.label,
        value: value.label,
      });
    } else {
      setSiteContact(value);
    }
  };

  const handleNewDeliveryAddressCreate = (value) => {
    setSiteContact({ label: value, value: value, name: value });
  };

  return {
    siteContact,
    setSiteContact,
    siteContactData,
    setSiteContactData,
    handleDeliveryAddressChange,
    handleNewDeliveryAddressCreate,
  };
};

export default useDeliveryAddress;
