import React from "react";
import useKeypress from "react-use-keypress";

const POSModalData = () => {
  const handleEnter = (condition) => {
    if (condition) {
    } else console.log("false");
  };
  useKeypress("Enter", () => handleEnter(false));
  return (
    <div>
      <h1>Pos Modal</h1>
    </div>
  );
};

export default POSModalData;
