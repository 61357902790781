import { useState } from "react";

const useTerms = () => {
  const [terms, setTerms] = useState([]);

  const termsData = [
    { label: "Cash On Delivary", value: "Cash On Delivary" },
    { label: "Project", value: "Project" },
    { label: "30 Days", value: "30 Days" },
  ];
  const handleTermsChange = (value) => {
    setTerms(value);
  };

  const handleNewTermCreate = (value) => {
    setTerms({ label: value, value: value });
  };

  return {
    terms,
    termsData,
    setTerms,
    handleTermsChange,
    handleNewTermCreate,
  };
};

export default useTerms;
