import React, { useEffect, useState } from "react";
import Alert from "../Shared/Alert";
import SelectInput from "../Shared/SelectInput";
import axios from "axios";
import { useHeader } from "../../lib/headers";
import { useNavigate } from "react-router-dom";
import SelectInput2 from "../Shared/SelectedInput2";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import Modal from "../Shared/Modal";
import Modal2 from "../Shared/Modal2";
import CreatableSelect from "react-select/creatable";

const createOption = (label, id, category) => ({
  id,
  label,
  value: label,
  category: category,
  name: label,
  // value: label.toLowerCase().replace(/\W/g, ""),
});
const createOptionForCategory = (label, id) => ({
  id,
  label,
  value: id,
  name: label,
  // value: label.toLowerCase().replace(/\W/g, ""),
});
const AddProduct = () => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
      ["bold", "italic", "underline", "strike"],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }, { direction: "ltr" }],
      ["blockquote", "code-block"],
      [{ header: 1 }, { header: 2 }],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      ["link"],
      ["clean"],
    ],

    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  const [data, setData] = useState([
    {
      attribute: "",
      value: "",
      is_show: true,
    },
  ]);
  const [data2, setData2] = useState([
    {
      variant: "",
      value: [],
    },
  ]);

  const [attributes, setAttributes] = useState([]);
  const [variants, setVariants] = useState([]);
  // const [attributesValue, setAttributesValue] = useState([]);
  const [brand, setBrand] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState();
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [name, setName] = useState("");
  const [status, setStatus] = useState(1);
  const [value, setValue] = useState();
  const [value2, setValue2] = useState();
  const [isReload, setIsReload] = useState(1);
  const [isReload2, setIsReload2] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [is_purchase, setIs_purchase] = useState(true);
  const [has_variant, setHas_variant] = useState(false);
  const [has_Attribute, setHas_Attribute] = useState(false);
  const [color, setColor] = useState("");

  const [is_sell, setIs_sell] = useState(true);
  const [description, setDescription] = useState("");
  const [editorHeight, setEditorHeight] = useState("200px");
  const [showModal, setShowModal] = useState(true);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const headers = useHeader();
  const navigate = useNavigate();

  const fetchCategory = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_ROOT_URL}/api/category/`, headers)
        .then((response) => {
          const data = response.data?.results;
          setCategory([
            ...data.map((item) => createOptionForCategory(item.name, item?.id)),
          ]);
          // setCategory(response.data?.results);
        });
    } catch (error) {
      console.error(error);
    }
  };
  const fetchBrand = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_ROOT_URL}/api/brand/`, headers)
        .then((response) => {
          const data = response.data?.results;

          setOptions([
            ...data.map((item) =>
              createOption(item.name, item?.id, item?.category)
            ),
          ]);
          setBrand(response.data?.results);
        });
    } catch (error) {
      console.error(error);
    }
  };
  const handleCreate = async (inputValue, index) => {
    setIsLoading(true);

    // setData((prevData) => {
    //   const newData = [...prevData];
    //   newData[index]["attribute"] = inputValue;
    //   return newData;
    // });
    const data = {
      name: inputValue,
      // category: selectedCategory?.value,
    };
    try {
      await axios
        .post(`${process.env.REACT_APP_ROOT_URL}/api/brand/`, data, headers)
        .then((res) => {
          const data = res?.data;
          setOptions([createOption(data.name, data?.id, data?.category)]);
          setSelectedBrand({
            label: data?.name,
            name: data?.name,
            value: data?.value,
            id: data?.id,
          });
        });
    } catch (error) {
      console.error(error);
    }

    setTimeout(() => {
      const newOption = createOption(inputValue);
      setIsLoading(false);
      setOptions((prev) => [...prev, newOption]);
      setValue(newOption);
    }, 1000);
  };
  const handleCreateCategory = async (inputValue, index) => {
    setIsLoading(true);

    const data = {
      name: inputValue,
    };

    try {
      let data2;
      await axios
        .post(`${process.env.REACT_APP_ROOT_URL}/api/category/`, data, headers)
        .then((res) => {
          data2 = res?.data;
          setCategory([createOptionForCategory(data2.name, data2?.id)]);
          setSelectedCategory({
            label: data2.name,
            id: data2?.id,
            value: data2?.id,
          });
          setIsLoading(false);
        });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchCategory();
  }, []);
  useEffect(() => {
    // if (selectedCategory?.value) {
    //   fetchBrand();
    // }
    fetchBrand();
  }, []);
  // const loadAttribute = (id) => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_ROOT_URL}/api/attribute_value/?attribute=${id}`,
  //       headers
  //     )
  //     .then((response) => {
  //       // Handle the response data
  //       setAttributesValue(response?.data?.results);
  //     })
  //     .catch((error) => {
  //       // Handle any errors
  //       console.error(error);
  //     });
  // };

  // Fetch data from the API endpoint
  const fetchAttributes = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_ROOT_URL}/api/attribute/`, headers)
        .then((response) => {
          setAttributes(response.data?.results);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const fetchVariants = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_ROOT_URL}/api/variant/`, headers)
        .then((response) => {
          setVariants(response.data?.results);
        });
    } catch (error) {
      console.error(error);
    }
  };

  // Trigger the API call when the component mounts
  useEffect(() => {
    fetchAttributes();
  }, [isReload]);
  useEffect(() => {
    fetchVariants();
  }, [isReload2]);
  const handlePluse = () => {
    setData([
      ...data,
      {
        attribute: "",
        value: "",
        is_show: true,
      },
    ]);
  };
  const handlePluse2 = () => {
    setData2([
      ...data2,
      {
        variant: "",
        value: [],
      },
    ]);
  };
  const handleMinus = (index) => {
    const list = [...data];
    list.splice(index, 1);
    setData(list);

    // setData((prevData) => {
    //   const newData = prevData.filter((_, i) => i !== index);
    //   return newData.map((item, i) => ({
    //     ...item,
    //     index: i,
    //   }));
    // });
  };
  const handleMinus2 = (index) => {
    const list = [...data2];
    list.splice(index, 1);
    setData2(list);

    // setData((prevData) => {
    //   const newData = prevData.filter((_, i) => i !== index);
    //   return newData.map((item, i) => ({
    //     ...item,
    //     index: i,
    //   }));
    // });
  };

  // const handleChange = (e, index, isSelect) => {
  //   if (isSelect) {
  //     setValue(e);
  //     const { label, value, id } = e;
  //     loadAttribute(id);

  //     setData((prevData) => {
  //       const newData = [...prevData];
  //       newData[index]["attribute"] = value;
  //       return newData;
  //     });
  //   } else {
  //     const { name, value } = e.target;
  //     setData((prevData) => {
  //       const newData = [...prevData];
  //       newData[index][name] = value;
  //       return newData;
  //     });
  //   }
  // };
  const handleSubmitProduct = async (e) => {
    e.preventDefault();
    const requestData = {
      name: name,
      // status: status,
      attributes: data,
      variants: has_variant ? data2 : [],
      category: selectedCategory?.value,
      brand: selectedBrand?.id,
      is_sell: is_sell,
      has_variant: has_variant,
      has_attribute: has_Attribute,
      is_purchase: is_purchase,
      color: color,
    };

    await axios
      .post(
        `${process.env.REACT_APP_ROOT_URL}/api/product/`,
        requestData,
        headers
      )
      .then((response) => {
        const data = response?.data;
        Alert("Product Added ", "success");
        navigate(`/product/${data?.id}`);
        // Handle the response data
      })
      .catch((error) => {
        // Handle any errors
        console.error(error);
      });
  };
  const handleHeightChange = (event) => {
    setEditorHeight(event.target.value);
  };

  const handleConfirmCategory = (e) => {
    e.preventDefault();
    setShowModal(false);
  };
  const handleClose = () => {
    // setSelectedItem({});
    setShowModal(false);
  };
  const handleCategory = (e) => {
    setSelectedCategory(e);
  };
  const colorOptions = [
    { value: "Red", label: "Red" },
    { value: "Green", label: "Green" },
    { value: "Blue", label: "Blue" },
    { value: "Yellow", label: "Yellow" },
    { value: "Magenta", label: "Magenta" },
    { value: "Cyan", label: "Cyan" },
    { value: "Black", label: "Black" },
    { value: "White", label: "White" },
    { value: "Gray", label: "Gray" },
    { value: "Maroon", label: "Maroon" },
    { value: "Olive", label: "Olive" },
    { value: "Purple", label: "Purple" },
    { value: "Dark Green", label: "Dark Green" },
    { value: "Navy", label: "Navy" },
    { value: "Orange Red", label: "Orange Red" },
    { value: "Sea Green", label: "Sea Green" },
    { value: "Steel Blue", label: "Steel Blue" },
    { value: "Chocolate", label: "Chocolate" },
    { value: "Brown", label: "Brown" },
    { value: "Cadet Blue", label: "Cadet Blue" },
    { value: "Hot Pink", label: "Hot Pink" },
    { value: "Indian Red", label: "Indian Red" },
    { value: "Indigo", label: "Indigo" },
    { value: "Gold", label: "Gold" },
    { value: "Goldenrod", label: "Goldenrod" },
    { value: "Green Yellow", label: "Green Yellow" },
    { value: "Khaki", label: "Khaki" },
    { value: "Lavender", label: "Lavender" },
    { value: "Lawn Green", label: "Lawn Green" },
    { value: "Lemon Chiffon", label: "Lemon Chiffon" },
    { value: "Light Blue", label: "Light Blue" },
    { value: "Light Coral", label: "Light Coral" },
    { value: "Light Cyan", label: "Light Cyan" },
    { value: "Light Goldenrod Yellow", label: "Light Goldenrod Yellow" },
    { value: "Light Gray", label: "Light Gray" },
    { value: "Light Green", label: "Light Green" },
    { value: "Light Pink", label: "Light Pink" },
    { value: "Light Salmon", label: "Light Salmon" },
    { value: "Light Sea Green", label: "Light Sea Green" },
    { value: "Light Sky Blue", label: "Light Sky Blue" },
    { value: "Light Slate Gray", label: "Light Slate Gray" },
    { value: "Light Steel Blue", label: "Light Steel Blue" },
    { value: "Light Yellow", label: "Light Yellow" },
    { value: "Lime Green", label: "Lime Green" },
    { value: "Linen", label: "Linen" },
    { value: "Fuchsia", label: "Fuchsia" },
    { value: "Medium Aquamarine", label: "Medium Aquamarine" },
    { value: "Medium Blue", label: "Medium Blue" },
    { value: "Medium Orchid", label: "Medium Orchid" },
    { value: "Medium Purple", label: "Medium Purple" },
    { value: "Medium Sea Green", label: "Medium Sea Green" },
    { value: "Medium Slate Blue", label: "Medium Slate Blue" },
    { value: "Medium Spring Green", label: "Medium Spring Green" },
    { value: "Medium Turquoise", label: "Medium Turquoise" },
    { value: "Medium Violet Red", label: "Medium Violet Red" },
    { value: "Midnight Blue", label: "Midnight Blue" },
    { value: "Mint Cream", label: "Mint Cream" },
    { value: "Misty Rose", label: "Misty Rose" },
    { value: "Moccasin", label: "Moccasin" },
    { value: "Navajo White", label: "Navajo White" },
    { value: "Dark Blue", label: "Dark Blue" },
    { value: "Dark Cyan", label: "Dark Cyan" },
    { value: "Dark Goldenrod", label: "Dark Goldenrod" },
    { value: "Dark Gray", label: "Dark Gray" },
    { value: "Dark Green", label: "Dark Green" },
    { value: "Dark Khaki", label: "Dark Khaki" },
    { value: "Dark Magenta", label: "Dark Magenta" },
    { value: "Dark Olive Green", label: "Dark Olive Green" },
    { value: "Dark Orange", label: "Dark Orange" },
    { value: "Dark Orchid", label: "Dark Orchid" },
    { value: "Dark Red", label: "Dark Red" },
    { value: "Dark Salmon", label: "Dark Salmon" },
    { value: "Dark Sea Green", label: "Dark Sea Green" },
    { value: "Dark Slate Blue", label: "Dark Slate Blue" },
    { value: "Dark Slate Gray", label: "Dark Slate Gray" },
    { value: "Dark Turquoise", label: "Dark Turquoise" },
    { value: "Dark Violet", label: "Dark Violet" },
    { value: "Deep Pink", label: "Deep Pink" },
    { value: "Deep Sky Blue", label: "Deep Sky Blue" },
    { value: "Dim Gray", label: "Dim Gray" },
    { value: "Dodger Blue", label: "Dodger Blue" },
    { value: "Firebrick", label: "Firebrick" },
    { value: "Floral White", label: "Floral White" },
    { value: "Forest Green", label: "Forest Green" },
    { value: "Gainsboro", label: "Gainsboro" },
    { value: "Ghost White", label: "Ghost White" },
    { value: "Peach Puff", label: "Peach Puff" },
    { value: "Peru", label: "Peru" },
    { value: "Pink", label: "Pink" },
    { value: "Plum", label: "Plum" },
    { value: "Powder Blue", label: "Powder Blue" },
    { value: "Purple", label: "Purple" },
    { value: "Red", label: "Red" },
    { value: "Rosy Brown", label: "Rosy Brown" },
    { value: "Royal Blue", label: "Royal Blue" },
    { value: "Saddle Brown", label: "Saddle Brown" },
    { value: "Salmon", label: "Salmon" },
    { value: "Sandy Brown", label: "Sandy Brown" },
    { value: "Sea Green", label: "Sea Green" },
    { value: "Sea Shell", label: "Sea Shell" },
    { value: "Sienna", label: "Sienna" },
    { value: "Silver", label: "Silver" },
    { value: "Sky Blue", label: "Sky Blue" },
  ];
  console.log("color", color);
  return (
    <div>
      {/* {showModal && (
        <Modal2
          setShowModal={setShowModal}
          showModal={showModal}
          onClose={handleClose}
        >
          <form onSubmit={handleConfirmCategory} className=" w-full mx-16">
            <div className=" w-full ">
              <label
                for="Category"
                class="block mb-2 text-xl font-medium text-gray-900 dark::text-white"
              >
                Select Category
              </label>
              <CreatableSelect
                required={true}
                isClearable
                isDisabled={isLoading}
                isLoading={isLoading}
                onChange={(newValue) => setSelectedCategory(newValue)}
                onCreateOption={handleCreateCategory}
                options={category}
                value={
                  selectedCategory && {
                    label: selectedCategory?.label,
                    value: selectedCategory?.value,
                  }
                }
                name="category"
                placeholder="Select or create Category..."
              />
             

              <div className=" flex justify-end space-x-4 my-5">
                <button
                  onClick={() => navigate(-1)}
                  className=" px-4 py-2 rounded border text-white hover:bg-red-600 bg-red-400 "
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  // onClick={handleConfirmCategory}
                  className=" px-4 py-2 rounded border text-white hover:bg-green-600 bg-green-400 "
                >
                  Confirm
                </button>
              </div>
            </div>
          </form>
        </Modal2>
      )} */}
      <form onSubmit={handleSubmitProduct}>
        <div class="grid gap-6 mb-6 md:grid-cols-2">
          <div>
            <label
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 "
            >
              Product Name
            </label>
            <input
              onChange={(e) => setName(e?.target?.value)}
              name="product_name"
              type="text"
              class=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              placeholder="Product Name"
              required
            />
          </div>
          <div>
            <label
              for="Category"
              class="block mb-2 text-sm font-medium text-gray-900 dark::text-white"
            >
              category
            </label>
            <CreatableSelect
              // required={true}
              isClearable
              isDisabled={isLoading}
              isLoading={isLoading}
              onChange={(newValue) => setSelectedCategory(newValue)}
              onCreateOption={handleCreateCategory}
              options={category}
              value={
                selectedCategory && {
                  label: selectedCategory?.label,
                  value: selectedCategory?.value,
                }
              }
              name="category"
              placeholder="Select or create category..."
            />
          </div>
          <div>
            <label
              for="brand"
              class="block mb-2 text-sm font-medium text-gray-900 dark::text-white"
            >
              Brand
            </label>
            <CreatableSelect
              isClearable
              isSearchable
              onChange={setSelectedBrand}
              onCreateOption={handleCreate}
              options={options}
              value={
                selectedBrand && {
                  label: selectedBrand?.name,
                  value: selectedBrand?.value,
                }
              }
              name="brand"
              placeholder="Select or create Brand..."
            />
            {/* <select
              name="brand"
              onChange={(e) => setSelectedBrand(e.target.value)}
              id="brand"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark::bg-gray-700 dark::border-gray-600 dark::placeholder-gray-400 dark::text-white dark::focus:ring-blue-500 dark::focus:border-blue-500"
            >
              <option selected>Choose a Brand </option>
              {brand?.map((item) => (
                <option value={item?.id}>{item?.name}</option>
              ))}
            </select> */}
          </div>
          <div>
            <label
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 "
            >
              Color
            </label>

            <CreatableSelect
              isMulti
              isClearable
              options={colorOptions}
              onChange={(newValue) => setColor(newValue)}
            />
            {/* <input
              onChange={(e) => setColor(e?.target?.value)}
              name="color"
              type="text"
              class=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              placeholder="Color"
              required
            /> */}
          </div>

          {/* <div>
          <label
            for="phone"
            class="block mb-2 text-sm font-medium text-gray-900 dark::text-white"
          >
            Status
          </label>
          <select
            name="status"
            onChange={(e) => setStatus(e.target.value)}
            id="status"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark::bg-gray-700 dark::border-gray-600 dark::placeholder-gray-400 dark::text-white dark::focus:ring-blue-500 dark::focus:border-blue-500"
          >
            <option value={1}>Raw</option>
            <option value={2}>Finished</option>
          </select>
        </div> */}
        </div>

        {/* <hr className=" my-3" /> */}
        {/* checkbox section  */}
        <div className=" flex items-center space-x-7 border border-gray-300 py-4 px-6 mb-4 rounded">
          <div class="flex items-center my-2">
            <input
              onChange={(event) => setHas_variant(event.target.checked)}
              checked={has_variant}
              id="variant"
              type="checkbox"
              name="has_variant"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
            />
            <label
              for="variant"
              class="ml-2 text-sm font-medium text-gray-900 dark::text-gray-300"
            >
              Has Variant
            </label>
          </div>

          {/*<div class="flex items-center my-2">*/}
          {/*  <input*/}
          {/*    onChange={(event) => setHas_Attribute(event.target.checked)}*/}
          {/*    checked={has_Attribute}*/}
          {/*    id="attribute"*/}
          {/*    type="checkbox"*/}
          {/*    name="has_attribute"*/}
          {/*    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"*/}
          {/*  />*/}
          {/*  <label*/}
          {/*    for="attribute"*/}
          {/*    class="ml-2 text-sm font-medium text-gray-900 dark::text-gray-300"*/}
          {/*  >*/}
          {/*    Has Attribute*/}
          {/*  </label>*/}
          {/*</div>*/}
          <div class="flex items-center my-2">
            <input
              onChange={(event) => setIs_purchase(event.target.checked)}
              checked={is_purchase}
              id="Purchase"
              type="checkbox"
              name="is_purchase"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
            />
            <label
              for="Purchase"
              class="ml-2 text-sm font-medium text-gray-900 dark::text-gray-300"
            >
              Can be Purchase
            </label>
          </div>
          <div class="flex items-center my-2">
            <input
              onChange={(event) => setIs_sell(event.target.checked)}
              checked={is_sell}
              id="Sell"
              type="checkbox"
              name="is_sell"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
            />
            <label
              for="Sell"
              class="ml-2 text-sm font-medium text-gray-900 dark::text-gray-300"
            >
              Can be Sell
            </label>
          </div>
        </div>
        {/* <hr className=" my-3" /> */}
        {has_Attribute && (
          <div>
            <h1 className=" font-bold">Attribute Section</h1>
            <div
              className={`${
                data?.length > 0 && " rounded border border-gray-200 p-4 mb-5 "
              }`}
            >
              {data?.map((item, index) => {
                return (
                  <div key={index} className=" w-full">
                    <SelectInput
                      selectedCategory={selectedCategory}
                      isReload={isReload}
                      setIsReload={setIsReload}
                      attributes={attributes}
                      // handleChange={handleChange}
                      index={index}
                      // onChange={(e) => handleChange(e, index)}
                      item={item}
                      setValue={setValue}
                      setData={setData}
                      data={data}
                      handlePluse={handlePluse}
                      handleMinus={handleMinus}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {has_variant && (
          <div>
            <h1 className=" font-bold">Variant Section</h1>
            <div
              className={`${
                data2.length > 0 && " rounded border border-gray-200 p-4 mb-5 "
              }`}
            >
              {data2?.map((item, index) => {
                return (
                  <div key={index} className=" w-full">
                    <SelectInput2
                      selectedCategory={selectedCategory}
                      isReload2={isReload2}
                      setIsReload2={setIsReload2}
                      variants={variants}
                      // handleChange={handleChange}
                      index={index}
                      // onChange={(e) => handleChange(e, index)}
                      item={item}
                      setValue2={setValue2}
                      setData2={setData2}
                      data2={data2}
                      handlePluse2={handlePluse2}
                      handleMinus2={handleMinus2}
                      selectedOptions={selectedOptions}
                      setSelectedOptions={setSelectedOptions}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}

        <button
          type="submit"
          // onClick={handleSubmitProduct}
          className="mt-16 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default AddProduct;
