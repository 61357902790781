import { useState } from "react";

const useAttention = () => {
  const [attention, setAttention] = useState([]);
  const [attentionData, setAttentionData] = useState([]);

  const handleAttentionChange = (value) => {
    if (value) {
      setAttention({
        ...value,
        name: value.label,
        value: value.label,
      });
    } else {
      setAttention(value);
    }
  };

  const handleNewAttentionCreate = (value) => {
    setAttention({ label: value, value: value, name: value });
  };

  return {
    attention,
    setAttention,
    attentionData,
    setAttentionData,
    handleAttentionChange,
    handleNewAttentionCreate,
  };
};

export default useAttention;
