import axios from "axios";
import React, { useEffect, useState } from "react";

import CreatableSelect from "react-select/creatable";
import { useHeader } from "../../lib/headers";

const createOption = (label, id) => ({
  id,
  label,
  value: label,
  // value: label.toLowerCase().replace(/\W/g, ""),
});
const createOption2 = (label, id, variantID) => ({
  id,
  label,
  value: label,
  variantID: variantID,
});

const SelectInput = ({
  value,
  setValue2,
  isReload2,
  setIsReload2,
  variants,
  setData2,
  index,
  item,

  data2,
  handlePluse2,
  handleMinus2,
  selectedOptions,
  setSelectedOptions,
  selectedCategory,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [attributesValue, setAttributesValue] = useState([]);
  const [options, setOptions] = useState([]);
  const [options2, setOptions2] = useState([]);
  const [currentData, setCurrentData] = useState({});

  const headers = useHeader();

  useEffect(() => {
    setOptions([
      ...variants.map((attribute) =>
        createOption(attribute.name, attribute?.id)
      ),
    ]);
  }, [variants]);

  const handleChange = (e, index, name, isSelect) => {
    if (isSelect) {
      if (name === "value" && e.length > 0) {
        setCurrentData(e);
        setValue2(e);

        const { label, value, id, attributeId } = e[index];

        loadvariant(attributeId);

        setData2((prevData) => {
          const newData = [...prevData];
          newData[index][name] = value;
          return newData;
        });
      } else {
        setCurrentData(e);
        setValue2(e);

        const { label, value, id, attributeId } = e;

        loadvariant(id);

        setData2((prevData) => {
          const newData = [...prevData];
          newData[index][name] = value;
          return newData;
        });
      }
    } else if (isSelect && !e) {
      setCurrentData({});
      setValue2(null);

      setData2((prevData) => {
        const newData = [...prevData];
        newData[index][name] = null;
        return newData;
      });
    } else if (!isSelect) {
      const { name, value } = e.target;
      setData2((prevData) => {
        const newData = [...prevData];
        newData[index][name] = value;
        return newData;
      });
    }
  };

  const loadvariant = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_ROOT_URL}/api/variant_value/?variant=${id}`,
        headers
      )
      .then((response) => {
        // Handle the response data
        const data = response?.data?.results;

        setOptions2([
          ...data.map((variant) =>
            createOption2(variant.value, variant?.id, variant?.variant)
          ),
        ]);
        setAttributesValue(response?.data?.results);
      })
      .catch((error) => {
        // Handle any errors
        console.error(error);
      });
  };
  const postVariant = async (value) => {
    const data = {
      name: value,
      // category: parseInt(selectedCategory?.value),
    };
    try {
      await axios
        .post(`${process.env.REACT_APP_ROOT_URL}/api/variant/`, data, headers)
        .then((response) => {
          const data = response?.data;
          const newData = {
            id: data?.id,
            label: data.name,
            value: data.name,
          };
          setCurrentData(newData);

          // setIsReload(isReload + 1);
        });
      // setAttributes(response.data?.postVariantValueresults);
    } catch (error) {
      console.error(error);
    }
  };
  const postVariantValue = async (value) => {
    const data = {
      variant: currentData?.id,
      value,
    };
    let newOption;
    try {
      await axios
        .post(
          `${process.env.REACT_APP_ROOT_URL}/api/variant_value/`,
          data,
          headers
        )
        .then((response) => {
          newOption = response;

          loadvariant(response?.data?.variant);
          setIsReload2(isReload2 + 1);
          // setSelectedOptions([...selectedOptions, newOption]);
        });

      // setAttributes(response.data?.results);
    } catch (error) {
      console.error(error);
    }
    return newOption;
  };

  const handleCreate = (inputValue, index) => {
    setIsLoading(true);

    setData2((prevData) => {
      const newData = [...prevData];
      newData[index]["variant"] = inputValue;
      return newData;
    });

    postVariant(inputValue);

    setTimeout(() => {
      const newOption = createOption(inputValue);
      setIsLoading(false);
      setOptions((prev) => [...prev, newOption]);
      setValue2(newOption);
    }, 1000);
  };
  const handleCreate2 = (inputValue, index) => {
    setIsLoading2(true);

    setData2((prevData) => {
      const newData = [...prevData];
      newData[index]["value"] = inputValue;
      return newData;
    });
    if (currentData?.id) {
      postVariantValue(inputValue);
    }

    setTimeout(() => {
      const newOption = createOption2(inputValue);
      setIsLoading2(false);
      setOptions2((prev) => [...prev, newOption]);
      // setValue(newOption);
    }, 1000);
  };
  const handleCreateOption = async (newValue, index) => {
    if (currentData?.id) {
      const res = await postVariantValue(newValue);

      const newOption = {
        label: res?.data?.value,
        value: res?.data?.value,
        variant: res?.data?.variant,
        id: res?.data?.id,
      };

      const newData = [...data2];
      const rest = newData[index];
      newData[index].value = [...rest?.value, newOption];
      setData2(newData);

      // setData2((prevData) => [
      //   ...prevData,
      //   {
      //     variant: currentData.id,
      //     value: [newOption],
      //   },
      // ]);
      setSelectedOptions([...selectedOptions, newOption]);
    }
  };
  const handleVariantValuChange = (selected, index) => {
    const newData = [...data2];
    newData[index].value = selected;
    setData2(newData);
    setSelectedOptions(selected);
    setSelectedOptions(selected);
  };

  return (
    <div className="grid grid-cols-12 gap-4 my-2 place-content-center place-items-center">
      <div className="col-span-5 w-full">
        <label
          htmlFor={`attribute_${index}`}
          className="block mb-2 text-sm font-medium text-gray-900 dark::text-white"
        >
          variant
        </label>
        <CreatableSelect
          required={true}
          isClearable={false}
          isDisabled={isLoading}
          isLoading={isLoading}
          onChange={(e) => handleChange(e, index, "variant", true)}
          onCreateOption={(e) => handleCreate(e, index)}
          options={options}
          value={
            item?.variant && {
              label: item?.variant,
              value: item?.variant,
            }
          }
          name="variant"
        />
      </div>

      <div className="col-span-5 w-full">
        <label
          htmlFor={`value_${index}`}
          className="block mb-2 text-sm font-medium text-gray-900 dark::text-white"
        >
          Value
        </label>
        <div>
          <CreatableSelect
            required={true}
            isMulti
            options={options2}
            value={data2[index]?.value} // Set the value from 'data2' at the current index
            onChange={(selected) => handleVariantValuChange(selected, index)}
            onCreateOption={(value) => handleCreateOption(value, index)}
            isClearable
            isSearchable
            placeholder="Select or create options..."
          />

          {/* <CreatableSelect
            isMulti
            isClearable={false}
            isDisabled={isLoading}
            isLoading={isLoading}
            onChange={(e) => handleChange(e, index, "value", true)}
            onCreateOption={(e) => handleCreate2(e, index)}
            options={options2}
            value={
              item?.variants && {
                label: item?.value,
                value: item?.value,
              }
            }
            name="value"
          /> */}

          {/* {attributesValue?.length > 0 ? (
            <>
              <select
                value={item?.value}
                name="value"
                onChange={(e) => handleChange(e, index, false)}
                id="value"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark::bg-gray-700 dark::border-gray-600 dark::placeholder-gray-400 dark::text-white dark::focus:ring-blue-500 dark::focus:border-blue-500"
              >
                <option>Select</option>
                {attributesValue?.map((item) => (
                  <option value={item?.value}>{item?.value}</option>
                ))}
              </select>
            </>
          ) : (
            <input
              value={item?.value}
              onChange={(e) => handleChange(e, index, false)}
              name="value"
              type="text"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark::bg-gray-700 dark::border-gray-600 dark::placeholder-gray-400 dark::text-white dark::focus:ring-blue-500 dark::focus:border-blue-500"
              placeholder="Value"
              required
            />
          )} */}
        </div>
      </div>
      <div className="col-span-2 mt-5">
        {data2.length === index + 1 && (
          <button
            onClick={handlePluse2}
            className="mx-2 bg-green-400 text-white w-16 rounded-md px-3 py-2"
          >
            +
          </button>
        )}

        <button
          onClick={() => handleMinus2(index)}
          className="bg-red-400 text-white w-16 rounded-md px-3 py-2"
        >
          -
        </button>
      </div>
    </div>
  );
};

export default SelectInput;
