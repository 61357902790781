import React from "react";
import { Navigate } from "react-router-dom";

function PrivateRoute({ children, ...rest }) {
  const token = localStorage.getItem("token");
  const tokenTimestamp = localStorage.getItem("tokenTimestamp");

  let isAuthenticated = false;

  if (token && tokenTimestamp) {
    const currentTime = Date.now();
    const tokenAge = currentTime - parseInt(tokenTimestamp, 10);
    const twentyFourHours = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

    if (tokenAge < twentyFourHours) {
      isAuthenticated = true;
    } else {
      // Token expired, remove it from localStorage
      localStorage.removeItem("token");
      localStorage.removeItem("tokenTimestamp");
    }
  }

  return isAuthenticated ? children : <Navigate to="/login" replace />;
}

export default PrivateRoute;
