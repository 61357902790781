import React from "react";
import CategoryTable from "./CategoryTable";

const Category = () => {
  return (
    <div>
      <CategoryTable />
    </div>
  );
};

export default Category;
