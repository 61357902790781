import { useState } from "react";
import { VscChromeClose } from "react-icons/vsc";

export default function Modal({ title, children, setShowModal, showModal }) {
  return (
    <>
      {/* Main modal */}
      <div
        id="defaultModal"
        tabIndex="-1"
        aria-hidden="true"
        className="fixed h-screen top-0 left-0 right-0 z-50 flex items-center justify-center w-full p-4 overflow-x-hidden overflow-y-auto bg-black bg-opacity-75"
      >
        <div className="relative w-full max-w-5xl max-h-screen h-[100%] overflow-y-scroll ">
          {/* Modal content */}
          <div className="relative bg-white rounded-lg shadow h-full">
            {/* Modal header */}
            <div className="flex items-start justify-between p-4 border-b rounded-t">
              <h3 className="text-xl font-semibold text-gray-900">{title}</h3>
              <button
                onClick={() => setShowModal(false)}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                data-modal-hide="defaultModal"
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  ></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* Modal body */}
            <div className="p-6 space-y-6  ">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
}
