import React from "react";
import ProjectsTable from "./ProjectsTable";

const Projects = () => {
  return (
    <div>
      <ProjectsTable />
    </div>
  );
};

export default Projects;
