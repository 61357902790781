import React, { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { CiBoxList } from "react-icons/ci";
import CreatableSelect from "react-select/creatable";
import Modal from "../Shared/Modal";
import SearchProducts from "./SearchProducts";
import ProductList from "./ProductList";
import QuotitionList from "./QuotitionList";
import axios from "axios";
import { useHeader } from "../../lib/headers";
import Alert from "../Shared/Alert";
import { AiFillPlusCircle } from "react-icons/ai";
import { calculateArea, calculateMeasurement } from "../../lib/measurement";
import { v4 as uuidv4 } from "uuid";
import useTerms from "../../hooks/useTerms";
import { useDispatch } from "react-redux";
import { addIdNumber } from "../../Redux/Edit_State_Slice/editStateSlice";
import { addNestedEditIdNumber } from "../../Redux/Edit_State_Slice/nestedEditStateSlice";
import useAttention from "../../hooks/useAttention";
import useDeliveryAddress from "../../hooks/useDeliveryAddress";
import useOrderBy from "../../hooks/useOrderBy";
const AddDO = () => {
  const dispatch = useDispatch();
  const { terms, setTerms, termsData, handleTermsChange, handleNewTermCreate } =
    useTerms();
  const {
    attention,
    setAttention,
    attentionData,
    setAttentionData,
    handleAttentionChange,
    handleNewAttentionCreate,
  } = useAttention();
  const {
    siteContact,
    setSiteContact,
    siteContactData,
    setSiteContactData,
    handleDeliveryAddressChange,
    handleNewDeliveryAddressCreate,
  } = useDeliveryAddress();

  const {
    orderBy,
    setOrderBy,
    orderByData,
    setOrderByData,
    handleOrderByChange,
    handleNewOrderByCreate,
  } = useOrderBy();
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [companyData, setcompanyData] = useState([]);
  const [company, setcompany] = useState(null);
  const [date, setDate] = useState(new Date().toLocaleDateString());
  const [quotaionData, setquotaionData] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [hideSelectedTab, setHideSelectedTab] = useState(false);
  const [products, setProducts] = useState([]);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [showTextInSearchBox, setShowTextInSearchBox] = useState(true);
  const [searchTitle, setSearchTitle] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [singleData, setSingleData] = useState({});
  const [basicInfoData, setBasicInfoData] = useState({
    do_no: "",
    po_no: "",
    so_no: "",
    terms: "",
    shipping: "",
    terms_and_conditions: "",
    sales_person: "",
  });
  console.log("company", company);
  console.log("COMPANYdATA", companyData);
  const [discount, setDisCount] = useState(0);
  const [selectedCompanyData, setSelectedCompanyData] = useState([]);
  const [siteAddress, setSiteAddress] = useState("");
  const [isLength, setIsLength] = useState(false);
  const [isTotalDiscount, setIsTotalDiscount] = useState(true);
  const [isItemDiscount, setIsItemDiscount] = useState(true);
  const [pricing, setPricing] = useState("");
  const [unit_price, setUnit_price] = useState("");
  const [isManual, setIsManual] = useState(false);
  const [isReload, setIsReload] = useState(1);
  const [area, setArea] = useState("");
  const [error, setError] = useState("");
  const [dimensions, setDimensions] = useState({
    width: "",
    height: "",
    length: "",
  });
  const [displayDimensions, setDisplayDimensions] = useState("");

  const [productTypeValue, setProductTypeValue] = useState("");
  const [thicknesValues, setThicknessValue] = useState("");
  const [brandTypeValue, setBrandTypeValue] = useState("");
  const [jointTypeValue, setJointTypeValue] = useState("");
  const [fitting_type, setFitting_type] = useState("duct");

  const [isShowGST, setIsShowGST] = useState(true);
  const [isFlatDiscount, setIsFlatDiscount] = useState(false);
  const [editDucting, setEditDucting] = useState({});
  const [isEditDucting, setIsEditDucting] = useState(false);

  // const [attention, setAttention] = useState("");
  // const [siteContact, setSiteContact] = useState("");
  // const [orderBy, setOrderBy] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState(
    "1. The above prices are in Singapore Dollars before the 9% GST.\n2. Validity of Quote: 2 (Two) Weeks from the quotation date"
  );
  const [activeItem, setActiveItem] = useState(null);
  const [activeItemList, setActiveItemList] = useState([]);
  const headers = useHeader();
  const navigate = useNavigate();

  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const ref = searchParams.get("ref");

  const handleBacisInfoDataChange = (e) => {
    const { name, value } = e?.target;
    const newData = { ...basicInfoData };
    newData[name] = value;
    setBasicInfoData(newData);
  };

  function searchBoxClick() {
    setShowTextInSearchBox(false);
  }
  console.log("siteAddress", siteAddress);
  useEffect(() => {
    const fetchData = async () => {
      try {
        await axios
          .get(
            `${process.env.REACT_APP_ROOT_URL}/api/delivery_order/${id}/`,
            headers
          )
          .then((response) => {
            const data = response?.data;
            console.log("data from server", data);

            const companyRes = {
              label: data?.company_name,
              value: data?.company,
              sites: data?.sites,
              id: data?.company,
              contact_person: data?.contact_person,
              address: data?.address,
              email: data?.email,
              phone: data?.phone,
              name: data?.company_name,
            };

            const newOption = {
              value: response?.data?.site,
              label: response?.data?.site_address?.address,
            };
            setDisCount(response?.data?.discount);
            setSiteAddress(newOption);

            setcompany(companyRes);
            setSingleData(response?.data);
            setProducts(response?.data?.product);
            setIsFlatDiscount(response?.data?.is_flat_discount);
            setIsItemDiscount(response?.data?.is_item_discount);
            setIsTotalDiscount(response?.data?.is_total_discount);
            setIsShowGST(response?.data?.is_show_GST);

            setTermsAndConditions(response?.data?.terms_and_conditions);
            setOrderNumber(response?.data?.order_number);
            setOrderBy(
              response?.data?.order_by
                ? {
                    label: response.data.order_by,
                    value: response.data.order_by,
                  }
                : { label: "", value: "" }
            );
            setSiteContact(
              response?.data?.site_contact
                ? {
                    label: response.data.site_contact,
                    value: response.data.site_contact,
                  }
                : { label: "", value: "" }
            );
            setAttention(
              response?.data?.attention
                ? {
                    label: response.data.attention,
                    value: response.data.attention,
                  }
                : { label: "", value: "" }
            );
            setTerms(
              response?.data?.terms
                ? { label: response.data.terms, value: response.data.terms }
                : { label: "", value: "" }
            );

            setSingleData(data);
            setBasicInfoData({
              do_no: data?.do_no,
              po_no: data?.po_no,
              so_no: data?.so_no,
              terms: data?.terms,
              shipping: data?.shipping,
              terms_and_conditions: data?.terms_and_conditions,
              sales_person: data?.sales_person,
            });
          });
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [id]);

  const onChangeSearchTitle = (e) => {
    setSearchTitle(e.target.value);
    setShowSearchBox(true);
  };
  const getSearchData = async () => {
    try {
      // const params = new URLSearchParams({ q: searchTitle });
      const response = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/api/product/all/?search=${searchTitle}`,
        headers
      );

      setSearchData(response?.data?.results);
    } catch (error) {}
  };

  useEffect(() => {
    getSearchData();
  }, [searchTitle]);

  // const revealChapterBtn = (index) => {
  //   if (selectedChapter === index) {
  //     setHideSelectedTab(!hideSelectedTab);
  //   } else {
  //     setHideSelectedTab(false);
  //   }
  //   setSelectedChapter(index);
  // };

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showModal]);

  const getcompanyList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/api/company/`,
        headers
      );
      const conpanyData = response?.data?.results;

      const rest = conpanyData.map((item) => {
        return {
          label: item.name,
          value: item.id,
          sites: item.sites,
          id: item?.id,
          contact_person: item.contact_person,
          address: item.address,
          email: item.email,
          phone: item.phone,
          name: item.name,
        };
      });
      setcompanyData(rest);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchData = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/api/quotation/?company=${id}`,
        headers
      );

      setquotaionData(response?.data?.results);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getcompanyList();
  }, []);
  // useEffect(() => {
  //   fetchData(company);
  // }, [company]);

  // const handelChange = (e, item, index, isDuction) => {
  //   const { name, value, checked } = e?.target;
  //   console.log(name, value, checked);
  //   if (name === "is_company_product ") {
  //     setProducts((prevData) => {
  //       const newData = [...prevData];
  //       newData[index]["is_company_product"] = checked;
  //       return newData;
  //     });
  //   } else {
  //     if (name === "new_quantity" && isDuction) {
  //       setProducts((prevData) => {
  //         const newData = [...prevData];
  //         newData[index]["new_quantity"] = value;
  //         newData[index]["useable_quantity"] = value;
  //         return newData;
  //       });
  //     } else {
  //       setProducts((prevData) => {
  //         const newData = [...prevData];
  //         newData[index][name] = value;
  //         return newData;
  //       });
  //     }
  //   }
  // };

  const handelChange = (e, item, index, isDuction) => {
    const { name, value, checked } = e.target;

    // Check if the field name is 'is_company_product'
    if (name === "is_company_product") {
      // Update the 'is_company_product' field in the products array
      setProducts((prevData) => {
        const newData = [...prevData];
        newData[index]["is_company_product"] = checked;
        return newData;
      });
    } else {
      // Check if the field name is 'new_quantity' and isDuction is true
      if (name === "new_quantity" && isDuction) {
        // Update 'new_quantity' and 'useable_quantity' fields in the products array
        setProducts((prevData) => {
          const newData = [...prevData];
          newData[index]["new_quantity"] = value;
          newData[index]["useable_quantity"] = value;
          return newData;
        });
      } else {
        // For other fields, update the corresponding field in the products array
        setProducts((prevData) => {
          const newData = [...prevData];
          newData[index][name] = value;
          return newData;
        });
      }
    }
    if (id) {
      setActiveItemList((prevList) => {
        const newList = new Set(prevList);
        newList.add(index);
        return Array.from(newList);
      });
    } else {
      setActiveItem(index);
    }
  };
  const handleToggleAllCheck = (e, isChecked) => {
    e.preventDefault();
    e.stopPropagation();

    console.log("isChecked", isChecked);
    setProducts((prevData) => {
      const newData = prevData.map((product) => ({
        ...product,
        is_company_product: isChecked,
      }));
      return newData;
    });
  };

  const handleSubmitProduct = async (e) => {
    e.preventDefault();
    const requestData = {
      company: Number(company?.value),
      date: formatDate(date),
      product: products,
      ...basicInfoData,

      sub_total: subtotal,
      gst: gstDiscount,
      discount: discount,
      grand_total: grandTotal,
      site: siteAddress?.id,
      is_total_discount: isTotalDiscount,
      is_show_GST: isShowGST,
      is_item_discount: isItemDiscount,
      is_flat_discount: isFlatDiscount,
      terms_and_conditions: termsAndConditions,
      attention: attention.name,
      site_contact: siteContact.name,
      order_by: orderBy.name,
      order_number: orderNumber,
      terms: terms.value,
    };
    if (id) {
      const newData = {
        company: Number(company?.value) || singleData?.company,
        date: singleData?.date || formatDate(date),
        product: products,
        ...basicInfoData,
        sub_total: subtotal,
        gst: gstDiscount,
        discount: discount,
        grand_total: grandTotal,
        site: siteAddress?.value,
        is_total_discount: isTotalDiscount,
        is_show_GST: isShowGST,
        is_item_discount: isItemDiscount,
        is_flat_discount: isFlatDiscount,
        terms_and_conditions: termsAndConditions,
        attention: attention.name,
        site_contact: siteContact.name,
        ref: ref,
        order_by: orderBy.name,
        order_number: orderNumber,
        terms: terms.value,
      };
      await axios
        .patch(
          `${process.env.REACT_APP_ROOT_URL}/api/delivery_order/${id}/`,
          newData,
          headers
        )
        .then((response) => {
          Alert("Delivery Order Update Successfully ", "success");
          dispatch(addIdNumber({ type: "doEditId", value: id }));
          navigate("/deliver_order");
          // Handle the response data
        })
        .catch((error) => {
          // Handle any errors
          console.error(error);
        });
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_ROOT_URL}/api/delivery_order/`,
          requestData,
          headers
        )
        .then((response) => {
          Alert("Delivery Order Added ", "success");
          navigate("/deliver_order");
          // Handle the response data
        })
        .catch((error) => {
          // Handle any errors
          console.error(error);
        });
    }
  };

  // const handleAddProduct = (item) => {
  //   item.new_quantity = "";

  //   setProducts([...products, item]);
  //   setShowModal(false);
  // };

  const handleAddProduct = (item) => {
    const newItem = {
      ...item,
      uuid: uuidv4(),
      new_quantity: 1,
      is_company_product: false,
    };
    setProducts((selectedRow) => [...selectedRow, newItem]);
    setShowModal(false);
  };

  const handleDelete = (e, index, product) => {
    e.preventDefault();
    e.stopPropagation();
    const rest = products?.filter((item) => item.uuid !== product?.uuid);
    setProducts(rest);
  };

  const handleDimensionChange = (e) => {
    const { id, value } = e.target;
    setDimensions((prev) => ({ ...prev, [id]: value }));
  };

  const handleDisplayDimensionChange = (e) => {
    const { id, value } = e.target;
    setDisplayDimensions(value);
  };

  useEffect(() => {
    if (company?.value) {
      const res = companyData.find((item) => item.value === company?.value);
      const rest = res?.sites?.map((item) => {
        return {
          label: item.address,
          value: item.company,
          id: item?.id,
          address: item.address,
          company: item.company,
          default: item.default,
        };
      });

      if (rest?.length > 0) {
        setSelectedCompanyData(rest);
      } else {
        setSelectedCompanyData([]);
      }
    }
  }, [company?.value]);

  const handleDisCount = (e) => {
    setDisCount(e?.target?.value);
  };
  const calculateTotalPrice = (item) => {
    let baseTotalPrice =
      item?.category_name === "Ducting"
        ? item?.unit_price * item.new_quantity
        : item.sell_price * item.new_quantity;

    let discountPercentage = Number(item.item_discount);
    discountPercentage = isNaN(discountPercentage) ? 0 : discountPercentage;
    let discountAmount = (baseTotalPrice * discountPercentage) / 100;
    const totalPrice = baseTotalPrice - discountAmount;

    return totalPrice;
  };

  // Calculate the subtotal for all items
  const subtotal = products.reduce(
    (sum, item) => sum + calculateTotalPrice(item),
    0
  );

  let withDiscount;

  if (isTotalDiscount) {
    if (isFlatDiscount) {
      withDiscount = subtotal - (isNaN(discount) ? 0 : discount);
    } else {
      const discountValue = isNaN(discount) ? 0 : discount;
      const discountAmount = (subtotal * discountValue) / 100;
      withDiscount = subtotal - discountAmount;
    }
  } else {
    withDiscount = subtotal;
  }

  withDiscount = Math.max(withDiscount, 0);

  // Calculate the GST (9%) discount
  const gstDiscount = isNaN(withDiscount) ? 0 : withDiscount * 0.09;

  // Calculate the grand total
  let grandTotal = isNaN(withDiscount) ? 0 : withDiscount;

  if (isShowGST) {
    const gstDiscount = isNaN(withDiscount) ? 0 : withDiscount * 0.09;
    grandTotal += gstDiscount;
  }

  // Output the results
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Output the results

  const handleAddProductForDuction = (e, item) => {
    e.preventDefault();
    const newData = { ...item, uuid: uuidv4(), is_company_product: false };
    const ductingType = {
      product_type: productTypeValue,
      thickness: thicknesValues,
      brand_type: brandTypeValue,
      joint_type: jointTypeValue,
    };
    newData.dimensions = dimensions;
    newData.isManual = isManual;
    newData.area = isManual
      ? area
      : isLength
      ? Number(calculateArea(dimensions)).toFixed(2)
      : Number(calculateMeasurement(dimensions)).toFixed(2);

    newData.display_dimensions = displayDimensions;
    newData.description = displayDimensions;
    newData.is_length = isLength;
    newData.costing = isLength
      ? calculateArea(dimensions)
      : calculateMeasurement(dimensions);
    newData.unit_price = unit_price;
    newData.ducting_type = ductingType;
    newData.fitting_type = fitting_type;

    newData.new_quantity = 1;
    setProducts([...products, newData]);
    setProductTypeValue("");
    setThicknessValue("");
    setBrandTypeValue("");
    setJointTypeValue("");
    setIsManual(false);
    setArea("");
    setDimensions({
      width: "",
      height: "",
      length: "",
    });
    setPricing("");
    setShowModal(false);
  };

  const revealChapterBtn = (index, item) => {
    if (selectedChapter == index) {
      setHideSelectedTab(!hideSelectedTab);
    } else {
      setHideSelectedTab(false);
    }

    setSelectedChapter(index);
  };

  const productType = [
    {
      name: "G",
      value: "G",
    },
    {
      name: "GI",
      value: "GI",
    },

    {
      name: "Aluminium",
      value: "ALUMINIUM",
    },
    {
      name: "SS",
      value: "SS",
    },
    {
      name: "SD",
      value: "SD",
    },
    {
      name: "SS304",
      value: "SS304",
    },
    {
      name: "SS316",
      value: "SS316",
    },
  ];
  const thickness = [0.5, 0.6, 0.8, 1.0, 1.2, 1.5, 2.0, 3.0, 5.0, 8.0, 10.0];
  const brandType = [
    { name: "jp", value: "JP" },
    { name: "cha", value: "CHA" },
    { name: "nip", value: "NIP" },
  ];

  const jointType = [
    { name: "S/C", value: "s/c" },
    { name: "S/C  F/D", value: "s/c f/d" },
    { name: "TDF", value: "tdf" },
    { name: "F/D", value: "f/d" },
    { name: "TDF  F/D", value: "tdf f/d" },
  ];

  const handleChange = (newValue) => {
    setcompany(newValue);
    setSiteAddress("");
    let siteAddresses = newValue.sites;
    siteAddresses = siteAddresses.map((item) => {
      return {
        ...item,
        label: item.address,
        value: item.address,
      };
    });
  };
  const handleCreateOption = async (inputValue) => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const data = {
      name: inputValue,
    };

    const response = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/api/company/`,
      data,
      { headers }
    );
    if (response?.data?.id) {
      const newOption = {
        value: response?.data?.id,
        label: response?.data?.name,
      };

      setcompanyData((prevOptions) => [...prevOptions, newOption]);
      // setSelectedCompanyData([newOption2]);
      setcompany(newOption);
      setSiteAddress("");
    }
  };
  const handleCreateSiteAddress = async (inputValue) => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const data = {
      address: inputValue,
      company: Number(company?.value),
    };
    const response = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/api/sites/`,
      data,
      { headers }
    );

    if (response?.data?.id) {
      const newOption = {
        value: response?.data?.id,
        label: response?.data?.address,
      };
      setSelectedCompanyData([...selectedCompanyData, newOption]);
      setSiteAddress(newOption);
      setSiteContact(newOption);
    }

    // const newOption = {
    //   value: inputValue.toLowerCase().replace(/\W/g, ""),
    //   label: inputValue,
    // };
    // setSelectedCompanyData((prevOptions) => [...prevOptions, newOption]);
    // setSiteAddress(newOption);
  };

  useEffect(() => {
    const loadPricing = () => {
      // console.log("thicknesValues", thicknesValues)
      const data = {
        fitting_type: fitting_type,
        thickness: thicknesValues,
        material_type: productTypeValue,
        country_origin: brandTypeValue,
      };
      if (data.thickness === 1) {
        data.thickness = "1.0";
      }
      console.log("updated_data", data);
      const response = axios.post(
        `${process.env.REACT_APP_ROOT_URL}/api/calculate-price/`,
        data
      );
      response
        .then((response) => {
          setError("");
          setPricing(response?.data?.price);
        })
        .catch((error) => {
          setError(error?.response?.data?.error || "Something went wrong");
          console.log("error", error);
          setPricing(0);
        });
    };
    if (fitting_type && thicknesValues && brandTypeValue && productTypeValue) {
      loadPricing();
    }
  }, [
    isReload,
    isManual,
    fitting_type,
    thicknesValues,
    productTypeValue,
    brandTypeValue,
  ]);

  const handleRadioChange = (event) => {
    setFitting_type(event.target.value);
  };

  // const unit_price = isLength
  //   ? calculateArea(dimensions) * pricing
  //   : calculateMeasurement(dimensions) * pricing;

  useEffect(() => {
    let pricePerUnit;

    if (isManual) {
      // If isManual is true, calculate unit price based on area * pricing
      pricePerUnit = area * pricing;
    } else {
      // Otherwise, decide based on isLength
      pricePerUnit = isLength
        ? calculateArea(dimensions) * pricing
        : calculateMeasurement(dimensions) * pricing;
    }

    setUnit_price(parseFloat(pricePerUnit.toFixed(2)));
  }, [isLength, dimensions, pricing, isManual, area]);

  const handleEditDucting = (e, itemToEdit) => {
    e.preventDefault();
    e.stopPropagation();
    setIsReload(isReload + 1);
    setEditDucting(itemToEdit);
    //change Pricing here
    setPricing(itemToEdit?.costing);
    setIsManual(itemToEdit?.isManual);
    setArea(itemToEdit?.area);
    setIsLength(itemToEdit?.is_length);
    setProductTypeValue(itemToEdit?.ducting_type?.product_type);
    setThicknessValue(Number(itemToEdit?.ducting_type?.thickness)?.toFixed(1));
    setBrandTypeValue(itemToEdit?.ducting_type?.brand_type);
    setJointTypeValue(itemToEdit?.ducting_type?.joint_type);
    setDimensions(itemToEdit.dimensions);
    setDisplayDimensions(itemToEdit?.display_dimensions);

    setIsEditDucting(true);
    setShowModal(false);
    setShowModal2(true);
  };

  const handleUpdateProductForDuction = (e, editItem) => {
    e.preventDefault();

    dispatch(
      addNestedEditIdNumber({ type: "nestedDoEditId", value: editItem.uuid })
    );

    const updatedItem = {
      ...editDucting,
      dimensions: dimensions,
      display_dimensions: displayDimensions,
      is_length: isLength,
      ducting_type: {
        product_type: productTypeValue,
        thickness: thicknesValues,
        brand_type: brandTypeValue,
        joint_type: jointTypeValue,
      },
      costing: unit_price,
      unit_price: unit_price,
      area: isManual
        ? area
        : isLength
        ? Number(calculateArea(dimensions)).toFixed(2)
        : Number(calculateMeasurement(dimensions)).toFixed(2),
    };

    const updatedRows = products.map((item) =>
      item.uuid === updatedItem.uuid ? updatedItem : item
    );

    setProducts(updatedRows);
    resetFormStates();
  };
  const resetFormStates = () => {
    setEditDucting({});
    setIsEditDucting(false);
    setShowModal2(false);
    setProductTypeValue("");
    setThicknessValue("");
    setBrandTypeValue("");
    setJointTypeValue("");
    setDimensions({ width: "", height: "", length: "" });
    setDisplayDimensions("");
    setPricing("");
    setIsManual(false);
    setArea(0);
    setIsLength(false);
    setUnit_price(0);
    setError("");
  };

  const handleAddProductModal = () => {
    setShowModal(true);
    setShowModal2(false);
    resetFormStates();
  };

  const getAttentionData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/api/attention/?company=${company?.id}`
        // { headers }
      );

      let attentionDataList = response?.data?.results;
      console.log("attetntion details list", attentionDataList);

      attentionDataList = attentionDataList
        .filter((singleItem) => singleItem.name) // This filters out items where 'name' is falsy
        .map((singleItem) => ({
          ...singleItem,
          label: singleItem.name,
          value: singleItem.name,
        }));

      setAttentionData(attentionDataList);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (company?.id) {
      getAttentionData();
    }
  }, [company?.id]);

  const getDeliverAddresssData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/api/sites/?company=${company?.id}`
        // { headers }
      );

      let attentionDeliveryAddList = response?.data?.results;

      attentionDeliveryAddList = attentionDeliveryAddList
        .filter((singleItem) => singleItem.address) // This filters out items where 'name' is falsy
        .map((singleItem) => ({
          ...singleItem,
          label: singleItem.address,
          value: singleItem.address,
        }));

      setSiteContactData(attentionDeliveryAddList);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (company?.id) {
      getDeliverAddresssData();
    }
  }, [company?.id]);

  const getOrderByData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/api/order_by/?company=${company?.id}`
        // { headers }
      );

      let orderByList = response?.data?.results;

      orderByList = orderByList
        .filter((singleItem) => singleItem.name) // This filters out items where 'name' is falsy
        .map((singleItem) => ({
          ...singleItem,
          label: singleItem.name,
          value: singleItem.name,
        }));

      setOrderByData(orderByList);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (company?.id) {
      getOrderByData();
    }
  }, [company?.id]);

  return (
    <>
      {showModal && (
        <Modal
          title="Add Product"
          showModal={showModal}
          setShowModal={setShowModal}
        >
          <div
            className=" rounded-lg border-[1px] border-gray-200 h-full "
            onClick={searchBoxClick}
          >
            <div class="relative w-full">
              <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  class="w-5 h-5 text-gray-500 "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                type="text"
                id="simple-search"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5           "
                placeholder="Enter Product Name"
                required
                value={searchTitle}
                onChange={onChangeSearchTitle}
              />
            </div>

            {/* <input
        type="text"
        className="lg:w-[500px]  bg-[#FFFFFF] rounded-[12px] p-2.5"
        onChange={searchInput}
        ref={inputRef}
      /> */}

            <div className="  mx-4 ">
              {!showSearchBox && <>{showTextInSearchBox}</>}
            </div>
            {showSearchBox && (
              <>
                <div className="my-2  rounded-lg max-h-[80%] w-[95%] bg-white  overflow-y-auto absolute z-[1000]">
                  <div className="pt-2 px-4">
                    <div>
                      <table class="w-full text-sm text-left text-gray-500  ">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50    ">
                          <tr>
                            <th scope="col" class="px-6 py-3  max-w-xs">
                              Product name
                            </th>
                            <th scope="col" class="px-6 py-3">
                              category
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Brand
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Available
                            </th>
                            <th scope="col" class="px-6 py-3">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {searchData?.map((item, index) => (
                            <>
                              {item?.category_name === "Ducting" ? (
                                <>
                                  <tr class="bg-white border-b    0">
                                    <th
                                      scope="row"
                                      class="px-6 py-1 font-medium text-gray-900 max-w-xs break-words whitespace-normal"
                                    >
                                      <p> {item?.name}</p>
                                    </th>
                                    <td class="px-6 py-4">
                                      {item?.category_name}
                                    </td>
                                    <td class="px-6 py-4">
                                      {item?.brand_name}
                                    </td>
                                    <td className="px-6 py-4">
                                      {item?.quantity}
                                    </td>
                                    <button
                                      class="px-6 py-4"
                                      onClick={() => revealChapterBtn(index)}
                                    >
                                      <div className=" flex items-center relative">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth="1.5"
                                          stroke="currentColor"
                                          className={`w-6 h-6   ${
                                            selectedChapter === index &&
                                            !hideSelectedTab
                                              ? " rotate-180 text-primary-orange font-bold"
                                              : "text-[#707070]"
                                          }`}
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                          />
                                        </svg>
                                      </div>
                                    </button>
                                  </tr>

                                  {selectedChapter === index &&
                                    !hideSelectedTab && (
                                      <tr class="bg-white w-full">
                                        <td colspan="5">
                                          <form
                                            onSubmit={(e) =>
                                              handleAddProductForDuction(
                                                e,
                                                item
                                              )
                                            }
                                          >
                                            {error && (
                                              <p className="text-red-400 text-center mt-2">
                                                {error}
                                              </p>
                                            )}
                                            <div className="mt-4 border-b rounded px-4 py-2.5  font-bold flex justify-between items-center ">
                                              <div class="flex items-center">
                                                <input
                                                  onChange={() =>
                                                    setIsManual(!isManual)
                                                  }
                                                  checked={isManual}
                                                  id="isManual"
                                                  type="checkbox"
                                                  value={!isManual}
                                                  class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500     focus:ring-2    "
                                                />
                                                <label
                                                  for="isManual"
                                                  class="ml-2 text-sm font-medium text-gray-900  "
                                                >
                                                  Is Manual
                                                </label>
                                              </div>
                                              {!isManual && (
                                                <div>
                                                  <div class="flex items-center">
                                                    <input
                                                      onChange={() =>
                                                        setIsLength(!isLength)
                                                      }
                                                      checked={isLength}
                                                      id="isEndCap"
                                                      type="checkbox"
                                                      value={!isLength}
                                                      class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500     focus:ring-2    "
                                                    />
                                                    <label
                                                      for="isEndCap"
                                                      class="ml-2 text-sm font-medium text-gray-900  "
                                                    >
                                                      {isLength
                                                        ? "With Length "
                                                        : "Without  Length"}
                                                    </label>
                                                  </div>
                                                </div>
                                              )}

                                              <div className="flex items-center px-2 rounded-md space-x-3 border">
                                                <div className="flex items-center">
                                                  <input
                                                    id="fromDuct"
                                                    type="radio"
                                                    value="duct"
                                                    name="selection"
                                                    className="w-7 h-7 text-blue-600"
                                                    onChange={handleRadioChange}
                                                    checked={
                                                      fitting_type === "duct"
                                                    }
                                                  />
                                                  <label
                                                    htmlFor="fromDuct"
                                                    className="w-full py-4 ms-2 text-sm font-medium text-gray-900  "
                                                  >
                                                    ST Duct
                                                  </label>
                                                </div>
                                                <div className="flex items-center">
                                                  <input
                                                    id="fromFittings"
                                                    type="radio"
                                                    value="fitting"
                                                    name="selection"
                                                    className="w-7 h-7 text-blue-600"
                                                    onChange={handleRadioChange}
                                                    checked={
                                                      fitting_type === "fitting"
                                                    }
                                                  />
                                                  <label
                                                    htmlFor="fromFittings"
                                                    className="w-full py-4 ms-2 text-sm font-medium text-gray-900  "
                                                  >
                                                    Fittings
                                                  </label>
                                                </div>
                                              </div>
                                              <div>
                                                <label
                                                  htmlFor="Pricing"
                                                  className="block text-xs font-medium text-gray-900"
                                                >
                                                  Pricing
                                                </label>
                                                <input
                                                  type="text"
                                                  id="Pricing"
                                                  value={pricing}
                                                  onChange={(e) =>
                                                    setPricing(e.target.value)
                                                  }
                                                  className="border pt-1 px-2 py-1.5 rounded w-[6.5rem] "
                                                  placeholder="Pricing"
                                                  required
                                                />
                                              </div>

                                              {!isManual && (
                                                <h3>
                                                  Area:
                                                  {isLength
                                                    ? Number(
                                                        calculateArea(
                                                          dimensions
                                                        )
                                                      )
                                                    : Number(
                                                        calculateMeasurement(
                                                          dimensions
                                                        )
                                                      )}
                                                </h3>
                                              )}
                                              <div>
                                                <label
                                                  htmlFor="unit_price"
                                                  className="block text-xs font-medium text-gray-900"
                                                >
                                                  Unit Price
                                                </label>
                                                <input
                                                  type="text"
                                                  id="unit_price"
                                                  value={unit_price}
                                                  onChange={(e) =>
                                                    setUnit_price(
                                                      e.target.value
                                                    )
                                                  }
                                                  className="border pt-1 px-2 py-1.5 rounded w-[6.5rem] "
                                                  placeholder="unit_price"
                                                  required
                                                />
                                              </div>

                                              {/* <h3>
                                                Unit Price:
                                                <span className=" mx-2">
                                                  {Number(unit_price).toFixed(
                                                    2
                                                  )}
                                                </span>
                                              </h3> */}
                                              <button
                                                type="submit"
                                                className=" text-gray-950 border  rounded px-4 py-2"
                                              >
                                                Add
                                              </button>
                                            </div>
                                            <div
                                              colspan="5"
                                              className=" mt-2  font-bold flex justify-between  items-center "
                                            >
                                              <div className=" border px-4 py-2.5 rounded ">
                                                {isManual ? (
                                                  <div>
                                                    <input
                                                      type="text"
                                                      id="length"
                                                      value={area}
                                                      onChange={(e) =>
                                                        setArea(e.target.value)
                                                      }
                                                      className="border px-2 py-3 rounded w-[20rem] "
                                                      placeholder="Area"
                                                      required
                                                    />
                                                  </div>
                                                ) : (
                                                  <div>
                                                    <h3>
                                                      Dimention of Calculation
                                                    </h3>
                                                    <div class="flex  items-center my-1 space-x-1 ">
                                                      <input
                                                        type="text"
                                                        id="width"
                                                        value={dimensions.width}
                                                        onChange={
                                                          handleDimensionChange
                                                        }
                                                        className="border px-2 py-2 rounded w-[6.5rem]"
                                                        placeholder="Width"
                                                        required
                                                      />
                                                      <input
                                                        type="text"
                                                        id="height"
                                                        value={
                                                          dimensions.height
                                                        }
                                                        onChange={
                                                          handleDimensionChange
                                                        }
                                                        className="border px-2 py-2 rounded w-[6.5rem]"
                                                        placeholder="Height"
                                                        required
                                                      />
                                                      {!isLength && (
                                                        <input
                                                          type="text"
                                                          id="length"
                                                          value={
                                                            dimensions.length
                                                          }
                                                          onChange={
                                                            handleDimensionChange
                                                          }
                                                          className="border px-2 py-2 rounded w-[6.5rem]"
                                                          placeholder="Length"
                                                          required
                                                        />
                                                      )}
                                                    </div>
                                                  </div>
                                                )}
                                              </div>

                                              <div className="ml-2 border px-4 py-2 rounded w-full ">
                                                {/* <h3>Display Dimention</h3> */}
                                                <div class="flex  items-center my-1 space-x-1 w-full ">
                                                  <input
                                                    type="text"
                                                    id="displayDimensions"
                                                    value={displayDimensions}
                                                    onChange={
                                                      handleDisplayDimensionChange
                                                    }
                                                    className="border px-2 py-2 rounded w-full h-12"
                                                    placeholder="Display of Dimensions"
                                                    required
                                                  />
                                                  {/* <input
                                                    type="text"
                                                    id="height"
                                                    value={
                                                      displayDimensions?.height
                                                    }
                                                    onChange={
                                                      handleDisplayDimensionChange
                                                    }
                                                    className="border px-2 py-2 rounded w-[6.5rem]"
                                                    placeholder="Height"
                                                    required
                                                  />
                                                  {!isLength && (
                                                    <input
                                                      type="text"
                                                      id="length"
                                                      value={
                                                        displayDimensions?.length
                                                      }
                                                      onChange={
                                                        handleDisplayDimensionChange
                                                      }
                                                      className="border px-2 py-2 rounded w-[6.5rem]"
                                                      placeholder="Length"
                                                      required
                                                    />
                                                  )} */}
                                                </div>
                                              </div>
                                            </div>

                                            <div class="grid grid-cols-4 gap-4 w-full my-5 border border-gray-400 p-4 rounded">
                                              <div>
                                                <h1>Product Type</h1>
                                                <div class="mt-4">
                                                  {productType?.map((p, i) => (
                                                    <div class="flex items-center mb-4">
                                                      <input
                                                        onChange={() =>
                                                          setProductTypeValue(
                                                            p?.value
                                                          )
                                                        }
                                                        id={`productType${
                                                          i + 1
                                                        }`}
                                                        type="radio"
                                                        name="productType"
                                                        value={p?.name}
                                                        className="w-5 h-5 text-blue-600"
                                                      />
                                                      <label
                                                        for={`productType${
                                                          i + 1
                                                        }`}
                                                        class="ms-2 text-sm font-medium text-gray-900  "
                                                      >
                                                        {p?.name?.toUpperCase()}
                                                      </label>
                                                    </div>
                                                  ))}
                                                </div>
                                              </div>
                                              <div>
                                                <h1 class="text-center">
                                                  Thickness
                                                </h1>
                                                <div class="flex justify-between mx-4">
                                                  <div class="flex flex-col justify-between mt-4 mr-4">
                                                    {thickness
                                                      ?.slice(0, 6)
                                                      .map((t, i) => (
                                                        <div
                                                          key={i}
                                                          class="flex items-center mb-4"
                                                        >
                                                          <input
                                                            onChange={() =>
                                                              setThicknessValue(
                                                                t.toFixed(1)
                                                              )
                                                            }
                                                            id={`Thickness${
                                                              i + 1
                                                            }`}
                                                            type="radio"
                                                            name="thickness"
                                                            value={t}
                                                            className="w-5 h-5 text-blue-600"
                                                          />
                                                          <label
                                                            htmlFor={`Thickness${
                                                              i + 1
                                                            }`}
                                                            class="ml-2 text-sm font-medium text-gray-900  "
                                                          >
                                                            {t?.toFixed(1)}
                                                          </label>
                                                        </div>
                                                      ))}
                                                  </div>

                                                  <div class="flex flex-col justify-between mt-4">
                                                    {thickness
                                                      ?.slice(6)
                                                      .map((t, i) => (
                                                        <div
                                                          key={i + 6}
                                                          class="flex items-center mb-4"
                                                        >
                                                          <input
                                                            onChange={() =>
                                                              setThicknessValue(
                                                                t
                                                              )
                                                            }
                                                            id={`Thickness${
                                                              i + 7
                                                            }`}
                                                            type="radio"
                                                            name="thickness"
                                                            value={t}
                                                            className="w-5 h-5 text-blue-600"
                                                          />
                                                          <label
                                                            htmlFor={`Thickness${
                                                              i + 7
                                                            }`}
                                                            class="ml-2 text-sm font-medium text-gray-900  "
                                                          >
                                                            {t?.toFixed(1)}
                                                          </label>
                                                        </div>
                                                      ))}
                                                  </div>
                                                </div>
                                              </div>
                                              <div>
                                                <h1>Country of Origin</h1>
                                                <div class="mt-4">
                                                  {brandType?.map((b, i) => (
                                                    <div class="flex items-center mb-4">
                                                      <input
                                                        onChange={() =>
                                                          setBrandTypeValue(
                                                            b?.value
                                                          )
                                                        }
                                                        id={`BrandType${i + 1}`}
                                                        type="radio"
                                                        name="brandType"
                                                        value={b?.name}
                                                        className="w-5 h-5 text-blue-600"
                                                      />
                                                      <label
                                                        for={`BrandType${
                                                          i + 1
                                                        }`}
                                                        class="ms-2 text-sm font-medium text-gray-900  "
                                                      >
                                                        {b?.name?.toUpperCase()}
                                                      </label>
                                                    </div>
                                                  ))}
                                                </div>
                                              </div>
                                              <div>
                                                <h1>Joint Type</h1>
                                                <div class="mt-4">
                                                  {jointType?.map((j, i) => (
                                                    <div class="flex items-center mb-4">
                                                      <input
                                                        onChange={() =>
                                                          setJointTypeValue(
                                                            j?.value
                                                          )
                                                        }
                                                        id={`JointType${i + 1}`}
                                                        type="radio"
                                                        name="jointType"
                                                        value=""
                                                        className="w-5 h-5 text-blue-600"
                                                      />
                                                      <label
                                                        for={`JointType${
                                                          i + 1
                                                        }`}
                                                        class="ms-2 text-sm font-medium text-gray-900  "
                                                      >
                                                        {j?.name?.toUpperCase()}
                                                      </label>
                                                    </div>
                                                  ))}
                                                </div>
                                              </div>
                                            </div>
                                          </form>
                                        </td>
                                      </tr>
                                    )}
                                </>
                              ) : (
                                <tr class="bg-white border-b    0">
                                  <th
                                    scope="row"
                                    class="px-6 py-1 font-medium text-gray-900 max-w-xs break-words whitespace-normal"
                                  >
                                    <p> {item?.name}</p>
                                  </th>
                                  <td class="px-6 py-4">
                                    {item?.category_name}
                                  </td>
                                  <td class="px-6 py-4">{item?.brand_name}</td>
                                  <td className="px-6 py-4">
                                    {item?.quantity}
                                  </td>
                                  <td class="px-6 py-4">
                                    {item?.quantity > 0 ? (
                                      <button
                                        onClick={() => handleAddProduct(item)}
                                      >
                                        <AiFillPlusCircle size={30} />
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              )}
                            </>
                          ))}
                        </tbody>
                      </table>

                      {searchData?.length < 1 && (
                        <h1 className="text-center py-4 text-gray-500">
                          There is no Data found
                        </h1>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </Modal>
      )}

      {showModal2 && (
        <Modal
          title="Edit Ducting"
          showModal={showModal2}
          setShowModal={setShowModal2}
        >
          <div className=" rounded-lg overflow-y-scroll   ">
            <table class="w-full text-sm text-left text-gray-500 ">
              <tbody>
                <tr class="bg-white w-full">
                  <td colspan="5">
                    <form
                      onSubmit={(e) =>
                        handleUpdateProductForDuction(e, editDucting)
                      }
                    >
                      <div className="mt-4 border-b rounded px-4 py-2.5  font-bold flex justify-between items-center ">
                        <div class="flex items-center">
                          <input
                            onChange={() => setIsManual(!isManual)}
                            checked={isManual}
                            id="isManual"
                            type="checkbox"
                            value={!isManual}
                            class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500     focus:ring-2    "
                          />
                          <label
                            for="isManual"
                            class="ml-2 text-sm font-medium text-gray-900  "
                          >
                            Is Manual
                          </label>
                        </div>
                        {!isManual && (
                          <div>
                            <div class="flex items-center">
                              <input
                                onChange={() => setIsLength(!isLength)}
                                checked={isLength}
                                id="isEndCap"
                                type="checkbox"
                                value={!isLength}
                                class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500     focus:ring-2    "
                              />
                              <label
                                for="isEndCap"
                                class="ml-2 text-sm font-medium text-gray-900  "
                              >
                                {isLength ? "With Length " : "Without  Length"}
                              </label>
                            </div>
                          </div>
                        )}

                        <div className="flex items-center px-2 rounded-md space-x-3 border">
                          <div className="flex items-center">
                            <input
                              id="fromDuct"
                              type="radio"
                              value="duct"
                              name="selection"
                              className="w-7 h-7 text-blue-600"
                              onChange={handleRadioChange}
                              checked={fitting_type === "duct"}
                            />
                            <label
                              htmlFor="fromDuct"
                              className="w-full py-4 ms-2 text-sm font-medium text-gray-900  "
                            >
                              ST Duct
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="fromFittings"
                              type="radio"
                              value="fitting"
                              name="selection"
                              className="w-7 h-7 text-blue-600"
                              onChange={handleRadioChange}
                              checked={fitting_type === "fitting"}
                            />
                            <label
                              htmlFor="fromFittings"
                              className="w-full py-4 ms-2 text-sm font-medium text-gray-900  "
                            >
                              Fittings
                            </label>
                          </div>
                        </div>

                        {isManual ? (
                          <input
                            type="text"
                            id="length"
                            value={pricing}
                            onChange={(e) => setPricing(e.target.value)}
                            className="border px-2 py-2 rounded w-[6.5rem] "
                            placeholder="Pricing"
                            required
                          />
                        ) : (
                          <h3>Pricing : {pricing}</h3>
                        )}
                        <h3>
                          Area:
                          {isLength
                            ? Number(calculateArea(dimensions))
                            : Number(calculateMeasurement(dimensions))}
                        </h3>
                        <h3>
                          Unit Price:
                          <span className=" mx-2">
                            {Number(unit_price).toFixed(2)}
                          </span>
                        </h3>
                        <button
                          type="submit"
                          className=" text-gray-950 border  rounded px-4 py-2"
                        >
                          Add
                        </button>
                      </div>
                      <div
                        colspan="5"
                        className=" mt-2  font-bold flex justify-between  items-center "
                      >
                        <div className=" border px-4 py-2.5 rounded ">
                          {isManual ? (
                            <div>
                              <input
                                type="text"
                                id="length"
                                value={area}
                                onChange={(e) => setArea(e.target.value)}
                                className="border px-2 py-3 rounded w-[20rem] "
                                placeholder="Area"
                                required
                              />
                            </div>
                          ) : (
                            <div>
                              <h3>Dimention of Calculation</h3>
                              <div class="flex  items-center my-1 space-x-1 ">
                                <input
                                  type="text"
                                  id="width"
                                  value={dimensions.width}
                                  onChange={handleDimensionChange}
                                  className="border px-2 py-2 rounded w-[6.5rem]"
                                  placeholder="Width"
                                  required
                                />
                                <input
                                  type="text"
                                  id="height"
                                  value={dimensions.height}
                                  onChange={handleDimensionChange}
                                  className="border px-2 py-2 rounded w-[6.5rem]"
                                  placeholder="Height"
                                  required
                                />
                                {!isLength && (
                                  <input
                                    type="text"
                                    id="length"
                                    value={dimensions.length}
                                    onChange={handleDimensionChange}
                                    className="border px-2 py-2 rounded w-[6.5rem]"
                                    placeholder="Length"
                                    required
                                  />
                                )}
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="ml-2 border px-4 py-2 rounded w-full ">
                          <div class="flex  items-center my-1 space-x-1 w-full ">
                            <input
                              type="text"
                              id="displayDimensions"
                              value={displayDimensions}
                              onChange={handleDisplayDimensionChange}
                              className="border px-2 py-2 rounded w-full h-12"
                              placeholder="Display of Dimensions"
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <div class="grid grid-cols-4 gap-4 w-full my-5 border border-gray-400 p-4 rounded">
                        <div>
                          <h1>Product Type</h1>
                          <div class="mt-4">
                            {productType?.map((p, i) => (
                              <div class="flex items-center mb-4">
                                <input
                                  onChange={() => setProductTypeValue(p?.value)}
                                  id={`productType${i + 1}`}
                                  type="radio"
                                  name="productType"
                                  value={p?.name}
                                  checked={productTypeValue === p.value}
                                  className="w-5 h-5 text-blue-600"
                                />
                                <label
                                  for={`productType${i + 1}`}
                                  class="ms-2 text-sm font-medium text-gray-900  "
                                >
                                  {p?.name?.toUpperCase()}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div>
                          <h1 class="text-center">Thickness</h1>
                          <div class="flex justify-between mx-4">
                            <div class="flex flex-col justify-between mt-4 mr-4">
                              {thickness?.slice(0, 6).map((t, i) => (
                                <div key={i} class="flex items-center mb-4">
                                  <input
                                    onChange={() => setThicknessValue(t)}
                                    id={`Thickness${i + 1}`}
                                    type="radio"
                                    name="thickness"
                                    value={thicknesValues}
                                    checked={thicknesValues == t}
                                    className="w-5 h-5 text-blue-600"
                                  />
                                  <label
                                    htmlFor={`Thickness${i + 1}`}
                                    class="ml-2 text-sm font-medium text-gray-900  "
                                  >
                                    {t?.toFixed(1)}
                                  </label>
                                </div>
                              ))}
                            </div>

                            <div class="flex flex-col justify-between mt-4">
                              {thickness?.slice(6).map((t, i) => (
                                <div key={i + 6} class="flex items-center mb-4">
                                  <input
                                    onChange={() => setThicknessValue(t)}
                                    id={`Thickness${i + 7}`}
                                    type="radio"
                                    name="thickness"
                                    value={thicknesValues}
                                    checked={thicknesValues == t}
                                    className="w-5 h-5 text-blue-600"
                                  />
                                  <label
                                    htmlFor={`Thickness${i + 7}`}
                                    class="ml-2 text-sm font-medium text-gray-900  "
                                  >
                                    {t?.toFixed(1)}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div>
                          <h1>Country of Origin</h1>
                          <div class="mt-4">
                            {brandType?.map((b, i) => (
                              <div class="flex items-center mb-4">
                                <input
                                  onChange={() => setBrandTypeValue(b?.value)}
                                  id={`BrandType${i + 1}`}
                                  type="radio"
                                  name="brandType"
                                  value={b?.name}
                                  checked={brandTypeValue === b.value}
                                  className="w-5 h-5 text-blue-600"
                                />
                                <label
                                  for={`BrandType${i + 1}`}
                                  class="ms-2 text-sm font-medium text-gray-900  "
                                >
                                  {b?.name?.toUpperCase()}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div>
                          <h1>Joint Type</h1>
                          <div class="mt-4">
                            {jointType?.map((j, i) => (
                              <div class="flex items-center mb-4">
                                <input
                                  onChange={() => setJointTypeValue(j?.value)}
                                  id={`JointType${i + 1}`}
                                  type="radio"
                                  name="jointType"
                                  value={j.value}
                                  checked={jointTypeValue === j.value}
                                  className="w-5 h-5 text-blue-600"
                                />
                                <label
                                  for={`JointType${i + 1}`}
                                  class="ms-2 text-sm font-medium text-gray-900  "
                                >
                                  {j?.name?.toUpperCase()}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </form>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal>
      )}
      {/* <div className=" ">
        <div>
          <div className=" flex items-center ">
            <select
              value={company}
              onChange={(e) => setcompany(e?.target?.value)}
              id="company"
              class="w-52 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5          "
            >
              <option selected>Select company</option>
              {companyData?.map((item) => (
                <option value={item?.id} key={item?.id}>
                  {item?.name}
                </option>
              ))}
            </select>
            <div class="w-52 mx-4 relative max-w-sm">
              <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  class="w-5 h-5 text-gray-500  "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>

              <input
                defaultValue={id && singleData?.date}
                onChange={(e) => setDate(e.target.value)}
                type="date"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5          "
                placeholder="Select date"
              />
            </div>
          </div>
        </div>
      </div>
    

      <div>
        <div class="grid gap-6 mb-6 md:grid-cols-3 mt-5">
          <div>
            <label
              for="do_no"
              class="block mb-2 text-sm font-medium text-gray-900  "
            >
              DO No:
            </label>
            <input
              onChange={handleBacisInfoDataChange}
              name="do_no"
              value={basicInfoData?.do_no}
              type="text"
              id="do_no"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="do no"
              required
            />
          </div>
          <div>
            <label
              for="po_no"
              class="block mb-2 text-sm font-medium text-gray-900  "
            >
              PO NO:
            </label>
            <input
              onChange={handleBacisInfoDataChange}
              name="po_no"
              value={basicInfoData?.po_no}
              type="text"
              id="po_no"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="po no"
              required
            />
          </div>
          <div>
            <label
              for="so_no"
              class="block mb-2 text-sm font-medium text-gray-900  "
            >
              SO NO:
            </label>
            <input
              value={basicInfoData?.so_no}
              onChange={handleBacisInfoDataChange}
              name="so_no"
              type="text"
              id="so_no"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="so no"
              required
            />
          </div>
          <div>
            <label
              for="terms"
              class="block mb-2 text-sm font-medium text-gray-900  "
            >
              Terms
            </label>
            <input
              onChange={handleBacisInfoDataChange}
              name="terms"
              value={basicInfoData?.terms}
              type="text"
              id="terms"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="terms"
              required
            />
          </div>
          <div>
            <label
              for="shipping"
              class="block mb-2 text-sm font-medium text-gray-900  "
            >
              Shipping
            </label>
            <input
              onChange={handleBacisInfoDataChange}
              name="shipping"
              value={basicInfoData?.shipping}
              type="url"
              id="shipping"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Shipping"
              required
            />
          </div>
          <div>
            <label
              for="Sales"
              class="block mb-2 text-sm font-medium text-gray-900  "
            >
              Sales Person
            </label>
            <input
              onChange={handleBacisInfoDataChange}
              name="sales_person"
              value={basicInfoData?.sales_person}
              type="text"
              id="Sales"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Salse Person"
              required
            />
          </div>
        </div>
      </div>

      <div className=" flex justify-end">
        <button
          onClick={() => setShowModal(true)}
          className=" my-10 bg-gray-900 text-white px-4 py-2 rounded-lg mr-[2%] "
        >
          Add Products
        </button>
      </div> */}
      <div className=" flex justify-center items-center">
        <div>
          <h1 className=" text-3xl my-4 text-gray-950 font-bold text-center ">
            {id && <span className=" mr-1">Edit</span>} Delivery Order{" "}
            {ref && <span className=" ml-1 text-gray-300">({ref})</span>}
          </h1>
          <div className=" flex items-center ">
            <CreatableSelect
              className=" w-72"
              isClearable
              onChange={handleChange}
              onCreateOption={handleCreateOption}
              options={companyData}
              value={company}
              placeholder="Select or Create Company"
            />

            {/* <select
              value={singleData && singleData?.company_name}
              onChange={(e) => setcompany(e?.target?.value)}
              id="company"
              class="w-52 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5          "
            >
              <option selected>Select Company</option>
              {companyData?.map((item) => (
                <option value={item?.id} key={item?.id}>
                  {item?.name}
                </option>
              ))}
            </select> */}
            {company?.value > 0 && (
              <>
                <CreatableSelect
                  className=" w-72 ml-5"
                  isClearable
                  onChange={(e) => setSiteAddress(e)}
                  onCreateOption={handleCreateSiteAddress}
                  options={selectedCompanyData}
                  value={siteAddress}
                  placeholder="Site address"
                />
                {/* <select
                  onChange={(e) => setSiteAddress(e?.target?.value)}
                  id="company"
                  class="ml-4 w-52 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5          "
                >
                  <option selected>Site Address</option>
                  {selectedCompanyData?.map((item) => (
                    <option value={item?.id} key={item?.id}>
                      {item?.address}
                    </option>
                  ))}
                </select> */}
              </>
            )}

            <div class="w-52 mx-4 relative max-w-sm">
              <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  class="w-5 h-5 text-gray-500  "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>

              <input
                defaultValue={id && singleData?.date}
                onChange={(e) => setDate(e.target.value)}
                type="date"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5          "
                placeholder="Select date"
              />
            </div>
          </div>
        </div>
      </div>

      {/* previous attention field */}

      {/* <div className=" w-full mb-3">
        <label
          for="message"
          class="block mb-2 text-sm font-medium text-gray-900 "
        >
          Attention
        </label>
        <textarea
          onChange={(e) => setAttention(e?.target?.value)}
          value={attention}
          id="message"
          rows="3"
          class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
          placeholder="Write your Attention here..."
        ></textarea>
      </div> */}

      <div className=" grid grid-cols-1 gap-5 md:grid-cols-3 w-full mb-3 mt-4">
        {/* <div className=" mt-2">
          <label for="ref" class="block  text-sm font-medium text-gray-900 ">
            Attention
          </label>
          <input
            type="text"
            onChange={(e) => setAttention(e?.target?.value)}
            value={attention}
            id="message"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            placeholder="Write your attention here..."
          />
        </div> */}

        <div className=" w-full mb-3">
          <label
            for="terms"
            class="block mb-2 text-sm font-medium text-gray-900 "
          >
            Attention
          </label>
          <CreatableSelect
            isClearable
            onChange={handleAttentionChange}
            onCreateOption={handleNewAttentionCreate}
            options={attentionData}
            value={attention}
            placeholder="Select or Create Attention"
          />
        </div>
        <div className=" mt-2">
          <label for="ref" class="block  text-sm font-medium text-gray-900 ">
            Custom DO name
          </label>
          <input
            type="text"
            value={ref}
            onChange={(e) => setSearchParams({ ref: e?.target?.value })}
            id="ref"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            placeholder="Custom DO name"
          />
        </div>
        {/* <div className=" w-full mb-3">
          <label
            for="message"
            class="block mb-2 text-sm font-medium text-gray-900 "
          >
            Delivery Address
          </label>
          <input
            onChange={(e) => setSiteContact(e?.target?.value)}
            value={siteContact}
            type="text"
            class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
            placeholder="Write your delivery address"
          ></input>
        </div> */}

        <div className=" w-full mb-3">
          <label
            for="terms"
            class="block mb-2 text-sm font-medium text-gray-900 "
          >
            Delivery Address
          </label>
          <CreatableSelect
            isClearable
            onChange={handleDeliveryAddressChange}
            onCreateOption={handleNewDeliveryAddressCreate}
            options={siteContactData}
            value={siteContact}
            placeholder="Select or Create Delivery Address"
          />
        </div>
      </div>
      {/* ----------------------------------------------------------------------------------- */}
      <div className=" grid grid-cols-1 gap-5 md:grid-cols-3 w-full mb-3">
        {/* <div className=" mt-2">
          <label
            for="orderBy"
            class="block  text-sm font-medium text-gray-900 "
          >
            Order by
          </label>
          <input
            type="text"
            id="orderBy"
            onChange={(e) => setOrderBy(e?.target?.value)}
            value={orderBy}
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            placeholder="Order by"
          />
        </div> */}

        <div className=" w-full mb-3">
          <label
            for="terms"
            class="block mb-2 text-sm font-medium text-gray-900 "
          >
            Order By
          </label>
          <CreatableSelect
            isClearable
            onChange={handleOrderByChange}
            onCreateOption={handleNewOrderByCreate}
            options={orderByData}
            value={orderBy}
            placeholder="Select or Create Order By"
          />
        </div>
        <div className=" mt-2">
          <label
            for="orderNumber"
            class="block  text-sm font-medium text-gray-900 "
          >
            Order number
          </label>
          <input
            type="text"
            value={orderNumber}
            onChange={(e) => setOrderNumber(e?.target?.value)}
            id="orderNumber"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            placeholder="Order Number"
          />
        </div>
        <div className=" w-full mb-3">
          <label
            for="terms"
            class="block mb-2 text-sm font-medium text-gray-900 "
          >
            Terms
          </label>
          <CreatableSelect
            isClearable
            onChange={handleTermsChange}
            onCreateOption={handleNewTermCreate}
            options={termsData}
            value={terms}
            placeholder="Select or Create Terms"
          />
        </div>
      </div>

      <div className=" flex justify-end items-center ">
        <div
          className={`${
            products?.length > 0 && "border px-3 my-6 rounded-md"
          } flex  items-center`}
        >
          <div className=" flex items-center space-x-3">
            {products?.length > 0 && (
              <div class="flex items-center mb-4 mt-5 w-40">
                <input
                  onChange={() => setIsItemDiscount(!isItemDiscount)}
                  id="isItemDiscount"
                  type="checkbox"
                  checked={isItemDiscount}
                  class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500     focus:ring-2    "
                />
                <label
                  for="isItemDiscount"
                  class="ml-2 text-sm font-medium text-gray-900  "
                >
                  Is Item Discount
                </label>
              </div>
            )}
            {products?.length > 0 && (
              <div class="flex items-center mb-4 mt-5 w-40">
                <input
                  onChange={() => setIsTotalDiscount(!isTotalDiscount)}
                  id="isTotalDiscount"
                  type="checkbox"
                  checked={isTotalDiscount}
                  class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500     focus:ring-2    "
                />
                <label
                  for="isTotalDiscount"
                  class="ml-2 text-sm font-medium text-gray-900  "
                >
                  Is total discount
                </label>
              </div>
            )}
            {products?.length > 0 && (
              <div class="flex items-center mb-4 mt-5 w-40">
                <input
                  onChange={() => setIsFlatDiscount(!isFlatDiscount)}
                  id="isFlatDiscount"
                  type="checkbox"
                  checked={isFlatDiscount}
                  class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500     focus:ring-2    "
                />
                <label
                  for="isFlatDiscount"
                  class="ml-2 text-sm font-medium text-gray-900  "
                >
                  is Flat Discount
                </label>
              </div>
            )}
            {products?.length > 0 && (
              <div class="flex items-center mb-4 mt-5 w-40">
                <input
                  onChange={() => setIsShowGST(!isShowGST)}
                  id="isShowGST"
                  type="checkbox"
                  checked={isShowGST}
                  class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500     focus:ring-2    "
                />
                <label
                  for="isShowGST"
                  class="ml-2 text-sm font-medium text-gray-900  "
                >
                  Is Calculate GST
                </label>
              </div>
            )}
          </div>
          <button
            onClick={() => handleAddProductModal()}
            className="  bg-gray-900 text-white px-4 py-2 rounded-lg mr-[2%] w-40 "
          >
            Add Products
          </button>
        </div>
      </div>
      <div>
        {products?.length > 0 ? (
          <form onSubmit={handleSubmitProduct}>
            <ProductList
              handelChange={handelChange}
              handleDelete={handleDelete}
              selectedProduct={products}
              products={products}
              subtotal={subtotal}
              gstDiscount={gstDiscount}
              grandTotal={grandTotal}
              handleDisCount={handleDisCount}
              isTotalDiscount={isTotalDiscount}
              isItemDiscount={isItemDiscount}
              isFlatDiscount={isFlatDiscount}
              discount={discount}
              setTermsAndConditions={setTermsAndConditions}
              termsAndConditions={termsAndConditions}
              handleEditDucting={handleEditDucting}
              isShowGST={isShowGST}
              handleToggleAllCheck={handleToggleAllCheck}
              activeItem={activeItem}
              setActiveItem={setActiveItem}
              activeItemList={activeItemList}
              setActiveItemList={setActiveItemList}
            />

            <div class="flex mt-2">
              <div class="md:w-2/3">
                <button
                  onClick={() => navigate(-1)}
                  class=" w-24 bg-red-100 text-red-600 shadowfocus:shadow-outline focus:outline-none  font-bold py-2 px-4 rounded"
                  type="button"
                >
                  Back
                </button>
                <button
                  // onClick={handleSubmitProduct}
                  class={`mx-4 w-24 bg-gray-950 text-white shadowfocus:shadow-outline focus:outline-none  font-bold py-2 px-4 rounded`}
                  type="submit"
                >
                  Save
                  {/* {singleData?"Update":"Save"}  */}
                </button>
              </div>
            </div>
          </form>
        ) : (
          <div className=" mt-10">
            <h1 className=" text-gray-300 text-center text-xl my-2">
              There is No Product List
            </h1>
            <h1 className=" text-gray-300 text-center my-2">Add Product</h1>
          </div>
        )}
      </div>
    </>
  );
};

export default AddDO;
