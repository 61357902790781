export function calculateMeasurement(dimensions) {
  if (!dimensions) return 0;

  const { width, height, length } = dimensions;
  const result =
    (((Number(width) + Number(height)) / 1000) * 2 * Number(length)) / 1000;
  return result.toFixed(4);
}

export function calculateArea(dimensions) {
  if (!dimensions) return 0;

  const { width, height } = dimensions;
  const result = (Number(width) * Number(height)) / 1000000;
  return result.toFixed(4);
}
