import React, { useEffect, useState } from "react";
import { BsPlusCircleDotted } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import Alert from "../Shared/Alert";
import axios from "axios";
import Swal from "sweetalert2";
import { useHeader } from "../../lib/headers";

const UploadImages = ({
  imageURL,
  varientFeatureImage,
  isVariantLoading,
  isVariantFeatureImageLoading,
  handleVarientFileDelete,
  handleVarientFeatureImageUpload,
  handleVarientImageUpload,
  handleFeatureFileDelete,
  setIsReload,
  isReload,
  products,
  selectedItem,
}) => {
  return (
    <div className="">
      <div class="flex items-center flex-wrap ">
        {varientFeatureImage ? (
          <div class="  w-1/4 h-40 p-1 md:p-2 relative">
            <img
              alt="gallery"
              class="block object-cover object-center w-full h-full rounded-lg"
              src={varientFeatureImage?.image}
            />
            <button
              onClick={(e) => handleFeatureFileDelete(e, varientFeatureImage)}
              className=" hover:bg-white bg-red-400 hover:text-red-600 text-white absolute top-2 right-2 rounded-full  "
            >
              <RxCross2 size={30} className=" p-2" />
            </button>
          </div>
        ) : (
          <div class="flex items-center justify-center h-40  w-1/4">
            <label
              for="dropzone-file"
              class="flex flex-col items-center justify-center border-2 border-gray-300  rounded-lg cursor-pointer bg-gray-50 dark::hover:bg-bray-800 dark::bg-gray-700 hover:bg-gray-100 dark::border-gray-600 dark::hover:border-gray-500 dark::hover:bg-gray-600"
            >
              {isVariantFeatureImageLoading ? (
                <h1>Uploading ...</h1>
              ) : (
                <>
                  <div class="flex flex-col items-center justify-center pt-5 pb-6 px-2">
                    <svg
                      class="w-8 h-8 mb-4 text-gray-500 dark::text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    <p class="mb-2 text-sm text-gray-500 dark::text-gray-400">
                      <span class="font-semibold">Upload </span> Feature image
                    </p>
                    <p class="text-xs text-gray-500 dark::text-gray-400">
                      {" "}
                      PNG or JPG{" "}
                    </p>
                  </div>
                  <input
                    onChange={(e) => handleVarientFeatureImageUpload(e)}
                    id="dropzone-file"
                    type="file"
                    class="hidden"
                  />
                </>
              )}
            </label>
          </div>
        )}

        {imageURL?.map((item, index) => (
          <div class=" w-1/4 h-40 p-1 md:p-2 relative">
            <img
              alt="gallery"
              class="block object-cover object-center w-full h-full rounded-lg"
              src={item?.image}
            />
            <button
              onClick={(e) => handleVarientFileDelete(e, item, index)}
              className=" hover:bg-white bg-red-400 hover:text-red-600 text-white absolute top-2 right-2 rounded-full  "
            >
              <RxCross2 size={30} className=" p-2" />
            </button>
          </div>
        ))}

        <div class="flex items-center justify-center w-1/4 h-40">
          <label
            for="dropzone-file"
            class="flex flex-col items-center justify-center border-2 border-red-300  rounded-lg cursor-pointer bg-gray-50   "
          >
            {isVariantLoading ? (
              <h1>Uploading Varient ...</h1>
            ) : (
              <>
                <div class="flex flex-col items-center justify-center pt-5 pb-6 px-2">
                  <BsPlusCircleDotted size={30} />
                  <p class="mb-2 text-sm text-gray-500 dark::text-gray-400 mt-2">
                    <span class="font-semibold">Upload </span> Variant image..
                  </p>
                  <p class="text-xs text-gray-500 dark::text-gray-400">
                    {" "}
                    PNG or JPG{" "}
                  </p>
                </div>
                <input
                  onChange={(e) => handleVarientImageUpload(e)}
                  id="dropzone-file"
                  type="file"
                  class="hidden"
                />
              </>
            )}
          </label>
        </div>
      </div>
    </div>
  );
};

export default UploadImages;
