import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import Alert from "../Shared/Alert";
import ReactToPrint from "react-to-print";
const OrderDetail = () => {
  const [quotaionData, setquotaionData] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const componentRef = useRef();
  //setting token
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/api/order/${id}`,
        { headers }
      );
      setquotaionData(response?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const calculateTotalPrice = (item) => {
    const totalPrice = item.sell_price * item.new_quantity;
    return totalPrice;
  };
  const subtotal = quotaionData?.product?.reduce(
    (sum, item) => sum + calculateTotalPrice(item),
    0
  );

  return (
    <div>
      <div className=" flex items-center justify-between">
        <button
          onClick={() => navigate(-1)}
          className=" border rounded p-2  flex  items-center hover:bg-black hover:text-white "
        >
          <IoIosArrowBack />
          <span className=" ml-2"> Back</span>
        </button>
        <ReactToPrint
          trigger={() => (
            <button className=" px-10 text-center  border rounded p-2  flex  items-center hover:bg-black hover:text-white ">
              Print
            </button>
          )}
          content={() => componentRef.current}
        />
      </div>

      <div ref={componentRef} class=" mx-auto max-w-7xl px-2 lg:px-0">
        <div class="mx-auto max-w-2xl py-8 lg:max-w-7xl px-2">
          <h1 class=" text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {quotaionData?.ref}
          </h1>
          <div className=" flex justify-between items-center">
            <h2
              id="summary-heading"
              class=" px-4 py-3 text-xl  text-gray-900 sm:p-4"
            >
              {quotaionData?.company_name && (
                <>
                  <span className=" font-medium"> Company:</span>{" "}
                  <span>{quotaionData?.company_name} </span>
                </>
              )}
            </h2>
            <div className=" ">
              <div class=" space-y-1 px-2 py-4">
                <div class="flex items-center justify-between">
                  <dt class="text-sm font-medium text-gray-800">Date:</dt>
                  <dd class=" ml-2 text-sm ">{quotaionData?.date}</dd>
                </div>
                <div class="flex items-center justify-between mt-2">
                  <dt class="text-sm font-medium text-gray-800">Status: </dt>
                  <dd class=" ml-2 text-sm  ">{quotaionData?.status}</dd>
                </div>
                {/* <div class="flex items-center justify-between  py-2 ">
                  <dt class="text-base font-medium text-gray-900">
                    Total Profit
                  </dt>
                  <dd class="text-base font-medium text-gray-900">
                    $ {quotaionData?.total_profit}
                  </dd>
                </div> */}
              </div>
            </div>
          </div>
          <div class=" mt-2  w-full">
            <section class="rounded-lg col-span-12 bg-white lg:col-span-8 w-full">
              <table class="w-full text-sm text-left text-gray-500 dark::text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark::bg-gray-700 dark::text-gray-400">
                  <tr>
                    <th scope="col" class="px-6 py-3">
                      Product name
                    </th>
                    {/* <th scope="col" class="px-6 py-3">
                Status
              </th> */}
                    <th scope="col" class="px-6 py-3">
                      Unit Price
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Quantity
                    </th>
                    {/* <th scope="col" class="px-6 py-3">
                      Item Profit
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {quotaionData?.product?.map((item) => (
                    <tr
                      className="bg-white border-b dark::bg-gray-800 dark::border-gray-700"
                      key={item.id}
                    >
                      <th
                        scope="row"
                        className="px-6 py-1 font-medium text-gray-900 whitespace-nowrap dark::text-white"
                      >
                        <p>{item?.name}</p>
                        <div>
                          {item?.attributes?.length > 0 &&
                            item.attributes.map((i) => (
                              <div
                                className="text-gray-300 text-xs flex"
                                key={i.attribute}
                              >
                                <span>{i.attribute}</span> :
                                <span>{i.value}</span>
                              </div>
                            ))}
                        </div>
                      </th>
                      {/* <td className="px-6 py-4">{item?.status_display}</td> */}
                      <td className="px-6 py-4">${item?.sell_price}</td>
                      <td className="px-6 py-4">
                        {item?.new_quantity} {item?.unit}
                      </td>
                      {/* <td className="px-6 py-4">${item?.total_profit}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* 
              {quotaionData?.product?.map((item) => (
                <ul role="list" class="divide-y divide-gray-200">
                  <li class="flex py-6 sm:py-6 ">
                    <div class="flex-shrink-0">
                      <img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiD4JxPlnuK37fhXnSCmNVHt-1TdKEiRudJijQCtjKpg&s"
                        alt="Nike Air Force 1 07 LV8"
                        class="sm:h-38 sm:w-38 h-24 w-24 rounded-md object-contain object-center"
                      />
                    </div>
                    <div class="ml-4 flex flex-1 flex-col justify-between sm:ml-6">
                      <div class="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
                        <div>
                          <div class="flex justify-between">
                            <h3 class="text-sm">
                              <a href="#" class="font-semibold text-black">
                                {item?.name}
                              </a>
                            </h3>
                          </div>
                          {item?.attributes?.map((i) => (
                            <div class="mt-1 flex text-sm">
                              <p class="text-sm text-gray-500">
                                {i?.attribute}
                              </p>
                              <p class="ml-4 border-l border-gray-200 pl-4 text-sm text-gray-500">
                                {i?.value}
                              </p>
                            </div>
                          ))}

                          <div class="mt-1 flex items-end">
                            <p class="text-sm font-medium text-gray-900">
                              Unit Price: ${item?.sell_price}
                            </p>
                            <p class="ml-4 text-sm font-medium text-gray-900">
                              Quantity: {item?.new_quantity} {item?.unit}
                            </p>
                          </div>
                          <p class=" text-sm font-medium text-gray-900">
                            Item Profit: ${item?.total_profit}
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                  <div class="mb-2 flex"></div>
                </ul>
              ))} */}
            </section>
            <section
              aria-labelledby="summary-heading"
              class="mt-5 rounded-md bg-white flex justify-end  mr-5"
            >
              <div>
                <div>
                  <div>
                    <dl class=" space-y-1 px-2 py-4">
                      <div class="flex items-center justify-between">
                        <dt class="text-sm text-gray-800">
                          Price ({quotaionData?.product?.length} item)
                        </dt>
                        <dd class="text-sm font-medium text-gray-900">
                          $ {quotaionData?.sub_total}
                        </dd>
                      </div>
                      <div class="flex items-center justify-between pt-4">
                        <dt class="flex items-center text-sm text-gray-800">
                          <span>Discount</span>
                        </dt>
                        <dd class="text-sm font-medium text-green-700">
                          $ {quotaionData?.discount}
                        </dd>
                      </div>
                      <div class="flex items-center justify-between py-4">
                        <dt class="flex text-sm text-gray-800">
                          <span>GST</span>
                        </dt>
                        <dd class="text-sm font-medium text-green-700">
                          $ {quotaionData?.gst?.toFixed(2)}
                        </dd>
                      </div>
                      <div class="flex items-center justify-between border-y border-dashed py-4 ">
                        <dt class="text-base font-medium text-gray-900">
                          Total Amount
                        </dt>
                        <dd class="ml-2 text-base font-medium text-gray-900">
                          $ {quotaionData?.grand_total}
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
