import React, { useEffect, useState } from "react";
import SidebarCart from "./SidebarCart";
import ProductCard from "./ProductCard";
import { useHeader } from "../../lib/headers";
import axios from "axios";
import useKeypress from "react-use-keypress";
import Modal from "../Shared/Modal";
import POSModalData from "./POSModalData";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCartItem,
  handleCurrentItem,
} from "../../Redux/API_Slices/POSSlice";
import Modal3 from "../Shared/Modal3";
import { useNavigate } from "react-router-dom";
import Alert from "../Shared/Alert";

const POS = () => {
  const [products, setProducts] = useState([]);
  const headers = useHeader();
  // const [cartItems, setCartItems] = useState([]);
  // const [currentItem, setCurrentItem] = useState({});
  const [searchText, setsearchText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const currentItem = useSelector((state) => state.posSlice.currentItem);
  const cartItems = useSelector((state) => state.posSlice.orderItems);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetchCategoryData = async () => {
    try {
      const response = await axios
        .get(`${process.env.REACT_APP_ROOT_URL}/api/category/`, headers)
        .then((res) => {
          setCategory(res?.data?.results);
        })
        .catch((err) => {});
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchCategoryData();
  }, []);

  const handleAddToCart = (item) => {
    if (item.quantity > 0) {
      if (!cartItems.some((cartItem) => cartItem.id === item.id)) {
        const newData = { ...item }; // Create a new object with the properties of 'item'
        newData.new_quantity = 1;
        dispatch(handleCurrentItem(newData));
        dispatch(addToCartItem([...cartItems, item]));
      }
    } else {
      Alert("product Not available now", "error");
    }
  };

  const handleRemoveFromCart = (item) => {
    const data = [...cartItems];
    const rest = data?.filter((pro) => pro.id !== item.id);

    dispatch(addToCartItem(rest));
  };

  const fetchData = () => {
    axios
      .get(
        `${process.env.REACT_APP_ROOT_URL}/api/product/all/?search=${searchText}&category=${selectedCategory}`,
        headers
      )
      .then((response) => {
        // Handle the response data

        const data = response.data?.results;
        setProducts(data);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    fetchData();
  }, [searchText, selectedCategory]);

  const handleNumberClick = (number) => {
    if (currentItem) {
      const newData = [...cartItems];
      const res = newData.find((item) => item?.id === currentItem.id);
      const index = newData.findIndex((item) => item?.id === currentItem.id);
      const data = { ...newData[index] };
      //  const data = newData[index];
      data.new_quantity = data.new_quantity
        ? String(data.new_quantity + number)
        : String(number);
      newData[index] = data;
      dispatch(addToCartItem(newData));
    }
  };

  const handleBackSpace = () => {
    if (currentItem) {
      if (currentItem?.new_quantity > 0) {
        const newData = [...cartItems];
        const res = newData.find((item) => item?.id === currentItem.id);
        const index = newData.findIndex((item) => item?.id === currentItem.id);
        const data = { ...newData[index] };
        // const data = newData[index];
        data.new_quantity = 0;
        newData[index] = data;
        dispatch(addToCartItem(newData));
      } else {
        const newData = [...cartItems];
        const res = newData.filter((item) => item?.id !== currentItem.id);
        dispatch(addToCartItem(res));
      }
    }
  };
  const handleEnter = () => {
    navigate("/pos/order");
    // setShowModal(true);
  };

  useKeypress("Backspace", handleBackSpace);

  useKeypress("Escape", () => {
    if (currentItem) {
      if (currentItem?.new_quantity > 0) {
        const newData = [...cartItems];
        const res = newData.find((item) => item?.id === currentItem.id);
        const index = newData.findIndex((item) => item?.id === currentItem.id);
        const data = { ...newData[index] };
        data.new_quantity = 0;
        newData[index] = data;
        dispatch(addToCartItem(newData));
      } else {
        const newData = [...cartItems];
        const res = newData.filter((item) => item?.id !== currentItem.id);
        dispatch(addToCartItem(res));
      }
    }
  });

  useKeypress("Enter", () => handleEnter(true));

  // Move useKeypress to the top level of the component
  useKeypress("1", () => handleNumberClick(1));
  useKeypress("2", () => handleNumberClick(2));
  useKeypress("3", () => handleNumberClick(3));
  useKeypress("4", () => handleNumberClick(4));
  useKeypress("5", () => handleNumberClick(5));
  useKeypress("6", () => handleNumberClick(6));
  useKeypress("7", () => handleNumberClick(7));
  useKeypress("8", () => handleNumberClick(8));
  useKeypress("9", () => handleNumberClick(9));
  useKeypress("0", () => handleNumberClick(0));
  return (
    <div>
      {showModal && (
        <Modal3
          title="Point oF Sell"
          showModal={showModal}
          setShowModal={setShowModal}
        >
          <POSModalData></POSModalData>
        </Modal3>
      )}
      <div className=" grid grid-cols-12 gap-4">
        {/* <Navbar /> */}
        <div className={` md:col-span-3 `}>
          <SidebarCart
            handleBackSpace={handleBackSpace}
            handleEnter={handleEnter}
            handleRemoveFromCart={handleRemoveFromCart}
            cartItems={cartItems}
            currentItem={currentItem}
            handleNumberClick={handleNumberClick}
            // setCurrentItem={setCurrentItem}
          />
        </div>
        <div className={` md:col-span-9 col-span-1 mr-2`}>
          {/* search  */}
          <form className=" mt-2 mr-2">
            <label
              for="default-search"
              class="text-sm font-medium text-gray-900 sr-only "
            >
              Search
            </label>
            <div class="relative">
              <div class="absolute inset-y-0 left-0 flex items-center px-3 pointer-events-none">
                <svg
                  class="w-4 h-4 text-gray-500 dark::text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                onChange={(e) => setsearchText(e?.target?.value)}
                type="search"
                id="default-search"
                class="block w-full p-3 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500   "
                placeholder="Search Products..."
                required
              />
            </div>
          </form>
          {/* tag  */}
          <div className=" flex  flex-wrap mt-4">
            {category.map((item) => (
              <button
                onClick={() => setSelectedCategory(item?.id)}
                className={` ${
                  selectedCategory === item?.id
                    ? "bg-black text-white"
                    : "bg-[#E5E5E5] hover:bg-black hover:text-white"
                }  px-2 py-2  mx-2 my-1 rounded-md text-xs`}
              >
                {item?.name}
              </button>
            ))}
          </div>
          <div
            class={`mx-auto flex flex-wrap  justify-center w-full max-w-7xl items-center space-y-4 px-2 py-5  md:gap-6 md:space-y-0  `}
          >
            {products?.map((item) => (
              <ProductCard handleAddToCart={handleAddToCart} item={item} />
            ))}
            {products?.length === 0 && (
              <h1 className=" text-gray-400 text-center my-10">
                No Data Found{" "}
              </h1>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default POS;
