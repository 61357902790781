import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CiBoxList } from "react-icons/ci";
import axios from "axios";

const AddProjects = () => {
  const navigate = useNavigate();
  const [companyData, setCompanyData] = useState([]);
  const [formData, setFormData] = useState({
    contact_person: "",
    name: "",
    phone: "",
    email: "",
    address: "",
    company: "",
  });

  // set the toke from local storage
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  // handle change function
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // post function
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ROOT_URL}/api/project/`,
        formData,
        { headers }
      );
      navigate("/projects");
    } catch (error) {
      console.error(error);
    }
  };

  // Function to fetch data from the API
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/api/company/`,
        { headers }
      );
      setCompanyData(response?.data?.results);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className=" flex justify-center items-center h-screen my-10 md:my-0">
      <form class="w-full " onSubmit={handleSubmit}>
        <div className=" flex justify-between mb-10 max-w-2xl mx-auto">
          <h1 class=" text-3xl  text-gray-500 font-bold ">Add Project</h1>

          <Link to="/projects">
            <CiBoxList size={30} />
          </Link>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label
              class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              for="inline-full-name"
            >
              Project Name
            </label>
          </div>
          <div class="md:w-1/2">
            <input
              name="name"
              onChange={handleChange}
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-500"
              id="inline-full-name"
              type="text"
              placeholder=" Enter Project Name"
            />
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label
              class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              for="inline-full-name"
            >
              Company Name
            </label>
          </div>
          <div class="md:w-1/2">
            <select
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-500"
              id="company"
              name="company"
              onChange={handleChange}
            >
              {companyData?.map((item) => (
                <option key={item?.id} name="company" value={item?.id}>
                  {item?.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label
              class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              for="inline-password"
            >
              Contact Person Name
            </label>
          </div>
          <div class=" md:w-1/2 ">
            <input
              onChange={handleChange}
              name="contact_person"
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="inline-password"
              type="text"
              placeholder="Enter Owner Name"
            />
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label
              class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              for="phone"
            >
              Phone
            </label>
          </div>
          <div class=" md:w-1/2 ">
            <input
              name="phone"
              onChange={handleChange}
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="phone"
              type="text"
              placeholder="EnterYour Phone Number"
            />
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label
              class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              for="Email"
            >
              Email
            </label>
          </div>
          <div class=" md:w-1/2 ">
            <input
              name="email"
              onChange={handleChange}
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="Email"
              type="text"
              placeholder="Enter Your Email"
            />
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label
              class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              for="Address"
            >
              Address
            </label>
          </div>
          <div class=" md:w-1/2 ">
            <input
              name="address"
              onChange={handleChange}
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="Address"
              type="text"
              placeholder="Enter Your Address"
            />
          </div>
        </div>
        <div class="md:flex md:items-center">
          <div class="md:w-1/3"></div>
          <div class="md:w-2/3">
            <button
              onClick={() => navigate(-1)}
              class=" w-24 bg-red-100 text-red-600 shadowfocus:shadow-outline focus:outline-none  font-bold py-2 px-4 rounded"
              type="button"
            >
              Back
            </button>
            <button
              class="mx-4 w-24 bg-gray-950 text-white shadowfocus:shadow-outline focus:outline-none  font-bold py-2 px-4 rounded"
              type="submit"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddProjects;
