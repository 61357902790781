import React, { useState } from "react";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  ResponsiveContainer,
  CartesianGrid,
  PieChart,
  Pie,
  Cell,
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";

const Home = () => {
  const data = [
    { name: "Jan", value: 240, value2: 123 },
    { name: "Feb", value: 320, value2: 923 },
    { name: "Mar", value: 200, value2: 133 },
    { name: "Apr", value: 480, value2: 523 },
    { name: "May", value: 180, value2: 923 },
    { name: "Jun", value: 280, value2: 723 },
  ];
  const pieData = [
    { name: "A", value: 300 },
    { name: "B", value: 400 },
    { name: "C", value: 200 },
  ];

  const COLORS = ["#3182CE", "#63B3ED", "#93C5FD"];
  const scatterData = [
    { x: 10, y: 200 },
    { x: 20, y: 300 },
    { x: 30, y: 150 },
    { x: 40, y: 450 },
    { x: 50, y: 250 },
    { x: 60, y: 350 },
  ];
  const [showPopup, setShowPopup] = useState(false);

  const popupHandler = (isOpen) => {
    setShowPopup(isOpen);
  };
  return (
    <div>
      {/* <div className="min-h-screen bg-gray-100">
        {showPopup && (
          <div className="fixed inset-0 flex items-center justify-center z-10">
            <div className="bg-white rounded-lg shadow-lg p-6">
              <h2 className="text-2xl font-semibold mb-4">Create a Task</h2>
          
              <form>
                <div className="mb-4">
                  <label
                    htmlFor="taskName"
                    className="block text-gray-800 font-medium mb-2"
                  >
                    Task Name
                  </label>
                  <input
                    type="text"
                    id="taskName"
                    className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="dueDate"
                    className="block text-gray-800 font-medium mb-2"
                  >
                    Due Date
                  </label>
                  <input
                    type="date"
                    id="dueDate"
                    className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="inline-flex items-center justify-center px-4 py-2 bg-indigo-600 border border-transparent rounded-md font-medium text-white hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Create
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

    
        <div className="mt-6 bg-white px-4 md:px-8 py-4 md:py-7 xl:px-10">
          <div className="space-y-4">
            <div className="flex items-start justify-between">
              <div className="flex items-center space-x-4">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="form-checkbox h-4 w-4 text-indigo-600 focus:ring-indigo-500"
                  />
                  <p className="ml-2 text-gray-800">Task 1</p>
                </div>
                <p className="text-gray-500">Due on June 30, 2023</p>
              </div>

              <button className="focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 inline-flex items-center justify-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-md text-indigo-600 bg-indigo-100 hover:bg-indigo-200">
                Edit
              </button>
            </div>
            <div className="flex items-start justify-between">
              <div className="flex items-center space-x-4">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="form-checkbox h-4 w-4 text-indigo-600 focus:ring-indigo-500"
                  />
                  <p className="ml-2 text-gray-800">Task 2</p>
                </div>
                <p className="text-gray-500">Due on July 15, 2023</p>
              </div>
              <button className="focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 inline-flex items-center justify-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-md text-indigo-600 bg-indigo-100 hover:bg-indigo-200">
                Edit
              </button>
            </div>
          </div>
        </div>

     
        <div className="fixed bottom-8 right-8">
          <button
            className="fixed z-20 bottom-8 right-8 bg-indigo-500 text-white rounded-full p-4 shadow-lg hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            onClick={() => popupHandler(true)}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 4v16m8-8H4"
              />
            </svg>
          </button>
        </div>
      </div> */}

      <h1 className="text-2xl font-semibold">Dashboard</h1>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className=" p-4">
          <h2 className="text-lg font-semibold">Bar Chart</h2>
          <ResponsiveContainer width="100%" height={200}>
            <BarChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <Bar dataKey="value" fill="#3182CE" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="w-full  p-4">
          <h2 className="text-lg font-semibold">Line Chart</h2>
          <ResponsiveContainer width="100%" height={200}>
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <Line type="monotone" dataKey="value" stroke="#3182CE" />
              <Line
                type="monotone"
                dataKey="value2"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className="w-full  p-4">
          <h2 className="text-lg font-semibold">Pie Chart</h2>
          <ResponsiveContainer width="100%" height={200}>
            <PieChart>
              <Pie
                data={pieData}
                dataKey="value"
                outerRadius={80}
                fill="#8884d8"
                label={(entry) => entry.name}
              >
                {pieData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <CartesianGrid strokeDasharray="3 3" />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="w-full p-4">
          <h2 className="text-lg font-semibold">Scatter Chart</h2>
          <ResponsiveContainer width="100%" height={200}>
            <ScatterChart>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="x" type="number" name="X-axis" />
              <YAxis dataKey="y" type="number" name="Y-axis" />
              <Tooltip cursor={{ strokeDasharray: "3 3" }} />
              <Scatter data={scatterData} fill="#8884d8" />
            </ScatterChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* <div className="sm:px-6 w-full">
        <div className="px-4 md:px-10 py-4 md:py-7">
          <div className="flex items-center justify-between">
            <p
              tabIndex="0"
              className="focus:outline-none text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800"
            >
              Tasks
            </p>
            <div className="py-3 px-4 flex items-center text-sm font-medium leading-none text-gray-600 bg-gray-200 hover:bg-gray-300 cursor-pointer rounded">
              <p>Sort By:</p>
              <select
                aria-label="select"
                className="focus:text-indigo-600 focus:outline-none bg-transparent ml-1"
              >
                <option className="text-sm text-indigo-800">Latest</option>
                <option className="text-sm text-indigo-800">Oldest</option>
                <option className="text-sm text-indigo-800">Latest</option>
              </select>
            </div>
          </div>
        </div>
        <div className="bg-white py-4 md:py-7 px-4 md:px-8 xl:px-10">
          <div className="sm:flex items-center justify-between">
            <div className="flex items-center">
              <a
                className="rounded-full focus:outline-none focus:ring-2 focus:bg-indigo-50 focus:ring-indigo-800"
                href="javascript:void(0)"
              >
                <div className="py-2 px-8 bg-indigo-100 text-indigo-700 rounded-full">
                  <p>All</p>
                </div>
              </a>
              <a
                className="rounded-full focus:outline-none focus:ring-2 focus:bg-indigo-50 focus:ring-indigo-800 ml-4 sm:ml-8"
                href="javascript:void(0)"
              >
                <div className="py-2 px-8 text-gray-600 hover:text-indigo-700 hover:bg-indigo-100 rounded-full">
                  <p>Done</p>
                </div>
              </a>
              <a
                className="rounded-full focus:outline-none focus:ring-2 focus:bg-indigo-50 focus:ring-indigo-800 ml-4 sm:ml-8"
                href="javascript:void(0)"
              >
                <div className="py-2 px-8 text-gray-600 hover:text-indigo-700 hover:bg-indigo-100 rounded-full">
                  <p>Pending</p>
                </div>
              </a>
            </div>{" "}
            <button
              onClick={() => popupHandler(true)}
              className="focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 mt-4 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-indigo-700 hover:bg-indigo-600 focus:outline-none rounded-full text-white text-sm sm:text-base font-medium"
            >
              <svg
                className="w-4 h-4 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                ></path>
              </svg>
              <p>Create Task</p>
            </button>
          </div>
        </div>
      </div>
      <div className="mt-6 bg-white px-4 md:px-8 py-4 md:py-7 xl:px-10">
      
        <div className="space-y-4">
          <div className="flex items-start justify-between">
            <div className="flex items-center space-x-4">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-indigo-600 focus:ring-indigo-500"
                />
                <p className="ml-2 text-gray-800">Task 1</p>
              </div>
              <p className="text-gray-500">Due on June 30, 2023</p>
            </div>
            <button className="focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 inline-flex items-center justify-center p-1 rounded-full text-indigo-600 hover:bg-indigo-100 hover:text-indigo-700">
              <svg
                className="w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0zM8 9a5 5 0 014.472 2.75l1.452-1.452A2.982 2.982 0 0012 7a3 3 0 00-3 3c0 .78.296 1.488.776 2.024L6.25 14.528A4.984 4.984 0 013 12a5 5 0 015-5z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        <div className="space-y-4">
          <div className="flex items-start justify-between">
            <div className="flex items-center space-x-4">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-indigo-600 focus:ring-indigo-500"
                />
                <p className="ml-2 text-gray-800">Task 1</p>
              </div>
              <p className="text-gray-500">Due on June 30, 2023</p>
            </div>
            <button className="focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 inline-flex items-center justify-center p-1 rounded-full text-indigo-600 hover:bg-indigo-100 hover:text-indigo-700">
              <svg
                className="w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0zM8 9a5 5 0 014.472 2.75l1.452-1.452A2.982 2.982 0 0012 7a3 3 0 00-3 3c0 .78.296 1.488.776 2.024L6.25 14.528A4.984 4.984 0 013 12a5 5 0 015-5z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        <div className="space-y-4">
          <div className="flex items-start justify-between">
            <div className="flex items-center space-x-4">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-indigo-600 focus:ring-indigo-500"
                />
                <p className="ml-2 text-gray-800">Task 1</p>
              </div>
              <p className="text-gray-500">Due on June 30, 2023</p>
            </div>
            <button className="focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 inline-flex items-center justify-center p-1 rounded-full text-indigo-600 hover:bg-indigo-100 hover:text-indigo-700">
              <svg
                className="w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0zM8 9a5 5 0 014.472 2.75l1.452-1.452A2.982 2.982 0 0012 7a3 3 0 00-3 3c0 .78.296 1.488.776 2.024L6.25 14.528A4.984 4.984 0 013 12a5 5 0 015-5z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        <div className="space-y-4">
          <div className="flex items-start justify-between">
            <div className="flex items-center space-x-4">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-indigo-600 focus:ring-indigo-500"
                />
                <p className="ml-2 text-gray-800">Task 1</p>
              </div>
              <p className="text-gray-500">Due on June 30, 2023</p>
            </div>
            <button className="focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 inline-flex items-center justify-center p-1 rounded-full text-indigo-600 hover:bg-indigo-100 hover:text-indigo-700">
              <svg
                className="w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0zM8 9a5 5 0 014.472 2.75l1.452-1.452A2.982 2.982 0 0012 7a3 3 0 00-3 3c0 .78.296 1.488.776 2.024L6.25 14.528A4.984 4.984 0 013 12a5 5 0 015-5z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Home;
