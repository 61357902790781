import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import POSInvoiceTable from "./POSInvoiceTable";
import { useHeader } from "../../lib/headers";
import axios from "axios";
import ReactToPrint from "react-to-print";

const POSInvoice = () => {
  const navigate = useNavigate();
  const [singleData, setSingleData] = useState({});
  const [invoice, setInvoice] = useState([]);
  const headers = useHeader();
  const { id } = useParams();
  const componentRef = useRef();
  const fetchData = async () => {
    try {
      const response = await axios
        .get(
          `${process.env.REACT_APP_ROOT_URL}/api/invoice/?source=${id}`,
          headers
        )
        .then((response) => {
          const data = response?.data?.results[0];
          if (data) {
            setInvoice(data?.product);
            setSingleData(data);
          }
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    // setTimeout(() => {
    //   fetchData();
    // }, 2000);
    fetchData();
  }, [id]);
  return (
    <div>
      <div
        id="defaultModal"
        tabIndex="-1"
        aria-hidden="true"
        className="fixed h-screen top-0 left-0 right-0 z-50 flex items-center justify-center w-full p-4 overflow-x-hidden overflow-y-auto bg-black bg-opacity-75"
      >
        <div className="relative w-full max-w-7xl max-h-screen h-[95%] over">
          {/* Modal content */}
          <div className="relative bg-white rounded-lg shadow h-full">
            {/* Modal header */}
            <div className="flex items-start justify-between p-4 border-b rounded-t">
              <h3 className="text-xl font-semibold text-gray-900">Invoice</h3>
              <button
                onClick={() => navigate("/pos")}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                data-modal-hide="defaultModal"
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  ></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* Modal body */}
            {invoice ? (
              <div className="p-6 space-y-6 h-screen overflow-y-scroll  ">
                <div className=" flex justify-start items-center">
                  <button className=" my-2 bg-gray-900 text-white px-4 py-2 rounded-lg mr-[2%] ">
                    <Link to={`/pos`}>Back</Link>
                  </button>
                  <ReactToPrint
                    trigger={() => (
                      <button className=" my-2 bg-gray-900 text-white px-4 py-2 rounded-lg mr-[2%] ">
                        Print
                      </button>
                    )}
                    content={() => componentRef.current}
                  />
                </div>

                {invoice && (
                  <POSInvoiceTable
                    invoice={invoice}
                    singleData={singleData}
                    ref={componentRef}
                  />
                )}
              </div>
            ) : (
              <div>
                <h1 className=" text-center text-xl text-gray-300">
                  Generating Invoice..
                </h1>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default POSInvoice;
