import axios from "axios";
import React, { useEffect, useState } from "react";

import CreatableSelect from "react-select/creatable";
import { useHeader } from "../../lib/headers";

const createOption = (label, id) => ({
  id,
  label,
  value: label,
  // value: label.toLowerCase().replace(/\W/g, ""),
});
const createOption2 = (label, id, attributeId) => ({
  id,
  label,
  value: label,
  attributeId: attributeId,
});

const SelectInput = ({
  value,
  setValue,
  isReload,
  setIsReload,
  attributes,
  setData,
  index,
  item,

  data,
  handlePluse,
  handleMinus,
  selectedCategory,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [attributesValue, setAttributesValue] = useState([]);
  const [options, setOptions] = useState([]);
  const [options2, setOptions2] = useState([]);
  const [currentData, setCurrentData] = useState({});
  const headers = useHeader();

  useEffect(() => {
    setOptions([
      ...attributes.map((attribute) =>
        createOption(attribute.name, attribute?.id)
      ),
    ]);
  }, [attributes]);

  const handleChange = (e, index, name, isSelect) => {
    if (isSelect && e) {
      setCurrentData(e);
      setValue(e);
      const { label, value, id } = e;
      loadAttribute(id);

      setData((prevData) => {
        const newData = [...prevData];
        newData[index][name] = value;
        return newData;
      });
    } else if (isSelect && !e) {
      setCurrentData({});
      setValue(null);

      setData((prevData) => {
        const newData = [...prevData];
        newData[index][name] = null;
        return newData;
      });
    } else if (!isSelect) {
      const { name, value } = e.target;
      setData((prevData) => {
        const newData = [...prevData];
        newData[index][name] = value;
        return newData;
      });
    }
  };
  const handleChecked = (e, index) => {
    const { name, checked } = e.target;

    setData((prevData) => {
      const newData = [...prevData];
      newData[index][name] = checked;
      return newData;
    });
  };
  const loadAttribute = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_ROOT_URL}/api/attribute_value/?attribute=${id}`,
        headers
      )
      .then((response) => {
        // Handle the response data
        const data = response?.data?.results;

        setOptions2([
          ...data.map((attribute) =>
            createOption2(attribute.value, attribute?.id, attribute?.attribute)
          ),
        ]);
        setAttributesValue(response?.data?.results);
      })
      .catch((error) => {
        // Handle any errors
        console.error(error);
      });
  };
  const fetchAttributes = async (value) => {
    const data = {
      name: value,
      // category: parseInt(selectedCategory?.value),
    };
    try {
      await axios
        .post(`${process.env.REACT_APP_ROOT_URL}/api/attribute/`, data, headers)
        .then((response) => {
          const data = response?.data;
          const newData = {
            id: data?.id,
            label: data.name,
            value: data.name,
          };
          setCurrentData(newData);

          // setIsReload(isReload + 1);
        });
      // setAttributes(response.data?.results);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchAttributesValue = async (value) => {
    const data = {
      attribute: currentData?.id,
      value,
    };
    try {
      await axios
        .post(
          `${process.env.REACT_APP_ROOT_URL}/api/attribute_value/`,
          data,
          headers
        )
        .then((response) => {
          setCurrentData({});
          setIsReload(isReload + 1);
        });
      // setAttributes(response.data?.results);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreate = (inputValue, index) => {
    setIsLoading(true);

    setData((prevData) => {
      const newData = [...prevData];
      newData[index]["attribute"] = inputValue;
      return newData;
    });

    fetchAttributes(inputValue);

    setTimeout(() => {
      const newOption = createOption(inputValue);
      setIsLoading(false);
      setOptions((prev) => [...prev, newOption]);
      setValue(newOption);
    }, 1000);
  };
  const handleCreate2 = (inputValue, index) => {
    setIsLoading2(true);

    setData((prevData) => {
      const newData = [...prevData];
      newData[index]["value"] = inputValue;
      return newData;
    });
    if (currentData?.id) {
      fetchAttributesValue(inputValue);
    }

    setTimeout(() => {
      const newOption = createOption2(inputValue);
      setIsLoading2(false);
      setOptions2((prev) => [...prev, newOption]);
      // setValue(newOption);
    }, 1000);
  };

  return (
    <div className="grid grid-cols-12 gap-4 my-2 place-content-center place-items-center">
      <div className="col-span-4 w-full">
        <label
          htmlFor={`attribute_${index}`}
          className="block mb-2 text-sm font-medium text-gray-900 dark::text-white"
        >
          Attribute
        </label>
        <CreatableSelect
          required={true}
          isClearable={false}
          isDisabled={isLoading}
          isLoading={isLoading}
          onChange={(e) => handleChange(e, index, "attribute", true)}
          onCreateOption={(e) => handleCreate(e, index)}
          options={options}
          value={
            item?.attribute && {
              label: item?.attribute,
              value: item?.attribute,
            }
          }
          name="category"
        />
      </div>

      <div className="col-span-4 w-full">
        <label
          htmlFor={`value_${index}`}
          className="block mb-2 text-sm font-medium text-gray-900 dark::text-white"
        >
          Value
        </label>
        <div>
          <CreatableSelect
            required={true}
            isClearable={false}
            isDisabled={isLoading}
            isLoading={isLoading}
            onChange={(e) => handleChange(e, index, "value", true)}
            onCreateOption={(e) => handleCreate2(e, index)}
            options={options2}
            value={
              item?.attribute && {
                label: item?.value,
                value: item?.value,
              }
            }
            name="value"
          />

          {/* {attributesValue?.length > 0 ? (
            <>
              <select
                value={item?.value}
                name="value"
                onChange={(e) => handleChange(e, index, false)}
                id="value"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark::bg-gray-700 dark::border-gray-600 dark::placeholder-gray-400 dark::text-white dark::focus:ring-blue-500 dark::focus:border-blue-500"
              >
                <option>Select</option>
                {attributesValue?.map((item) => (
                  <option value={item?.value}>{item?.value}</option>
                ))}
              </select>
            </>
          ) : (
            <input
              value={item?.value}
              onChange={(e) => handleChange(e, index, false)}
              name="value"
              type="text"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark::bg-gray-700 dark::border-gray-600 dark::placeholder-gray-400 dark::text-white dark::focus:ring-blue-500 dark::focus:border-blue-500"
              placeholder="Value"
              required
            />
          )} */}
        </div>
      </div>

      <div className=" w-full flex flex-col justify-center  mx-auto">
        <label
          htmlFor="checked-checkbox"
          className="block mb-2 text-sm font-medium text-gray-900 mx-auto"
        >
          Is Show
        </label>

        <input
          checked={item?.is_show}
          value={item?.is_show}
          onChange={(e) => handleChecked(e, index, item)}
          name="is_show"
          id="checked-checkbox"
          type="checkbox"
          class=" mx-auto w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 "
        />
      </div>

      <div className="col-span-3 mt-5 flex items-center">
        {data.length === index + 1 && (
          <button
            onClick={handlePluse}
            className="mx-2 bg-green-400 text-white w-16 rounded-md px-3 py-2"
          >
            +
          </button>
        )}

        <button
          onClick={() => handleMinus(index)}
          className="bg-red-400 text-white w-16 rounded-md px-3 py-2"
        >
          -
        </button>
      </div>
    </div>
  );
};

export default SelectInput;
