import React from "react";
import Barcode from "react-jsbarcode";
const BarcodeSection = React.forwardRef(({ name, products }, ref) => {
  return (
    <div ref={ref} className=" flex justify-center">
      <div className=" w-full">
        <h1 className=" text-center">{name}</h1>
        <Barcode
          value={products?.barcode}
          options={{ format: "code128", width: 2, height: 50 }}
          renderer="svg"
        />
        {products?.sell_price && (
          <h1 className=" text-center  mb-2">Price: ${products?.sell_price}</h1>
        )}
      </div>
    </div>
  );
});

export default BarcodeSection;
