import { createSlice } from "@reduxjs/toolkit";

const POSSlice = createSlice({
  name: "cart",
  initialState: {
    orderItems: [],
    currentItem: {},
  },

  reducers: {
    addToCartItem: (state, action) => {
      state.orderItems = action.payload;
    },
    handleCurrentItem: (state, action) => {
      state.currentItem = action.payload;
    },
    // addToCartItem: (state, action) => {
    //   const data = action.payload;
    //   if (!state.orderItems.some((cartItem) => cartItem.id === data.id)) {
    //     const newData = data;
    //     newData.new_quantity = 1;
    //     state.currentItem = newData;
    //     state.orderItems = [...state.orderItems, data];
    //   }
    // },
  },
});

export const { addToCartItem, handleCurrentItem } = POSSlice.actions;

export default POSSlice.reducer;
