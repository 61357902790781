import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import Navbar from "../Shared/Navbar";

const Layout = () => {
  return (
    <div className=" grid grid-cols-12 gap-4">
      {/* <Navbar /> */}
      <div className="hidden md:block md:col-span-2">
        {" "}
        <Sidebar />{" "}
      </div>
      <div className="col-span-12 md:col-span-10 m-5">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
