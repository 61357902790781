import React from "react";
import QuotitionTable from "./QuotitionTable";

const Quotition = () => {
  return (
    <div>
      <QuotitionTable />
    </div>
  );
};

export default Quotition;
