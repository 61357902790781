import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  nestedDoEditId: "",
  nestedInvoiceEditId: "",
  nestedQuotationEditId: "",
};

const nestedEditState = createSlice({
  name: "nestedEditState",
  initialState,
  reducers: {
    addNestedEditIdNumber: (state, action) => {
      state[action.payload.type] = action.payload.value;
    },
    resetNestedEditIdNumber: (state) => {
      state = initialState;
    },
  },
});

export const { addNestedEditIdNumber, resetNestedEditIdNumber } =
  nestedEditState.actions;
export default nestedEditState.reducer;
