import React from "react";
import BrandTable from "./BrandTable";

const Brand = () => {
  return (
    <div>
      <BrandTable />
    </div>
  );
};

export default Brand;
