import React, { useEffect, useRef, useState } from "react";
import { useHeader } from "../../lib/headers";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ReactToPrint from "react-to-print";

const MultiInventoryDetails = () => {
  const [data, setData] = useState([]);
  const location = useLocation();
  const productId = new URLSearchParams(location.search).get("product_id");
  const navigate = useNavigate();
  const headers = useHeader();
  const { id } = useParams();

  console.log("data", data);
  const componentRef = useRef();
  const fetchData = () => {
    axios
      .get(
        `${process.env.REACT_APP_ROOT_URL}/api/inventory_movement/?company=${id}&product=${productId}`,
        headers
      )
      .then((response) => {
        const res = response.data;
        setData(res?.results);
      })
      .catch((error) => {
        // Handle any errors
        console.error(error);
      });
  };
  useEffect(() => {
    fetchData();
  }, [id]);
  return (
    <div>
      <div className=" flex justify-between items-center">
        <button
          onClick={() => navigate(-1)}
          className=" my-2 bg-gray-900 text-white px-4 py-2 rounded-lg mr-[2%] "
        >
          Back
        </button>
        {data?.length > 0 && (
          <ReactToPrint
            trigger={() => (
              <button className=" my-2 bg-gray-900 text-white px-4 py-2 rounded-lg mr-[2%] ">
                Print
              </button>
            )}
            content={() => componentRef.current}
          />
        )}
      </div>
      <div ref={componentRef} className="p-4">
        <h1 className=" text-xl mb-4 font-bold">Multi Inventory Movement</h1>
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg ">
          <table class="w-full text-sm text-left text-gray-500 dark::text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark::bg-gray-700 dark::text-gray-400">
              <tr>
                <th scope="col" class="px-6 py-3">
                  Product name
                </th>
                <th scope="col" class="px-6 py-3">
                  Mode
                </th>
                <th scope="col" class="px-6 py-3">
                  Quantity
                </th>

                <th scope="col" class="px-6 py-3">
                  Source
                </th>
                <th scope="col" class="px-6 py-3">
                  Date/Time
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item) => (
                <tr
                  className="bg-white border-b dark::bg-gray-800 dark::border-gray-700"
                  key={item.id}
                >
                  <th
                    scope="row"
                    className="px-6 py-1 font-medium text-gray-900 whitespace-nowrap dark::text-white"
                  >
                    <p>{item?.product}</p>
                  </th>
                  <td className="px-6 py-4">{item?.mode}</td>
                  <td className="px-6 py-4">
                    {item?.quantity} {item?.product_detail?.unit}
                  </td>
                  <td className="px-6 py-4">{item?.ref}</td>
                  <td className="px-6 py-4">{item?.created_at}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MultiInventoryDetails;
