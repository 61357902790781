import React from "react";
import EmployeeTable from "./EmployeeTable";

const Employee = () => {
  return (
    <div>
      <EmployeeTable />
    </div>
  );
};

export default Employee;
