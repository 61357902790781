import React from "react";
import ProjectsTable from "./InvoiceTable";
import InvoiceTable from "./InvoiceTable";

const Invoice = () => {
  return (
    <div>
      <InvoiceTable />
    </div>
  );
};

export default Invoice;
